import { Adjacent, Alert, Block, Button, Frame, Link, Stack, Text, useNavigation } from '@stadion/bright'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import RadioInput from '../../RadioInput'
import { answerQuestion } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router'
import { wizardRoutes } from '../../../routes'

function Questions2() {
    const navigation = useNavigation()
    const questionnaire = useSelector(state => state.questionnaire)
    const dispatch = useDispatch()
    const [ errors, setErrors ] = useState({})
    
    const checkForm = () => {
        if (questionnaire.q3 && questionnaire.q4)
            navigation.redirectTo('/participant/questionnaire/questions-3')
        else
            setErrors({
                q3: questionnaire.q3 ? null : [ 'Rating is required' ],
                q4: questionnaire.q4 ? null : [ 'Rating is required' ]
            })
    }

    if (!questionnaire.q1 && !questionnaire.q2)
        return <Navigate to={ wizardRoutes.questions1 } />

    const hasErrors = Object.values(errors).some(e => e)

    return (
        <Block palette="baseTwo" paddingY="4xl">
            <Frame width={ [ 'full' ] }>
                <Stack space="6xl">
                    { hasErrors && <Alert variant="negative" heading="Questionnaire answers missing" /> }
                    <RadioInput
                        label="3. I focus on the upside even if there is some risk involved."
                        isRequired={ true }
                        name="q3"
                        value={ questionnaire.q3 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q3', answer })) }
                        errors={ errors.q3 || [] }
                    />
                    <RadioInput
                        label="4. Compared to other people I know, I am an aggressive investor."
                        isRequired={ true }
                        name="q4"
                        value={ questionnaire.q4 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q4', answer })) }
                        errors={ errors.q4 || [] }
                    />
                    <Adjacent space="md">
                        <Link href="/participant/questionnaire/questions-1" variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={ checkForm }>
                            Next
                        </Button>
                    </Adjacent>
                </Stack>
            </Frame>
        </Block>
    )    
}

export default Questions2