import { Adjacent, Alert, Block, Button, Frame, Link, Radio, RadioGroup, Stack, useNavigation } from '@stadion/bright'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { answerQuestion } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { wizardRoutes } from '../../../routes'
import { Navigate } from 'react-router'

function Questions5() {
    const navigation = useNavigation()
    const questionnaire = useSelector(state => state.questionnaire)
    const dispatch = useDispatch()
    const [ errors, setErrors ] = useState([])
    
    const checkForm = () => {
        if (questionnaire.q9)
            navigation.redirectTo(wizardRoutes.review)
        else
            setErrors([ 'Question is required' ])
    }

    if (!questionnaire.q7 && !questionnaire.q8)
        return <Navigate to={ wizardRoutes.questions4 } />

    const hasErrors = errors.length > 0

    return (
        <Block palette="baseTwo" paddingY="4xl">
            <Frame width={ [ 'full' ] }>
                <Stack space="6xl">
                    { hasErrors && <Alert variant="negative" heading="Questionnaire answers missing" /> }
                    <RadioGroup
                        label="9. How do you view the money in your 401(k) in relation to your other savings?"
                        name="q9"
                        isRequired={ true }
                        value={ questionnaire.q9 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q9', answer })) }
                        errors={ errors || [] }
                    >
                        <Radio
                            label="I don’t have any additional savings, my 401(k) is currently my only savings "
                            value="1"
                        />
                        <Radio
                            label="I view my 401(k) account as needing to take more risks to compensate for a conservative allocation of outside savings (outside savings are primarily invested in cash, bonds, CDs, money market accounts, etc)."
                            value="2"
                        />
                        <Radio
                            label="My outside savings are invested based on my risk tolerance given my age (like a target date fund or balanced fund, a mix of stocks and bonds, etc.)"
                            value="3"
                        />
                        <Radio
                            label="I need to take less risk in my 401(k) account due to an aggressive allocation of outside savings (if something dire happened I would use my 401(k) to take a loan or hardship)"
                            value="4"
                        />
                    </RadioGroup>
                    <Adjacent space="md">
                        <Link href="/participant/questionnaire/questions-4" variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={ checkForm }>
                            Next
                        </Button>
                    </Adjacent>
                </Stack>
            </Frame>
        </Block>
    )    
}

export default Questions5