import { Box, Container, Flex } from '@chakra-ui/layout'
import { ElevatedCard, Heading, PageHeadingBlock, Stack, TextInput, Block, PasswordInput, Button, MainWrapper, Link, Adjacent, Text, Alert } from '@stadion/bright'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router'
import { useLoginMutation, useForgotPasswordMutation, useGetUserInfoQuery } from '../../store/api'
import { setUsername as resetUsername, setResetTitleMsg, setForgotUsernameMessageDisplay } from '../../store/store'
import { useSelector, useDispatch } from 'react-redux'
import routes from '../../routes'
import PageWrapper from '../PageWrapper'
import useUser from '../../store/useUser'
import ReactGA from 'react-ga4';


function Login()
{   const navigate = useNavigate()
    const { displayForgotUsernameMessage } = useSelector(state => state.forgotUsernameMessage)
    const { sessionToken } = useSelector(state => state.token)
    const dispatch = useDispatch();
    const [forgotPassword, forgotPasswordResponse] = useForgotPasswordMutation()
    const initialRender = useRef(true);
    // const { user } = useSelector(state => state.userInfo)
    const user = useUser()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [login, loginResponse] = useLoginMutation()
    const userinfo = useGetUserInfoQuery()

    const loginError = (
        loginResponse.data?.status?.rc !== undefined &&
        loginResponse.data?.status?.rc !== 1 &&
        loginResponse.data?.status?.rc !== -80  // Require password reset
    )

    const resetPwRequired = (
        loginResponse.data?.status?.rc !== undefined &&
        loginResponse.data?.status?.rc === -80  // Require password reset    
    )

    useEffect(() =>
    {
        if (initialRender.current)
        {
            initialRender.current = false;
        } else
        {
            return () =>
            {
                dispatch(setForgotUsernameMessageDisplay({ value: false }));
            }
        }
    }, []);

    useEffect(() =>
    {
        if (resetPwRequired)
        {
            forgotPassword({ username })
            dispatch(resetUsername(username));
            dispatch(setResetTitleMsg(loginResponse.data?.status?.msg));
        }
    }, [dispatch, forgotPassword, loginResponse.data?.status?.msg, resetPwRequired, username])

    if (resetPwRequired)
    {
        navigate(routes.updatePassword)
    }

    useEffect(() => {
        if (loginResponse?.data?.status?.rc === -1)
        {
            // Send GA event for general login failure
            ReactGA.event({'category': 'Login', 'action': 'Login Failure'})
        }
        if (loginResponse?.data?.status?.rc === -3)
        {
            // Send GA event for login max retry failure
            ReactGA.event({'category': 'Login', 'action': 'Max Retry Login Failure'})
        }
    }, [ loginResponse ])
       
    
    if (sessionToken && user)
    {
        //console.log(sessionToken)
        // const implemented = loginResponse?.data?.user?.implemented
        const implemented = user.implemented
        if (implemented)
            return <Navigate to="/participant/dashboard" />
        else
            return <Navigate to="/participant/get-started" />
    }

    const handleBackClick = () =>
    {
        navigate(-1)
    }

    const handleLogin = e =>
    {
        e.preventDefault()
        login({ username, password })
    }

    const usernameErrors = loginError ? ['Please check your input'] : []
    const passwordErrors = loginError ? ['Please check your input'] : []

    const content = (
        <Container>
            <ElevatedCard>
                <Stack space="xl">
                    <Text level="xl" variant="primary" as="b">
                        StoryLine login
                    </Text>
                    {
                        loginError &&
                        <Alert variant="negative">
                            <Heading as="h5" color="contentSecondary">
                                {loginResponse.data?.status?.msg}
                            </Heading>
                        </Alert>
                    }
                    {
                        displayForgotUsernameMessage &&
                        <Alert variant="positive">
                            <Heading as="h5" color="contentSecondary">
                                Your username was sent to your email if an account exists.
                            </Heading>
                        </Alert>
                    }
                    <form onSubmit={handleLogin}>
                        <Stack space="xl">
                            <TextInput
                                label="Username"
                                isRequired={true}
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                errors={usernameErrors}
                            />
                            <PasswordInput
                                label="Password"
                                isRequired={true}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                isReadOnly={loginResponse.isLoading}
                                errors={passwordErrors}
                            />
                            <Adjacent space="md">
                                <Link href={routes.forgotUsername}>I've forgotten my username</Link>
                            </Adjacent>
                            <Adjacent space="md">
                                <Link href={routes.forgotPassword}>I've forgotten my password</Link>
                            </Adjacent>
                            <Adjacent space="md">
                                <Button onClick={handleBackClick} variant="secondaryButton">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primaryButton"
                                    isLoading={loginResponse.isLoading}
                                >
                                    Log in
                                </Button>
                            </Adjacent>
                        </Stack>
                    </form>
                    <Adjacent space="md">
                        <Text as="b" >Not already registered with us?</Text>
                        <Link href={routes.signup}>Sign up</Link>
                    </Adjacent>

                </Stack>
            </ElevatedCard>
        </Container>
    )
    return (
        <PageWrapper pageTitle="Login">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="6xl">
                                <Box>{content}</Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default Login