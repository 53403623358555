import React from 'react'
import { HiFiIcon, PageHeadingBlock, Stack, Block, MainWrapper, Link, Text } from '@stadion/bright'
import { Box, Flex, Grid } from '@chakra-ui/layout'
import PageWrapper from '../../PageWrapper';

function GetStarted() {
    return (
        <PageWrapper pageTitle="Get Started">
            <MainWrapper palette="baseTwo">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseTwo" paddingY="6xl">
                            <Stack space="6xl">
                                <Box>
                                    <Stack space="md">
                                        <Text as="p">
                                           Stadion StoryLine is a retirement solution that takes your personal story into account. It will take just a few minutes for us to get to know you better and create your personalized StoryLine portfolio–but the results may make a lifetime of difference.                                        
                                        </Text>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack space="4xl">
                                        <Grid
                                            gap={{ base: 'md', sm: 'lg' }}
                                            templateColumns={["1fr", "auto 1fr"]}
                                            flexDirection="column"
                                        >
                                            <HiFiIcon
                                                palette="baseOne"
                                                strokeWidth="md"
                                                set="multiTone"
                                                variant="PersonalDetailsEdit"
                                            />
                                            <Stack space="sm">
                                                <Text level="xl" variant="primary" as="b">Complete your profile</Text>
                                                <Text level="sm">Enter and confirm a few personal details to begin</Text>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            gap={{ base: 'md', sm: 'lg' }}
                                            templateColumns={["1fr", "auto 1fr"]}
                                            flexDirection="column"
                                        >
                                            <HiFiIcon
                                                palette="baseOne"
                                                strokeWidth="md"
                                                set="multiTone"
                                                variant="Assessments"
                                            />
                                            <Stack space="sm">
                                                <Text level="xl" variant="primary" as="b">Take the questionnaire</Text>
                                                <Text level="sm">Answer a few simple questions to determine your attitude toward risk.</Text>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            gap={{ base: 'md', sm: 'lg' }}
                                            templateColumns={["1fr", "auto 1fr"]}
                                            flexDirection="column"
                                        >
                                            <HiFiIcon
                                                palette="baseOne"
                                                strokeWidth="md"
                                                set="multiTone"
                                                variant="Guarantee"
                                            />
                                            <Stack space="sm">
                                                <Text level="xl" variant="primary" as="b">Review and confirm your results</Text>
                                                <Text level="sm">Review your selected risk profile and investment portfolio and confirm the results to start saving.</Text>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            gap={{ base: 'md', sm: 'lg' }}
                                            templateColumns={["1fr", "auto 1fr"]}
                                            flexDirection="column"
                                        >
                                            <HiFiIcon
                                                palette="baseOne"
                                                strokeWidth="md"
                                                set="multiTone"
                                                variant="Flexible"
                                            />
                                            <Stack space="sm">
                                                <Text level="xl" variant="primary" as="b">Check your dashboard for retirement options</Text>
                                                <Text level="sm">After you sign up for StoryLine, you should come back to your dashboard on a regular basis, for example, once each year, where you can see your estimated account balance, update your financial information, risk profile and learn about withdrawing money from your retirement account over time.</Text>
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Link href="/participant/questionnaire/profile" variant="primaryButton">
                                        Get started
                                    </Link>
                                </Box>
                                <Box>
                                    <Stack space="sm">
                                        <Link href="https://cdn.stadionmoney.com/Footer/StoryLine_Interface_Terms.pdf" level='2xs'>
                                            <b>StoryLine Terms and Conditions</b>
                                        </Link>
                                        <Text as="p" level="2xs">
                                            Past performance is no guarantee of future results. Investments are subject to risk, and any of Stadion’s investment strategies may lose money. Your account balances, your personal circumstances, investment returns and other material factors will likely change over time. As a result, your outcomes and estimated retirement income can, and likely will, change. As a result, it is important that you review your Dashboard on a regular basis, for example, annually. Allocation percentages shown are the target model for each portfolio. Actual percentages will vary based on market conditions. Stadion reserves the right to modify its current investment strategies based on changing market dynamics or client needs.
                                        </Text>
                                        <Text as="p" level="2xs">
                                            Stadion Money Management, LLC ("Stadion") is a registered investment adviser under the Investment Advisers Act of 1940.  Registration does not imply a certain level of skill or training. More information about Stadion, including fees, can be found in Stadion's ADV Part 2, which is available free of charge.
                                        </Text>
                                        <Text as="p" level="2xs">
                                            This report is not intended to predict actual future data, but is only an illustration based on the profile user’s data inputs. The projections are provided solely as information which should be carefully weighed and evaluated by the investor with the assistance of the investor’s personal financial adviser. No guarantee of future performance is implied or intended, and no specific investments or financial products are represented in the reports. Portfolio characteristics, client restrictions, market conditions, or other factors could impact estimated accumulation. Although this report should prove helpful in visualizing the possibilities for your financial future, you should keep in mind that your actual results will undoubtedly differ from those shown. These projections are for illustrative purposes only and do not constitute investment advice. Your account balances, your personal circumstances, investment returns and other material factors will likely change over time. As a result, your outcomes and estimated retirement income can, and likely will, change. As a result, it is important that you review your Dashboard on a regular basis, for example, annually. 
                                        </Text>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default GetStarted