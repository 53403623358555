import { Grid, Navigation, NavigationDrawerPathLinksItems, TopBar } from '@stadion/bright'
import React, { useEffect } from 'react'
import AppFooter from './Footer'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useUser from '../store/useUser'
import logo from '../images/logo.png'

export const defaultFooter = <AppFooter />

export default function PageWrapper(props)
{
    const {
        footer = defaultFooter
    } = props

    const { sessionToken } = useSelector(state => state.token)
    const location = useLocation()

    useEffect(() =>
    {
        window.scrollTo(0, 0)
    }, [location.pathname])

    useEffect(() =>
    {
        if(props.pageTitle !== undefined) document.title = props.pageTitle
    }, [props.pageTitle])

    function AppNavigation({ user, sessionToken, location })
    {
        // console.log({ location })
        return ((user && !location.pathname.match(/\/questionnaire/)) ?
            <Navigation
                // imageUrl="https://uploads-ssl.webflow.com/63281bde463ce17982567ea7/6332ddc18f3a6935d891e1fe_Stadion%20new%20logo%20full%20white-p-2000.png"
                imageUrl={logo}
                imageLink={sessionToken ? '/participant/dashboard' : '/'}
                accountDetails={[
                    {
                        content: `${user.firstName} ${user.lastName}`,
                        label: 'Signed in as'
                    }
                ]}
                menuButtonLabel="Menu"
                signOutButtonLabel="Log out"
                onSignOut={() => { delete sessionStorage.sessionToken; window.location.replace('/') }}
            >
                <NavigationDrawerPathLinksItems
                    pathname={location.pathname}
                    navigationItems={
                        [
                            { label: 'Dashboard', path: '/participant/dashboard' },
                            { label: 'Profile', path: '/participant/questionnaire/profile' },
                        ]
                    }
                />
            </Navigation>
            :
            <TopBar
                // imageUrl="https://uploads-ssl.webflow.com/63281bde463ce17982567ea7/6332ddc18f3a6935d891e1fe_Stadion%20new%20logo%20full%20white-p-2000.png"
                imageUrl={logo}
                imageLink="/"
            />
        )
    }

    const user = useUser()

    return (
        <Grid flexDir="column" minHeight="100vh">
            <AppNavigation
                user={user}
                sessionToken={sessionToken}
                location={location}
            />
            {props.children}
            {footer}
        </Grid>
    )
}