import { Box, Flex, Grid } from '@chakra-ui/layout'
import { PageHeadingBlock, Stack, Block, OutlineCard, Text, PageLoading } from '@stadion/bright'
import React from 'react'
import { ModalDashboardTabOverview1, ModalDashboardTabOverview2, ModalDashboardTabOverview3 } from '../../utils/modals'
import LineChart401k from '../charts/LineChart401k';
import useUser from '../../store/useUser';


function DashboardTabOverview({ dashboardResponse, aumBalanceResponse, lineChartData, Notifications }) {
    const user = useUser()
    let incomeList = dashboardResponse?.data?.incomeDistributionList
    if(incomeList !== undefined)  incomeList = incomeList.filter(el => el.income > 0)
    let monthlyIncome = dashboardResponse?.data?.incomeMonthlyAtRetire;
    const retirementAmts = incomeList?.find(cd => (cd.age === parseInt(dashboardResponse?.data?.incomeStartAge))) 
    if(retirementAmts !== undefined) {
        monthlyIncome = retirementAmts.income;
    }
    if(monthlyIncome === undefined) monthlyIncome = 0

    return (
        <Flex flexGrow={1} flexDir="column">
            <Block>
                <Stack space="lg">
                    <PageHeadingBlock
                        pageHeading={"Overview"}
                        showDivider={false}
                    />

                    {!(dashboardResponse.isLoading || dashboardResponse.isFetching) && ( <Notifications /> )}

                    <OutlineCard>
                        <Stack space="xl">
                            {!(dashboardResponse.isLoading || dashboardResponse.isFetching) ? (
                                <div>
                                    <Box mt={5}>
                                        <Grid
                                            templateColumns={
                                                [
                                                    'auto',
                                                    '1fr 1px 1fr 1px 1fr'
                                                ]
                                            }
                                            columnGap="4"
                                            rowGap="4"
                                        >
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Current 401(k) balance:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${user !== undefined ? (Math.round(user?.pqdiaReq?.vendorAum).toLocaleString()) : "0"}
                                                    </Text>
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box>
                                                    <Text level="sm" as="b" variant="primary">
                                                        Estimated 401(k) balance at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${ dashboardResponse?.data?.incomeAcctValueAtRetire ? (Math.round(dashboardResponse?.data?.incomeAcctValueAtRetire).toLocaleString()) : "0"} 
                                                    </Text>
                                                    <ModalDashboardTabOverview1 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box>
                                                    <Text level="sm" as="b" variant="primary">
                                                        Est 401(k) monthly income in retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${dashboardResponse  ? (Math.round(monthlyIncome).toLocaleString()) : "0"} 
                                                    </Text>
                                                    <ModalDashboardTabOverview2 />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                    <Box mt={4}>
                                       <ModalDashboardTabOverview3/>
                                    </Box>
                                </div>
                            ) : (
                               <PageLoading />
                            )
                            }
                            {!(aumBalanceResponse.isLoading || aumBalanceResponse.isFetching) ? (
                                <div>
                                    <Box mt={5}>
                                        <LineChart401k lineData={lineChartData} />
                                    </Box>
                                </div>
                            ) : (
                               <div />
                            )
                            }
                        </Stack>
                    </OutlineCard>
                </Stack>
            </Block>
        </Flex>
    )
}

function Border() {
    return <div style={ { backgroundColor: '#E0E0E0', minHeight: '1px' } }></div>
}


export default DashboardTabOverview