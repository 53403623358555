import React, { useEffect, useState } from 'react'
import { OutlinePlanCard, Alert, ElevatedCard, PageHeadingBlock, PageLoading, QuitAndRestartModal, Stack, Block, Button, Link, Text } from '@stadion/bright'
import { Box, Flex, Grid } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { toggle } from '../../../store/slices/quitModal'
import { useGetIncomeDistMutation } from '../../../store/api'
import { setAcceptedPlan, setStartAge, setEndAge, setMonthlyIncome, setCustomStartAge, setCustomEndAge, setCustomMonthlyIncome, setPtileOverride } from '../../../store/store'
import StackedBarChartIncome from '../../charts/StackedBarChartIncome'
import PageWrapper from '../../PageWrapper'
import useUser from '../../../store/useUser'


function IncomeComparePlans() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customGraphSmileMode = useSelector(state => state.incomePlan.customGraphSmileMode)
    const [ getIncomeDist1, incomeDistResponse1 ] = useGetIncomeDistMutation()
    const [ getIncomeDist2, incomeDistResponse2 ] = useGetIncomeDistMutation()

    const urlFrom = location.state?.from;
    const editAll = location.state?.editAll;
    
    const [ errors, setErrors ] = useState({})
    
    const user = useUser()
    
    
    useEffect(() => {
        // Graph/option 1 is the default Stadion recommended plan
        getIncomeDist1();
    },  [startAge, endAge])  
    
        

    useEffect(() => {
        // Graph/option 2 is the plan with current/unsaved values.
        getIncomeDist2({
            smileChartMode: customGraphSmileMode,
            incomeStartAge: customStartAge > 0 ? customStartAge : startAge,
            incomeEndAge: customEndAge > 0 ? customEndAge : endAge,
            incomeOverride: customMonthlyIncome > 0 ? customMonthlyIncome : 0,        
        })
    },  [customStartAge, customEndAge, customGraphSmileMode])    
   
                  
    const restart = () =>
    {
        toggleModal()
        navigate('/participant/dashboard')
    } 
    
    const handleBackClick = () =>
    {
        navigate(-1, {state: { editAll: editAll }})
    }
               
    const handleSelectPlan = (whichPlan) =>
    {
        console.log("Plan selected: ", whichPlan);
        dispatch(setAcceptedPlan(whichPlan))
        let cStartAge = 0;
        let cEndAge = 0;
        let cMonthly = 0;
        if(whichPlan === 1) {
            cStartAge = customStartAge
            cEndAge = customEndAge
            cMonthly = customMonthlyIncome
        }
        else {
            // Reset start/end ages/monthly back to original.
            dispatch(setPtileOverride(0))
            dispatch(setStartAge(incomeDistResponse1?.data?.incomeStartAge))
            dispatch(setEndAge(incomeDistResponse1?.data?.incomeEndAge))
            dispatch(setMonthlyIncome(incomeDistResponse1?.data?.incomeDistributionList?.find(cd => (cd.age === user?.pqdiaReq?.expectedRetirementAge)).income))
        }
        dispatch(setCustomStartAge(cStartAge))
        dispatch(setCustomEndAge(cEndAge))
        dispatch(setCustomMonthlyIncome(cMonthly))
        if((urlFrom !== undefined) && (urlFrom === "/income/IncomePlanSummary"))
           navigate('/income/IncomePlanSummary')
        else
           navigate('/income/customizeConfirm' , {state: { editAll: editAll }})
    }
   
    
      
    const errorEntries = Object.values(errors).filter(err => err)

    const incomeList1 = incomeDistResponse1?.data?.incomeDistributionList; 
    const retirementAmts = incomeList1?.find(cd => (cd.age === user?.pqdiaReq?.expectedRetirementAge))  
    let monthlyRetireIncome = monthlyIncome
    if(retirementAmts !== undefined) {
       monthlyRetireIncome = retirementAmts.income;
    }
   
    
    const incomeList2 = incomeDistResponse2?.data?.incomeDistributionList.filter(el => el.income > 0)
    const retirementAmts2 = incomeList2?.find(cd => (cd.age === parseInt(customStartAge))) 
    let monthlyIncome2 = incomeDistResponse2?.data?.incomeMonthlyAtRetire; 
    if(retirementAmts2 !== undefined) {
        monthlyIncome2 = retirementAmts2.income;
    }
    const include401k = true;
    const includeSocSec = false;
    const includePension = false;
    

    return (
        <PageWrapper pageTitle="Income Compare Plans">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="Compare your customized retirement income amount"
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={8}>
                        <Text as="p">
                           Great work! Now it is time to compare your two income options and decide which one fits you the best.
                        </Text>
                    </Box>
                    <Box mt={4}>
                        <Text as="p">
                            Income amounts are subject to changes in market conditions. Social Security and pension amounts are participant inputs and Stadion only provides education on them.
                        </Text>
                    </Box>
                    <Box mt={4}>
                        <Grid
                            templateColumns={
                                [
                                    'auto',
                                    '1fr 1fr'
                                ]
                            }
                            columnGap="4"
                            rowGap="4"
                            minWidth={'600px'}
                        >
                            <OutlinePlanCard
                                subtitleContent="<p>Stadion's estimated income amount</p>"
                                title="Option 1"
                                hasDivider={false}
                            >
                                {!(incomeDistResponse1.isLoading || incomeDistResponse1.isFetching) ? (
                                    <div>
                                        <StackedBarChartIncome
                                            barChartData={incomeList1}
                                            include401k={include401k}
                                            includeSocSec={includeSocSec}
                                            includePension={includePension}
                                            narrowView={true}
                                            heightOverride="75%"
                                        />
                                        <Box mt={4}>
                                            <Text level="sm">
                                                Est 401(k) monthly income at retirement:
                                            </Text>
                                        </Box>
                                        <Box mt={2}>
                                            <Text level="lg" as="b">
                                                ${Math.round(monthlyRetireIncome).toLocaleString()}
                                            </Text>
                                        </Box>
                                        <Box mt={4}>
                                            <Text level="xs">
                                                This calculation is calculated for your retirement age of {startAge}
                                            </Text>
                                        </Box>
                                        <Box mt={4}>
                                            <Button onClick={() => handleSelectPlan(0)}>
                                                Select strategy
                                            </Button>
                                        </Box>
                                        <Box mt={4}>
                                            <Text level="xs">
                                                If you're not sure, you can change your mind later.
                                            </Text>
                                        </Box>
                                    </div>
                                ) : (
                                    <PageLoading />
                                )
                                }
                            </OutlinePlanCard>

                            <OutlinePlanCard
                                subtitleContent="<p>Your customized income amount</p>"
                                title="Option 2"
                                hasDivider={false}
                            >
                                {!(incomeDistResponse2.isLoading || incomeDistResponse2.isFetching) ? (
                                    <div>
                                        < StackedBarChartIncome
                                            barChartData={incomeList2}
                                            include401k={include401k}
                                            includeSocSec={includeSocSec}
                                            includePension={includePension}
                                            narrowView={true}
                                            heightOverride="75%"
                                        />
                                        <Box mt={4}>
                                            <Text level="sm">
                                                Est 401(k) monthly income at retirement:
                                            </Text>
                                        </Box>
                                        <Box mt={2}>
                                            <Text level="lg" as="b">
                                                ${Math.round(monthlyIncome2).toLocaleString()}
                                            </Text>
                                        </Box>
                                        <Box mt={4}>
                                            <Text level="xs">
                                                This calculation is calculated for your retirement age of {customStartAge  > 0 ? customStartAge : startAge}
                                            </Text>
                                        </Box>
                                        <Box mt={4}>
                                            <Button onClick={() => handleSelectPlan(1)}>
                                                Select strategy
                                            </Button>
                                        </Box>
                                        <Box mt={4}>
                                            <Text level="xs">
                                                If you're not sure, you can change your mind later.
                                            </Text>
                                        </Box>
                                    </div>
                                ) : (
                                    <PageLoading />
                                )
                                }
                            </OutlinePlanCard>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                            <Text as="p" level="xs">
                               The timeframe for any disbursement estimates shown may not be available based on your Plan's service arrangement with its recordkeeper. Please confirm check with your Plan's administrator to learn what is available to you.
                            </Text>
                    </Box>

                    <Box mt={8}>
                        {/* <Link href="/income/customize2" variant="secondaryButton">
                            Back
                        </Link> */}
                        <Button onClick={handleBackClick} variant="secondaryButton">
                                Back
                         </Button>
                    </Box>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}


export default IncomeComparePlans