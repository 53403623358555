import React, { useEffect, useState } from 'react'
import { Alert, ElevatedCard, Divider, Heading, PageHeadingBlock, PageLoading, QuitAndRestartModal, Stack, Block, Button, Link, Adjacent, Text, ContrastCard } from '@stadion/bright'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import PageWrapper from '../../PageWrapper'
import { toggle } from '../../../store/slices/quitModal'
import { useGetIncomeDistMutation } from '../../../store/api'
import { ModalDashboardTabOverview2 } from '../../../utils/modals'
import StackedBarChartIncome from '../../charts/StackedBarChartIncome'
import useUser from '../../../store/useUser'


function IncomePlanSummary() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const [ errors, setErrors ] = useState({})
    const startAge = useSelector(state => state.incomePlan.startAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customSocsecStartAge = useSelector(state => state.incomePlan.customSocsecStartAge)
    const customPensionStartAge = useSelector(state => state.incomePlan.customPensionStartAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customMonthlySocsec = useSelector(state => state.incomePlan.customMonthlySocsec)
    const customMonthlyPension = useSelector(state => state.incomePlan.customMonthlyPension)
    const customGraphSmileMode = useSelector(state => state.incomePlan.customGraphSmileMode)
    const includeSocsec = useSelector(state => state.incomeAccount.includeSocsec)
    const includePension = useSelector(state => state.incomeAccount.includePension)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const [ getIncomeDist, incomeDistResponse ] = useGetIncomeDistMutation()

    const user = useUser()
        

    useEffect(() => {
        // console.log("custom ages: ", customStartAge, customEndAge, startAge, endAge)
        // console.log("acceptedPlan: ", acceptedPlan, typeof(acceptedPlan))
        // console.log("Monthly:", monthlyIncome, customMonthlyIncome)
        // console.log("smileChartMode: ", acceptedPlan > 0 ? customGraphSmileMode : 1)
        // console.log("incomeStartAge: ", acceptedPlan > 0 ? customStartAge : startAge)
        // console.log("incomeEndAge: ", acceptedPlan > 0 ? customEndAge : endAge)
        // console.log("incomeOverride: ", acceptedPlan > 0 ? customMonthlyIncome : 0)

        let socsecMonthly = 0;
        let socsecStartAge = 0;
        if(includeSocsec) {
            socsecMonthly = customMonthlySocsec;
            socsecStartAge = customSocsecStartAge;
        }
        
        getIncomeDist({
            smileChartMode: acceptedPlan > 0 ? customGraphSmileMode : 1,
            incomeStartAge: acceptedPlan > 0 ? customStartAge : startAge,
            incomeEndAge: acceptedPlan > 0 ? customEndAge : endAge,
            incomeOverride: customMonthlyIncome > 0 ? customMonthlyIncome : 0,
            socSecOverride: socsecMonthly,
            socSecStartAge: socsecStartAge
        })
        if (incomeDistResponse.data?.status?.rc < 0)
        {
            setErrors({ generic: incomeDistResponse.data?.status?.msg })
        }
    }, [user])
    
           
    const restart = () =>
    {
        toggleModal()
        navigate('/participant/dashboard')
    } 
    const handleBackClick = () =>
    {
        navigate(-1)
    }         
    const handleContinueClick = () =>
    {
        navigate('/income/review');
    }
    const handleEditAllClick = () =>
    {
        navigate('/income/welcome', {state: { editAll: location.pathname }})
    }
    const handleEdit401kClick = () =>
    {
        navigate('/income/customize1', {state: { from: location.pathname }})
    }
    const handleEditSocsecClick = () =>
    {
        navigate('/income/customizeSocsec', {state: { from: location.pathname }})
    }
    const handleEditPensionClick = () =>
    {
        navigate('/income/customizePension', {state: { from: location.pathname }})
    }

    
    const errorEntries = Object.values(errors).filter(err => err)
    
    const incomeList = incomeDistResponse?.data?.incomeDistributionList; 
    const retirementBalance = incomeDistResponse?.data?.incomeAcctValueAtRetire;
    // const monthlyRetireIncome = incomeDistResponse?.data?.incomeMonthlyAtRetire;
   
    // Find the income distrib for user at income start age, get monthly values from there.
    let incomeStartAge = parseInt(acceptedPlan) > 0 ? parseInt(customStartAge) : parseInt(startAge);
    if(incomeStartAge === 0) incomeStartAge = user?.pqdiaReq?.expectedRetirementAge;
    const incomeAmts = incomeList?.find(cd => (cd.age === incomeStartAge))
    let monthly401kIncome = 0;
    if(incomeAmts !== undefined) {
        monthly401kIncome = incomeAmts?.income;
    }
    
    // Find the income distrib for user retirement age, get monthly values from there.
    const retirementAmts = incomeList?.find(cd => (cd.age === user?.pqdiaReq?.expectedRetirementAge))  
    const include401k = true;
    let totalIncome = 0;
    let monthlyOtherIncome = 0;
    let monthlyRetireIncome = 0;
    if(retirementAmts !== undefined) {
        // totalIncome = retirementAmts.income;
        totalIncome = monthly401kIncome;

        monthlyRetireIncome = retirementAmts?.income;
        if(includeSocsec === true) {
            const socsecAmts = incomeList?.find(cd => (cd.age === customSocsecStartAge))  
            totalIncome = totalIncome + socsecAmts?.socsec;
            
            const socsecAmtsAtRetire = incomeList?.find(cd => (cd.age === user?.pqdiaReq?.expectedRetirementAge))  
            monthlyOtherIncome = monthlyOtherIncome + socsecAmtsAtRetire?.socsec;    
        }
        if(includePension === true) {
            totalIncome = totalIncome + customMonthlyPension;
            monthlyOtherIncome = monthlyOtherIncome + customMonthlyPension;       
        }
    }
    
    
    return (
        <PageWrapper pageTitle="Income Plan Summary">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading={"Your retirement income"}
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) ? (
                        <>
                            <Box mt={8}>
                                <Text as="p">
                                    Based on the information you provided, when you retire at age {user?.pqdiaReq?.expectedRetirementAge}, you may have a retirement savings  balance of ${Math.round(retirementBalance).toLocaleString()}. You could expect to sustain a monthly income of ${Math.round(monthlyRetireIncome).toLocaleString()} from your 401(k) plan, and ${Math.round(monthlyOtherIncome).toLocaleString()} from income sources outside of your plan.
                                </Text>
                            </Box>


                            <ContrastCard mt={8}>
                                <Stack space="md">
                                    <div>
                                        <Heading as="h1" styleAs="h3">
                                            Your retirement income
                                            <ModalDashboardTabOverview2 />
                                        </Heading>
                                        <Box mt={5} mb={5}>
                                            <StackedBarChartIncome
                                                barChartData={incomeList}
                                                include401k={include401k}
                                                includeSocSec={includeSocsec}
                                                socSecStartAge={customSocsecStartAge}
                                                includePension={includePension}
                                                pensionStartAge={customPensionStartAge}
                                                monthlyPension={customMonthlyPension}
                                                bgOverride='#F5F7FB'
                                            />
                                        </Box>
                                        <Text as="p">
                                            Est 401(k) monthly income at retirement
                                        </Text>
                                        <Heading as="h1" styleAs="h1">
                                            ${Math.round(monthlyRetireIncome).toLocaleString()}
                                        </Heading>
                                        <Box mt={5} mb={5}>
                                            <Text as="p" level="xs" variant="tertiary">
                                                This calculation is calculated from your retirement age of {user?.pqdiaReq?.expectedRetirementAge}
                                            </Text>
                                        </Box>
                                        <Grid templateColumns={"repeat(2, 1fr)"} gap={1}>
                                            <Text variant='primary' as="b">Estimated income breakdown</Text>
                                            <Box display="flex" justifyContent="end" w="100%">
                                                   <Button variant="primaryLink" onClick={handleEditAllClick}>
                                                        Edit all
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Box mt={5}>
                                            <Grid templateColumns={"repeat(4, 1fr)"} gap={1}>
                                                <Text variant='primary' as="b">Income sources</Text>
                                                <Text variant='primary' as="b">Start age</Text>
                                                <GridItem colSpan={2}><Text variant='primary' as="b">Monthly amount</Text></GridItem>
                                                <Text variant='primary'>401(k)</Text>
                                                <Text variant='primary'>{incomeStartAge}</Text>
                                                <Text variant='primary'>${Math.round(monthly401kIncome).toLocaleString()}</Text>
                                                <Box display="flex" justifyContent="end" w="100%">
                                                    <Button variant="primaryLink" onClick={handleEdit401kClick}>
                                                        Edit
                                                    </Button>
                                                </Box>
                                                <Text variant='primary'>Social Security</Text>
                                                <Text variant='primary'>{(includeSocsec === true) ? (<>{customSocsecStartAge}</>) : 0}</Text>
                                                <Text variant='primary'>${(includeSocsec === true) ? (<>{Math.round(customMonthlySocsec).toLocaleString()}</>) : 0}</Text>
                                                <Box display="flex" justifyContent="end" w="100%">
                                                    <Button variant="primaryLink" onClick={handleEditSocsecClick}>
                                                        Edit
                                                    </Button>
                                                </Box>
                                                <Text variant='primary'>Pension</Text>
                                                <Text variant='primary'>{(includePension === true) ? (<>{customPensionStartAge}</>) : 0}</Text>
                                                <Text variant='primary'>${(includePension === true) ? (<>{Math.round(customMonthlyPension).toLocaleString()}</>) : 0}</Text>
                                                <Box display="flex" justifyContent="end" w="100%">
                                                    <Button variant="primaryLink" onClick={handleEditPensionClick}>
                                                        Edit
                                                    </Button>
                                                </Box>
                                                <GridItem colSpan={4}><Divider /></GridItem>
                                                <GridItem colSpan={2}><Text variant='primary' as="b">Est income total</Text></GridItem>
                                                <Text variant='primary'>${Math.round(totalIncome).toLocaleString()}</Text>
                                            </Grid>
                                        </Box>
                                        <Box mt={5}>
                                            <Text as="p" level="xs" variant="tertiary">
                                               This report illustrates point-in-time estimates and projections from the StoryLine website, and should not be relied upon for multi-year income 
                                               planning purposes. Estimated income projections are based on assumptions and hypothetical performance data and do not represent actual or guaranteed 
                                               results. Please read the Important Disclosures section for details. Your point-in-time projections will vary over time, and are subject to review and 
                                               change at least annually by Stadion. Actual results will vary based on factors such as market performance, inflation, taxes, and personal circumstances 
                                               including: retirement age, lifestyle, mortality, and other relevant personal information.
                                               Social Security and pension amounts are participant inputs and Stadion only provides education on them.                                             </Text>
                                         </Box>
                                         <Box mt={2} mb={2}>
                                            <Text as="p" level="xs" variant="tertiary">
                                               The timeframe for any disbursement estimates shown may not be available based on your Plan's service arrangement with its recordkeeper. Please confirm check with your Plan's administrator to learn what is available to you.
                                            </Text>
                                        </Box>
                                    </div>
                                </Stack>
                            </ContrastCard>
                        </>
                    ) : (
                        <PageLoading palette='baseOne' />
                    )
                    }

                    <Box mt={8}>
                        <Adjacent space="md">
                            {/* <Link href="/income/CustomizePension" variant="secondaryButton">
                                Back
                            </Link> */}
                            <Button onClick={handleBackClick} variant="secondaryButton">
                                Back
                            </Button>
                            <Button onClick={handleContinueClick}>
                                Create illustration
                            </Button>
                        </Adjacent>
                    </Box>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}


export default IncomePlanSummary