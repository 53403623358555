import { Button, DynamicErrors, MainWrapper, Modal, ModalBody, ModalFooter, ModalHeader, PageLoading, Text } from '@stadion/bright'
import { Outlet } from 'react-router'
import { useDispatch } from 'react-redux'
import { useGetUserInfoQuery, useLogoutMutation, useRefreshSessionMutation } from '../store/api'
import { setLatestActivityTimestamp } from "../store/store";
import { useState, useEffect } from 'react'
import useLatestActivity from '../store/useLatestActivity'
import ReactGA from 'react-ga4';
import useUser from '../store/useUser'

function Auth() {
    const userInfoResponse = useGetUserInfoQuery()
    const [ logout ] = useLogoutMutation()
    const [ refreshSession ] = useRefreshSessionMutation()
    const latestActivity = useLatestActivity()
    // const { sessionToken } = useSelector(state => state.token)
    const user = useUser()

    const rc = userInfoResponse.data?.status?.rc

    const loggedIn = rc === 0
    const sessionTimeout = rc === -90
    const customError = (rc && rc !== 0)

    const [ timestamp, setTimestamp ] = useState(Date.now())

    useEffect(() => {
        const intervalCallback = () => {
            setTimestamp(Date.now())
        }

        const interval = setInterval(intervalCallback, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        // console.log(latestActivity, timestamp)
        const timeout = timestamp > latestActivity.latestAPIRequestTimestamp + (60000 * 30)

        // console.log(timeout, timestamp, 'vs', latestActivity.latestAPIRequestTimestamp + (60000 * 30))

        if (timeout)
            logout()
    }, [ timestamp ])

    useEffect(() => {
        refreshSession()
    }, [ latestActivity.latestActivityTimestamp ])
    
    useEffect(() =>
    {
        if (sessionTimeout)
        {
            // Send GA event for user  timeout
            ReactGA.event({ 'category': 'Timeout', 'action': 'Session Timeout Occurred' })
        }
    }, [sessionTimeout])

    if (user)
        return <AuthorizedOutlet />

    if (userInfoResponse.isLoading || userInfoResponse.isUninitialized)
        return (
            <MainWrapper>
                <PageLoading />
            </MainWrapper>
        )

    // if (userInfoResponse.isFetching)
    //     return <Outlet />

    const sessionTimeoutErrorItem = {
        buttonLabel: 'Go to login',
        buttonLink: '/login',
        errorHeading: 'We\'re sorry',
        errorMessage: `<p>
            ${ sessionTimeout ?
                'Your session has expired' :
                (
                    userInfoResponse.data?.status?.msg ||
                    userInfoResponse.error?.data?.message ||
                    'Something went wrong'
                )
            }</p>`,
        statusCode: 401
      }

    if (userInfoResponse.isError || customError || !user || latestActivity.timeout)
        return (
            <MainWrapper palette="baseTwo">
                <DynamicErrors
                    autoPlay
                    errorItems={ [] }
                    fallbackErrorItem={ sessionTimeoutErrorItem }
                    statusCodeLabel="Error code:"
                />
            </MainWrapper>
        )

    return <AuthorizedOutlet />
}

export default Auth

const AuthorizedOutlet = () => {
    const dispatch = useDispatch()

    const latestActivity = useLatestActivity()

    // const timeoutTimestamp = latestActivity.latestActivityTimestamp + 60000 * 1
    // Note: make 15 seconds less than the timeout of 30 minutes
    const timeoutTimestamp = latestActivity.latestActivityTimestamp + (60000 *  29) + 45000
    let secondsTillTimeout = Math.round((timeoutTimestamp - Date.now()) / 1000)
    if(secondsTillTimeout < 0) secondsTillTimeout = 0;
    const displayModal = secondsTillTimeout <= 15
    // console.log(secondsTillTimeout)
    
    const onClose = () =>
    {
        dispatch(setLatestActivityTimestamp(Date.now()))
    }

    return (
        <>
            <Modal isOpen={displayModal}>
                <ModalHeader>We've noticed you've been away</ModalHeader>
                <ModalBody>
                    <p>
                        <Text>
                            We'll automatically log you out in {secondsTillTimeout} seconds for your protection unless you click 'Remain logged in'
                        </Text>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Remain logged in</Button>
                </ModalFooter>
            </Modal>
            <Outlet />
        </>
    )
}