import { Grid, GridItem } from '@chakra-ui/react'
import { Block, Button, Divider, Link, Text } from '@stadion/bright'
import React from 'react'

export default function IncomeStickyFooter({
    backLabel = 'Back',
    continueLabel = 'Continue',
    income401k = 0,
    start401k = 0,
    includeSocsec = false,
    incomeSocsec = 0,
    startSocsec = 0,
    includePension = false,
    incomePension = 0,
    startPension = 0,
    continueHandler,
    backHandler
})
{
   
    let totalIncome = income401k;
    if(includeSocsec === true) totalIncome = totalIncome + incomeSocsec;
    if(includePension === true) totalIncome = totalIncome + incomePension;
    
    return (
        <div style={{ width: '100%', position: 'sticky', bottom: 0 }}>
            <Divider />
            <Block
                palette='baseOne'
                paddingTop='md'
                paddingBottom='4xl'
            >
                <Grid
                    gridTemplateAreas={
                        [
                            `"summary"
                            "buttons"`,
                            `"buttons summary"`,
                        ]
                    }
                    gridTemplateColumns={['full', '1fr 1fr']}
                    rowGap="4"
                >
                    <GridItem
                        area={'buttons'}
                        alignSelf="end"
                    >
                        <Grid
                            columnGap='4'
                            rowGap='3'
                            templateColumns={['1fr', 'auto auto']}
                            justifyContent="start"
                        >
                            <Button onClick={backHandler} variant='secondaryButton'>{backLabel} </Button>
                            <Button onClick={continueHandler}>{continueLabel}</Button>
                        </Grid>
                    </GridItem>
                    <GridItem area={'summary'}>
                        <Grid templateColumns={"repeat(3, 1fr)"} gap={1}>
                            <Text variant='primary' as="b">Income sources</Text>
                            <Text variant='primary' as="b">Start age</Text>
                            <Text variant='primary' as="b">Monthly amount</Text>
                            <Text variant='primary'>401(k)</Text>
                            <Text variant='primary'>{start401k}</Text>
                            <Text variant='primary'>${Math.round(income401k).toLocaleString()}</Text>
                            {(includeSocsec === true) && (<Text variant='primary'>Social Security</Text>)}
                            {(includeSocsec === true) && (<Text variant='primary'>{startSocsec}</Text>)}
                            {(includeSocsec === true) && (<Text variant='primary'>${Math.round(incomeSocsec).toLocaleString()}</Text>)}
                            {(includePension === true) && (<Text variant='primary'>Pension</Text>)}
                            {(includePension === true) && (<Text variant='primary'>{startPension}</Text>)}
                            {(includePension === true) && (<Text variant='primary'>${Math.round(incomePension).toLocaleString()}</Text>)}
                            <GridItem colSpan={3}><Divider /></GridItem>
                            <GridItem colSpan={2}><Text variant='primary' as="b">Est income total</Text></GridItem>
                            <Text variant='primary'>${Math.round(totalIncome).toLocaleString()}</Text>
                            <GridItem colSpan={3}><Text variant='primary' level="xs">Income amounts are subject to changes in market conditions.</Text></GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </Block>
        </div>
    )
}