import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Alert, ElevatedCard, Link, QuitAndRestartModal, PageHeadingBlock, PageLoading, Stack, Block, Button, Adjacent, Text } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import PageWrapper from '../../PageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggle } from '../../../store/slices/quitModal'
import { useUpdateUserMutation, useGetWebsiteModelsMutation } from '../../../store/api'
import { setPlanAccepted, setPlanAcceptedNotification, setHideIncomeReminder, setUpdatePlanNotification } from '../../../store/store'
import RiskProfileChart from '../../charts/RiskProfileChart';
import DonutChart from '../../charts/DonutChart'
import useUser from '../../../store/useUser'
import ReactGA from 'react-ga4';
import moment from 'moment/moment'


function IncomeAccept() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customSocsecStartAge = useSelector(state => state.incomePlan.customSocsecStartAge)
    const customPensionStartAge = useSelector(state => state.incomePlan.customPensionStartAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customMonthlySocsec = useSelector(state => state.incomePlan.customMonthlySocsec)
    const customMonthlyPension = useSelector(state => state.incomePlan.customMonthlyPension)
    const customGraphSmileMode = useSelector(state => state.incomePlan.customGraphSmileMode)
    const includeSocsec = useSelector(state => state.incomeAccount.includeSocsec)
    const includePension = useSelector(state => state.incomeAccount.includePension)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const ptileOverride = useSelector(state => state.incomePlan.ptileOverride)
    const riskProfileOverride = useSelector(state => state.incomePlan.riskProfileOverride)
    const [ getWebsiteModels, websiteModelsResponse ] = useGetWebsiteModelsMutation()
    const [updateUser, updateUserResponse] = useUpdateUserMutation()

    const [ errors, setErrors ] = useState({})

    const rc2 = websiteModelsResponse.data?.status?.rc
    const user = useUser()
    
    const allocationDataList = useSelector(state => state.donutData.allocationDataList)
    const [ allocDataList, setAllocDataList ] = useState(allocationDataList)
    
    let riskProfileText = user?.pqdiaResp?.riskProfileText

   
    useEffect(() =>
    {
        //if (((ptileOverride !== undefined) && (ptileOverride > 0)) || (allocDataList === null) || (allocDataList === undefined))
        //{ NOTE-get new donut/allocation data every time, might have changed so ptileoverride went back to 0
            // console.log("ptileOverride to getWebsiteModels: ", ptileOverride, user, user.incPtileOverride)
            getWebsiteModels({
                palette: 3,
                sendModelsToS3: true,
                ptileOverride: ptileOverride,
                prevPtileOverride: user?.incPtileOverride
            })
        //}
    }, [])
   
    
    useEffect(() =>
    {
        if (rc2 < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: websiteModelsResponse.data?.status?.msg })
        }
        //else if (((websiteModelsResponse !== undefined) && (ptileOverride > 0)) || (allocDataList === null) || (allocDataList === undefined))
        else
        {
            setAllocDataList(websiteModelsResponse.data?.allocationDataList)
        }
    }, [websiteModelsResponse, rc2])
        
   
    
    const restart = () =>
    {
        toggleModal()
        navigate('/participant/dashboard')
    }
    
    const handleBackClick = () =>
    {
        navigate(-1)
    }
    const handleAcceptClick = () =>
    {
        ReactGA.event({'category': 'Income Accept', 'action': 'Income Plan Accept Clicked'})

        dispatch(setPlanAccepted(true));
        dispatch(setPlanAcceptedNotification(true));
        dispatch(setHideIncomeReminder(true));
        dispatch(setUpdatePlanNotification(true));
  
        let profileFields = [];
        profileFields.push("incSmileChartMode", acceptedPlan > 0 ? customGraphSmileMode : 1);
        profileFields.push("incomeEndAgeOverride", customEndAge);
        profileFields.push("incomeStartAgeOverride", customStartAge);
        profileFields.push("socsecStartAgeOverride", customSocsecStartAge);
        profileFields.push("pensionStartAgeOverride", customPensionStartAge);
        profileFields.push("incIncludeSocsec", includeSocsec);
        profileFields.push("incIncludePension", includePension);
        profileFields.push("monthlyIncomeAtRetireOverride", customMonthlyIncome);
        profileFields.push("monthlySocsecAtRetireOverride", customMonthlySocsec);
        profileFields.push("monthlyPensionAtRetireOverride", customMonthlyPension);
        profileFields.push("incomeAcceptedPlan", acceptedPlan);
        profileFields.push("incPtileOverride", ptileOverride);
        profileFields.push("incRiskProfileOverride", riskProfileOverride);
        profileFields.push("incomeAcceptedPlanDate", moment(new Date()).format('YYYY-MM-DD hh:mm:ss'))
       
        updateUser({ profileFields })
        
        navigate('/income/confirm')
    }
    
    
    const errorEntries = Object.values(errors).filter(err => err)   
    
    let ptileOverrideText = ""
    let retakeQuestionnaireText = ""
    if((ptileOverride !== undefined) && (ptileOverride > 0)) {
        ptileOverrideText = "The desired monthly income you've entered will materially change the investment profile Stadion created for you. Your investment profile includes your risk tolerance and retirement portfolio allocation. Please review the changes to your investment profile. If you agree to these changes, Stadion will formalize and implement your changes."
        retakeQuestionnaireText = "Retaking the questionnaire will override previous information you have provided and will likely change your current retirement portfolio, projected balances, and income estimates."
    }
    
    if(riskProfileOverride !== undefined) {
        if(riskProfileOverride === 1) riskProfileText = "Aggressive"
        else if(riskProfileOverride === 2) riskProfileText = "Moderate"
        else if(riskProfileOverride === 3) riskProfileText = "Conservative"
    }
    
    

    return (
        <PageWrapper pageTitle="Income Plan Accept">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading={"Your information"}
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }
                    
                    <Box mt={5}>
                        <Text level="xs">
                            Please review your investment profile based on your desired monthly income input.
                        </Text>
                    </Box>

                    <Box mt={2}>
                        <Text level="xs">
                           {ptileOverrideText}
                        </Text>
                    </Box>
                          
                    <Box mt={5}>
                        <Accordion allowToggle={true}  defaultIndex = { [0]  }>
                            <AccordionItem>
                                <AccordionButton>
                                    Risk assessment
                                </AccordionButton>
                                <AccordionPanel px="0" py="2">
                                    <Stack space="md">
                                        {!(websiteModelsResponse.isLoading || websiteModelsResponse.isFetching) ? (
                                            <div>
                                                <Box mt={5}>
                                                    <RiskProfileChart riskProfile={riskProfileText} />
                                                </Box>
                                            </div>
                                        ) : (
                                            <PageLoading />
                                        )
                                        }
                                        <Box mt={2}>
                                            <Text level="xs">
                                                {retakeQuestionnaireText}
                                            </Text>
                                        </Box>                                        
                                        <Link
                                            href="/participant/questionnaire/questions-1"
                                            variant="secondaryButton"
                                            level="2xs"
                                        >
                                            Retake the questionnaire
                                        </Link>
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                    <Box mt={5}>
                        <Accordion allowToggle={true} defaultIndex = { [0]  }>
                            <AccordionItem>
                                <AccordionButton>
                                    Your retirement portfolio
                                </AccordionButton>
                                <AccordionPanel px="0" py="2">
                                    {!(websiteModelsResponse.isLoading || websiteModelsResponse.isFetching) ? (
                                        <DonutChart user={user} allocationDataList={allocDataList} allowAgeChange={false} />
                                    ) : (
                                        <PageLoading />
                                    )
                                    }
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>


                    <Box mt={8}>
                        <Adjacent space="md">
                            <Button onClick={handleBackClick} variant="secondaryButton">
                                Back
                            </Button>
                            <Button onClick={handleAcceptClick} isLoading={updateUserResponse.isLoading}>

                                Accept
                            </Button>
                        </Adjacent>
                    </Box>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

export default IncomeAccept