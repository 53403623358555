import { Box, Grid, GridItem  } from "@chakra-ui/layout";
import { Frame, Stack, Text } from "@stadion/bright";
import { ModalRiskProfile } from '../../utils/modals'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function RiskProfileChart({ riskProfile }) {
   // Set options for the donut chart
   const options = getOptions(riskProfile);

   return (
      <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} columnGap="1" rowGap="1">
         <GridItem>
            <Stack space="md">
               <Text level="lg" variant="primary" as="b">
                  You’ve completed your questionnaire
               </Text>
               <Text as="p">
                  Using your answers, we’ve selected a risk profile that matches your attitudes about investing for retirement.
               </Text>
               <Text as="p">
                  Your risk profile determines how your account will be invested. It also determines how we’ll manage your investments between now and the time you retire.
               </Text>
               {/* <Text as="p" level="xs" variant="accentPrimary">
                  <InfoTooltip label="Learn more about your risk profile here." variant="FunctionalQuestion" textIn=" Learn more about your risk profile."/>
               </Text> */}
               <Box>
                  <ModalRiskProfile />
               </Box>
            </Stack>
         </GridItem>
         <GridItem>
            <Frame alignY="center" alignX="center">
               <Box>
                  <Stack space="md">
                     <Box>
                        {/* <Frame palette="baseOne" alignY="center" alignX="center"> */}
                        {riskProfile ? (
                           <HighchartsReact highcharts={Highcharts} options={options} />
                        ) : (
                           <Text as="p">There is no risk profile data to display.</Text>
                        )}
                        {/*</Frame> */}
                     </Box>
                  </Stack>
               </Box>
            </Frame>
         </GridItem>
      </Grid>

   );
}

function getOptions(riskProfile) {
   let color1 = "#d4e0e8";
   let color2 = "#d4e0e8";
   let color3 = "#d4e0e8";
   let riskProfileTxt = "";
   if (riskProfile === 1 || riskProfile === "Conservative") {
      riskProfileTxt = "Conservative";
      color1 = "#00819d";
   }
   if (riskProfile === 2 || riskProfile === "Moderate") {
      riskProfileTxt = "Moderate";
      color2 = "#00819d";
   }
   if (riskProfile === 3 || riskProfile === "Aggressive") {
      riskProfileTxt = "Aggressive";
      color3 = "#00819d";
   }

   return {
      chart: {
         width: 250,
         height: 250,
         marginBottom: -40,
         backgroundColor: "transparent",
         events: {
            load: function () {
               let textElement =
                  '<div style="font-size:26px;color:#000;text-align:center;white-space:nowrap">' +
                  riskProfileTxt +
                  "</div>" +
                  '<div style="font-size:12px;color:#000;text-align:center;white-space:nowrap">Risk Profile</div>';
               this.setTitle({
                  text: textElement,
                  align: "center",
                  verticalAlign: "middle",
                  useHTML: true,
                  y: 40,
                  //x: 0,
               });
            },
         },
         scrollablePlotArea: {
            minWidth: 200,
         },
      },
      tooltip: {
         enabled: false,
      },
      accessibility: {
         enabled: false,
      },
      plotOptions: {
         series: {
            size: 225,
            enableMouseTracking: false,
            dataLabels: {
               enabled: false,
            },
            borderWidth: 2,
            borderRadius: 0,
            startAngle: -125,
            endAngle: 125,
            //size: "100%",
            innerSize: "88%",
            label: { enabled: false },
         },
      },
      series: [
         {
            type: "pie",
            name: "Risk profile",
            data: [
               { y: 20, color: color1 },
               { y: 20, color: color2 },
               { y: 20, color: color3 },
            ],
         },
      ],
      yAxis: {
         title: {
            text: null,
         },
      },
      xAxis: { visible: false },
      credits: { enabled: false },
   };
}

export default RiskProfileChart;
