import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
   Adjacent,
   Button,
   Frame,
   Stack,
   Text,
   useIsMobileViewport,
} from "@stadion/bright";
import { Box, Grid } from "@chakra-ui/layout";
import React, { useState, useEffect } from "react";

function LineChart401k({ lineData }) {
   const [lineChartData, setLineChartData] = useState([]);
   const isMobile = useIsMobileViewport();

   useEffect(() => {
      if(lineData !== undefined) {
         const updatedData = lineData?.slice();
         setLineChartData(updatedData);
      }
   }, [lineData]);

   function handleLast3MonthsClick() {
      if(lineData !== undefined) {
         const updatedData = lineData?.slice(-3);
         setLineChartData(updatedData);
      }
   }
   function handleLast12MonthsClick() {
      if(lineData !== undefined) {
         const updatedData = lineData?.slice(-12);
         setLineChartData(updatedData);
      }
   }
   function handleAllClick() {
      if(lineData !== undefined) {
         const updatedData = lineData?.slice();
         setLineChartData(updatedData);
      }
   }

   const options = getOptions(lineChartData, isMobile);

   return (
      <div>
         <Box ml={3} mt={1} mb={3}>
            <Grid
               templateColumns={["auto", "2fr 1fr"]}
               columnGap="1"
               rowGap="1"
            >
               <Box>
                  <Text level="lg" variant="primary" as="b">
                     Account Balance with Stadion
                  </Text>
               </Box>
               <Stack space="sm">
                  <Adjacent space="xs">
                     <Box
                        display="flex"
                        justifyContent="end"
                        ml="100"
                        sx={{
                           "@media screen and (max-width: 600px)": {
                              justifyContent: "start",
                              ml: "-5",
                           },
                        }}
                     >
                        <Button
                           onClick={handleAllClick}
                           variant="ghostButton"
                           size="sm"
                           level="xs"
                        >
                           All
                        </Button>
                        <Button
                           onClick={handleLast12MonthsClick}
                           variant="ghostButton"
                           size="sm"
                        >
                           1 year
                        </Button>
                        <Button
                           onClick={handleLast3MonthsClick}
                           variant="ghostButton"
                           size="sm"
                        >
                           3 months
                        </Button>
                     </Box>
                  </Adjacent>
               </Stack>
            </Grid>
         </Box>

         <Frame>
            <div style={{ position: "relative", width: "100%" }}>
               {(lineChartData !== undefined) && (lineChartData.length > 0) ? (
                  <HighchartsReact highcharts={Highcharts} options={options} />
               ) : (
                  <Box m={4}>
                     <Text as="p" level="xs">There is no historical 401(k) balance to display in the chart.</Text>
                  </Box>
               )} 
            </div>
         </Frame>
         {lineChartData ? (
            <Frame alignX="center" alignY="center">
               <div
                  style={{
                     backgroundColor: "#00819D",
                     width: 10,
                     height: 10,
                     marginRight: 6,
                     borderRadius: 2,
                  }}
               ></div>{" "}
               <Text level="2xs">401(k) balance</Text>
            </Frame>
         ) : (
            ""
         )}
      </div>
   );
}

function getOptions(lineChartDataIn, isMobile) {
   // Convert yyyy-mm-dd to milliseconds for xaxis
   const lineChartData = lineChartDataIn?.map(lc => ({...lc,  as_of_dt: new Date(lc.as_of_dt).getTime()}))
   // const lineChartData = lineChartDataIn.map(lc => {
   //    const as_of_dt = new Date(lc.as_of_dt).getTime();
   //    return {...lc,  as_of_dt}
   // })
   // lineChartData.forEach((element) =>
   // {
   //    console.log(element);
   // });
   return {
      chart: {
         type: "line",
         marginRight: 30,
      },
      credits: {
         enabled: false,
      },
      accessibility: {
         enabled: false,
      },
      title: {
         text: "",
      },
      tooltip: {
         backgroundColor: "#003357",
         style: {
            color: "#ffffff",
            fontFamily:
               'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
         },
         useHTML: true,
         formatter: function () {
            return `<div style="font-family: lato; display: grid; grid-template-columns: auto; grid-gap: 5px">
                        <div style="display: grid; grid-template-columns: 65px 65px">
                            <div>Date</div>
                            <div style="justify-self: end">${Highcharts.dateFormat(
                               "%b %Y",
                               this.x
                            )}</div>
                        </div>
                        <div style="background-color: rgba(255, 255, 255, 0.15); height: 2px">
                        </div>
                        <div style="display: grid; grid-template-columns: 65px 65px">
                            <div>Balance</div>
                            <div style="justify-self: end">$${this.y.toLocaleString()}</div>
                        </div>
                    </div>
                    `;
         },
      },
      xAxis: {
         type: "datetime",
         //  dateTimeLabelFormats: {
         //     month: "%b %Y", //ex- 01 Jan 2016
         //  },
         //  categories: calcData?.chart?.filter((value, index) => !index % 2),
         //  tickInterval: isMobile ? 4 : 2,
         tickLength: 0,
         // tickmarkPlacement: 'on',

         tickPositioner: function () {
            const ticks = this.tickPositions;

            if (!ticks.includes(this.dataMax)) ticks.push(this.dataMax);
            if (!ticks.includes(this.dataMin)) ticks.push(this.dataMin);

            ticks.sort((a, b) => a - b);

            while (ticks[ticks.length - 1] > this.dataMax) {
               ticks.pop();
            }

            return ticks;
         },
         showFirstLabel: true,
         showLastLabel: true,
         // startOnTick: true,
         // endOnTick: true,
         labels: {
            formatter() {
               if (this.isFirst || this.isLast) {
                  return Highcharts.dateFormat("%b %Y", this.value);
               } else {
                  return "";
               }
            },
            style: {
               fontSize: isMobile ? '9px' : '12px',
               fontFamily:
                  'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            },
         },
      },
      series: [
         {
            // data: lineChartData,
            data: lineChartData.map(dataEl => [dataEl.as_of_dt, dataEl.aum]),
            color: "#00819D",
            name: "401k",
         },
      ],
      yAxis: {
         labels: {
            style: {
               fontSize: isMobile ? '9px' : '12px',
               fontFamily:
                  'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            },
            formatter: function () {
               return `$${this.value.toLocaleString()}`;
            },
         },
         title: {
            text: null,
         },
         tickPixelInterval: 100,
      },
      legend: {
         enabled: false,
      },
      plotOptions: {
         series: {
            pointWidth: isMobile ? 8 : 20,
            marker: {
               enabled: false,
            },
         },
      },
   };
}

export default LineChart401k;
