import { Box, Container, Flex } from '@chakra-ui/layout'
import { ElevatedCard, Heading, PageHeadingBlock, Stack, TextInput, Block, PasswordInput, Button, MainWrapper, Text, Alert, Copy } from '@stadion/bright'
import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router'
import { useUpdatePasswordMutation } from '../../store/api'
import { useSelector } from 'react-redux'
import PageWrapper from '../PageWrapper'



function UpdatePassword() {
    const [ updatePw, updatePwResponse ] = useUpdatePasswordMutation()
    const username = useSelector(state => state.resetPw.username)
    const [ verificationCode, setVerificationCode ] = useState('')
    const [ passwordNew, setPasswordNew ] = useState('')
    const [ passwordNew2, setPasswordNew2 ] = useState('')
    const [ passwordNewInput, setPasswordNewInput ] = useState({ current: null })
    const [ passwordNew2Input, setPasswordNew2Input ] = useState({ current: null })
    const resetTitleMsg = useSelector(state => state.resetPw.resetTitleMsg)

    
    useEffect(() => {
        if (!username)
            return
        const passwordNewInputElement = document.getElementById('passwordNew')
        const passwordNew2InputElement = document.getElementById('passwordNew2')

        // passwordNewInputElement.setAttribute('pattern', '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!$%@^&amp;*_-]).{8,}$')
        // passwordNew2InputElement.setAttribute('pattern', '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!$%@^&amp;*_-]).{8,}$')
        passwordNewInputElement.setAttribute('pattern', '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!$%@^&*_\\-])[A-Za-z\\d#?!$%@^&*_\\-]{8,}$')
        passwordNew2InputElement.setAttribute('pattern', '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!$%@^&*_\\-])[A-Za-z\\d#?!$%@^&*_\\-]{8,}$')

        setPasswordNewInput({ current: passwordNewInputElement })
        setPasswordNew2Input({ current: passwordNew2InputElement })
    }, [])


    useEffect(() => {
        if (!passwordNew2Input.current)
            return

        if (passwordNew !== passwordNew2)
            passwordNew2Input.current.setCustomValidity(`Passwords must match`)
        else
            passwordNew2Input.current.setCustomValidity('')
    }, [ passwordNew, passwordNew2 ])

    if (!username)
        return <Navigate to="/forgot-password" />
    

    const updatePwRc = updatePwResponse.data?.status?.rc === 0
    const updatePwError = (
        updatePwResponse.data?.status?.rc !== undefined &&
        updatePwResponse.data?.status?.rc !== 1
    )
    

    if (updatePwRc)
        return <Navigate to="/login" />
   
    const handleSubmit = e => {
        e.preventDefault()
        updatePw({username, verificationCode, passwordNew, passwordNew2 })
    }

 
    const verificationCodeErrors = updatePwError ? [ 'Please check your input' ] : []
    const passwordNewErrors = updatePwError ? [ 'Please check your input' ] : []
    const passwordNew2Errors = updatePwError ? [ 'Please check your input' ] : []
    
    const content = (
        <Container>
            <ElevatedCard>
                <Stack space="xl">
                    <Text level="xl" variant="primary" as="b">
                        Reset password
                    </Text>
                    if(resetTitleMsg)
                    {
                        <Text as="b" variant="error">
                          { resetTitleMsg }
                        </Text>
                    }
                    <Alert
                        variant="info"
                        heading="Please use the verification code sent to your email to reset your password."
                        content="<p>If you didn't receive an email, please make sure you entered the correct username and try again or <a href='https://stadionstoryline.com/contact-us'>contact us.</a></p>"
                    >
                    </Alert>
                    {
                        updatePwError &&
                        <Alert variant="negative">
                            <Heading as="h5" color="contentSecondary">
                                { updatePwResponse.data?.status?.msg }
                            </Heading>
                        </Alert>
                    }
                    <form onSubmit={ handleSubmit }>
                        <Stack space="xl">
                            <TextInput
                                label="Verification code"
                                isRequired={ true }
                                value={ verificationCode }
                                onChange={ e => setVerificationCode(e.target.value) }
                                errors={ verificationCodeErrors }
                            />
                            <PasswordInput
                                inputRef={ passwordNewInput }
                                label="Password"
                                ariaLabel="Password"
                                description="Minimum of 8 characters; one number, one uppercase and one lowercase letter, special character #?!@$%^&*-"
                                isRequired={ true }
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!$%@^&*_\\-])[A-Za-z\\d#?!$%@^&*_\\-]{8,}$"
                                name="passwordNew"
                                id="passwordNew"
                                errors={ passwordNewErrors }
                                value={ passwordNew }
                                onChange={ e => setPasswordNew(e.target.value) }
                            /> 
                            <PasswordInput
                                inputRef={ passwordNew2Input }
                                label="Confirm password"
                                ariaLabel="Confirm password"
                                description="Minimum of 8 characters; one number, one uppercase and one lowercase letter, special character #?!@$%^&*-"
                                isRequired={ true }
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!$%@^&*_\\-])[A-Za-z\\d#?!$%@^&*_\\-]{8,}$"
                                name="passwordNew2"
                                id="passwordNew2"
                                errors={ passwordNew2Errors }
                                value={ passwordNew2 }
                                onChange={ e => setPasswordNew2(e.target.value) }
                            />                          
                             <Button
                                type="submit"
                                variant="primaryButton"
                                isLoading={ updatePwResponse.isLoading }
                            >
                                Submit
                            </Button>
                        </Stack>
                    </form>
                 </Stack>
            </ElevatedCard>
        </Container>
    )
    return (
        <PageWrapper pageTitle="Reset Password">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="6xl">
                                <Box>{content}</Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default UpdatePassword