import { Footer } from "@stadion/bright";
import { useLocation } from "react-router";



function AppFooter() {
    const location = useLocation()

    const content = 
        `<div>
        <p>© ${new Date().getFullYear()} Stadion Money Management
        </p>
        <p><a href="https://cdn.stadionmoney.com/Footer/Site_Terms.pdf">Site terms</a>    <a href="https://cdn.stadionmoney.com/Footer/Stadion_FormADVPart2A_PrivacyNotice.pdf">Form ADV Part 2</a>    <a href="https://cdn.stadionmoney.com/Retirement/Misc/PRIVACY_NOTICE_01_2016.pdf">Privacy notice</a>
        </p>
        <p>Stadion, the Stadion S, and Start smart. Finish strong. are registered service marks of Stadion Money Management, LLC.<br>StoryLine is a service mark of Stadion Money Management, LLC.
        </p>    
        <p>Stadion is an investment adviser registered with the U.S. Securities and Exchange Commission.<br>Registration does not imply a certain level of skill or training. More information about Stadion's investment advisory services can be found in its Form ADV Part 2, which is available upon request.
        </p>
        </div>`
    

    return <Footer palette={ location.pathname.includes('/participant') && !location.pathname.includes('/dashboard') ? "baseOne" : "baseThree" } content={ content } />
}

export default AppFooter