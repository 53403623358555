
import { BrightProvider } from "@stadion/bright";
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import Auth from "./components/Auth";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import UpdatePassword from "./components/pages/UpdatePassword";
import Profile from "./components/pages/profile-setup/Profile";
import GetStarted from "./components/pages/profile-setup/GetStarted";
import SignUp from "./components/pages/SignUp";
import ForgotUsername from "./components/pages/ForgotUsername";
import Wizard from "./components/pages/profile-setup/Wizard";
import FinancialInformation from "./components/pages/profile-setup/FinancialInformation";
import Home from "./components/pages/Home";
import { setLatestActivityTimestamp, store } from "./store/store";
import Contact from "./components/pages/Contact";
import Dashboard from "./components/pages/Dashboard";
import Questions1 from "./components/pages/profile-setup/Questions1";
import Questions2 from "./components/pages/profile-setup/Questions2";
import Questions3 from "./components/pages/profile-setup/Questions3";
import Questions4 from "./components/pages/profile-setup/Questions4";
import Questions5 from "./components/pages/profile-setup/Questions5";
import Review from "./components/pages/profile-setup/Review";
import ToA from "./components/pages/profile-setup/ToA";
import routes, { participantRoutes, wizardRoutes } from "./routes";
import IncomeWelcome from "./components/pages/income/IncomeWelcome";
import IncomeStep1 from "./components/pages/income/IncomeStep1";
import IncomeCustomize1 from "./components/pages/income/IncomeCustomize1";
import IncomeCustomize2 from "./components/pages/income/IncomeCustomize2";
import IncomeCustomizeSocsec from "./components/pages/income/IncomeCustomizeSocsec";
import IncomeCustomizePension from "./components/pages/income/IncomeCustomizePension";
import IncomeCustomizeConfirm from "./components/pages/income/IncomeCustomizeConfirm";
import IncomePlanSummary from "./components/pages/income/IncomePlanSummary";
import IncomeReview from "./components/pages/income/IncomeReview";
import IncomeAccept from "./components/pages/income/IncomeAccept";
import IncomeConfirm from "./components/pages/income/IncomeConfirm";
import IncomeComparePlans from "./components/pages/income/IncomeComparePlans";
import PaymentSetup from "./components/pages/payment/PaymentSetup";
import NotFound from "./components/pages/NotFound";
import RouteChangeTrackerGA from "./RouteChangeTrackerGA";
import { throttle } from 'underscore'

export function SessionHandler() {
  const dispatch = useDispatch()

  function handleInput(e) {
    dispatch(setLatestActivityTimestamp(Date.now()))
  }

  // const throttledInput = throttle(handleInput, 5000)
  const throttledInput = throttle(handleInput, 60000)

  useEffect(() => {
    window.addEventListener('mousemove', throttledInput)
    window.addEventListener('keydown', throttledInput)
    window.addEventListener('click', throttledInput)

    return () => {
      window.removeEventListener('mousemove', throttledInput)
      window.removeEventListener('keydown', throttledInput)
      window.removeEventListener('click', throttledInput)
    }
  }, [])

  return null
}

function App() {
  
  return (
    <Provider store={ store }>
      <SessionHandler />
      <Router>
        <RouteChangeTrackerGA />
        <BrightProvider>
            <Routes>
              <Route path="/" element={ <Home /> } />
              <Route path={ routes.login } element={ <Login /> } />
              <Route path={ routes.forgotPassword } element={ <ForgotPassword /> } />
              <Route path={ routes.updatePassword } element={ <UpdatePassword /> } />
              <Route path={ routes.signup } element={ <SignUp /> } />
              <Route path={ routes.forgotUsername } element={ <ForgotUsername /> } />
              <Route path={ routes.contact } element={ <Contact /> } />
              <Route element={ <Auth /> }>
                <Route path={ routes.income } element={ <Outlet /> }>
                  <Route path={ routes.incomeWelcome } element={ <IncomeWelcome /> } />
                  <Route path={ routes.incomeStep1 } element={ <IncomeStep1 /> } />
                  <Route path={ routes.incomeCustomize1 } element={ <IncomeCustomize1 /> } />
                  <Route path={ routes.incomeCustomize2 } element={ <IncomeCustomize2 /> } />
                  <Route path={ routes.incomeCustomizeSocsec } element={ <IncomeCustomizeSocsec /> } />
                  <Route path={ routes.incomeCustomizePension } element={ <IncomeCustomizePension /> } />
                  <Route path={ routes.incomeCustomizeConfirm } element={ <IncomeCustomizeConfirm /> } />
                  <Route path={ routes.incomePlanSummary } element={ <IncomePlanSummary /> } />
                  <Route path={ routes.incomeReview } element={ <IncomeReview /> } />
                  <Route path={ routes.incomeConfirm } element={ <IncomeConfirm /> } />
                  <Route path={ routes.incomeAccept } element={ <IncomeAccept /> } />
                  <Route path={ routes.incomeComparePlans } element={ <IncomeComparePlans /> } />
                </Route>
                <Route path={ routes.payment } element={ <Outlet /> }>
                  <Route path={ routes.paymentSetup } element={ <PaymentSetup /> } />
                </Route>
                <Route path={ routes.participant } element={ <Outlet /> }>
                  <Route path={ participantRoutes.getStarted } element={ <GetStarted /> } />
                  <Route path={ routes.wizard } element={ <Wizard /> }>
                    <Route path={ wizardRoutes.profile } element={ <Profile /> } />
                    <Route path={ wizardRoutes.financialInformation } element={ <FinancialInformation /> } />
                    <Route path={ wizardRoutes.questions1 } element={ <Questions1 /> } />
                    <Route path={ wizardRoutes.questions2 } element={ <Questions2 /> } />
                    <Route path={ wizardRoutes.questions3 } element={ <Questions3 /> } />
                    <Route path={ wizardRoutes.questions4 } element={ <Questions4 /> } />
                    <Route path={ wizardRoutes.questions5 } element={ <Questions5 /> } />
                    <Route path={ wizardRoutes.review } element={ <Review /> } />
                    <Route path={ wizardRoutes.toa } element={ <ToA /> } />
                  </Route>
                  <Route path={ routes.dashboard } element={ <Dashboard /> } />
                </Route>
              </Route>
              <Route path="*" element={ <NotFound /> }></Route>
            </Routes>
        </BrightProvider>
      </Router>
    </Provider>
  );
}

export default App;
