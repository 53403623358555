import { Alert, Button, Checkbox, CurrencyInput, Frame, Heading, Link, NumberInput, PercentageInput, Radio, RadioConditional, RadioGroup, Select, Stack, Text } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { useUpdateUserMutation } from '../../../store/api'
import { errorTemplates, getErrors, getFormFields } from '../../../utils/forms'
import { Navigate } from 'react-router-dom'
import { wizardRoutes } from '../../../routes'
import { Grid } from '@chakra-ui/layout'
import useUser from '../../../store/useUser'

function FinancialInformation() {
    const [ errors, setErrors ] = useState({})
    const [ userInfo, setUserInfo ] = useState({ sharedData: true })

    const [ salaryInput, setSalaryInput ] = useState(null)
    const [ contributionRateInput, setContributionRateInput ] = useState(null)
    const [ expectedRetirementAgeInput, setExpectedRetirementAgeInput ] = useState(null)
    const [ current401kBalanceInput, setCurrent401kBalanceInput ] = useState(null)
    const [ outsideAssetsInput, setOutsideAssetsInput ] = useState(null)
    const [ pensionsInput, setPensionsInput ] = useState(null)
    const [ genderInput, setGenderInput ] = useState(null)
    const [ personalDebtInput, setPersonalDebtInput ] = useState(null)
    const [ numDependentsInput, setNumDependentsInput ] = useState(null)

    const [ hasDependents, setHasDependents ] = useState('no')

    const user = useUser()
    const [ updateUser, updateUserResponse ] = useUpdateUserMutation()

    useEffect(() => {
        const salaryInputElement = document.getElementById('salary')
        const contributionRateInputElement = document.getElementById('contributionRate')
        const expectedRetirementAgeInputElement = document.getElementById('expectedRetirementAge')
        const current401kBalanceInputElement = document.getElementById('current401kBalance')
        const outsideAssetsInputElement = document.getElementById('outsideAssets')
        const pensionsInputElement = document.getElementById('pensions')
        const genderInputElement = document.querySelector('[name=gender]')
        const personalDebtInputElement = document.getElementById('personalDebt')
        const numDependentsInputElement = document.getElementById('numDependents')

        salaryInputElement.setAttribute('pattern', '.*')
        current401kBalanceInputElement.setAttribute('pattern', '.*')
        outsideAssetsInputElement.setAttribute('pattern', '.*')
        pensionsInputElement.setAttribute('pattern', '.*')
        personalDebtInputElement.setAttribute('pattern', '.*')

        setSalaryInput({ current: salaryInputElement })
        setContributionRateInput({ current: contributionRateInputElement })
        setExpectedRetirementAgeInput({ current: expectedRetirementAgeInputElement })
        setCurrent401kBalanceInput({ current: current401kBalanceInputElement })
        setOutsideAssetsInput({ current: outsideAssetsInputElement })
        setPensionsInput({ current: pensionsInputElement })
        setGenderInput({ current: genderInputElement })
        setPersonalDebtInput({ current: personalDebtInputElement })
        setNumDependentsInput({ current: numDependentsInputElement })
    }, [])

    useEffect(() => {
        let retireAge = user?.pqdiaReq?.expectedRetirementAge
        if((retireAge === undefined) || (retireAge <= 0)) retireAge = 67
        setUserInfo({
            salary: user?.pqdiaReq?.salary,
            contributionRate: user?.pqdiaReq?.partDeferralPercentage === undefined ? 0 : user?.pqdiaReq?.partDeferralPercentage,
            expectedRetirementAge: retireAge,
            current401kBalance: user?.pqdiaReq?.vendorAum === undefined ? 0 : user?.pqdiaReq?.vendorAum,
            outsideAssets: user?.pqdiaReq?.outsideAssets,
            pensions: user?.pqdiaReq?.pensions,
            gender: user?.pqdiaReq?.gender,
            personalDebt: user?.pqdiaReq?.personalDebt,
            numDependents: user?.pqdiaReq?.numDependents,
            sharedData: user?.implemented ? Boolean(user?.sharedData) : true
        })

        if (user?.pqdiaReq?.numDependents)
            setHasDependents('yes')
    }, [ ])

    useEffect(() => {
        if (userInfo.numDependents && hasDependents === 'no')
            setUserInfo({
                ...userInfo,
                numDependents: 0
            })
    }, [ hasDependents ])
    
    const handleSubmit = e => {
        e.preventDefault()

        const inputs = [
            salaryInput,
            contributionRateInput,
            expectedRetirementAgeInput,
            current401kBalanceInput,
            outsideAssetsInput,
            pensionsInput,
            genderInput,
            personalDebtInput,
            numDependentsInput
        ]

        let errors = {}

        for (let input of inputs) {
            errors = { ...errors, [input.current.name || input.current.id]: getErrors(input.current, errorTemplates[input.current.name || input.current.id]) }
        }
 
        // Bright allow's 0's for these so manually check
        if (userInfo.salary === undefined || userInfo.salary <= 0) {
            errors = {
                ...errors,
                salary: errors.salary ?
                    [ ...errors.salary, 'Salary must be greater than 0' ] :
                    [ 'Salary must be greater than 0' ]
            }
        }
        // if (userInfo.contributionRate === undefined || userInfo.contributionRate <= 0) {
        //     errors = {
        //         ...errors,
        //         contributionRate: errors.contributionRate ?
        //             [ ...errors.contributionRate, 'Contribution rate must be greater than 0' ] :
        //             [ 'Contribution rate must be greater than 0' ]
        //     }
        // }
        
        // Current problem in the DB not allowing billions to be saved
        if (userInfo?.salary > 999999999) {
            errors = {
                ...errors,
                salary: errors.salary ?
                    [ ...errors.salary, 'Salary exceeds current limits, please enter a value less than $1,000,000,000' ] :
                    [ 'Salary exceeds current limits, please enter a value less than $1,000,000,000' ]
            }
        }     
        if (userInfo?.current401kBalance > 999999999) {
            errors = {
                ...errors,
                current401kBalance: errors.current401kBalance ?
                    [ ...errors.current401kBalance, '401K exceeds current limits, please enter a value less than $1,000,000,000' ] :
                    [ '401K exceeds current limits, please enter a value less than $1,000,000,000' ]
            }
        }
        if (userInfo?.outsideAssets > 999999999) {
            errors = {
                ...errors,
                outsideAssets: errors.outsideAssets ?
                    [ ...errors.outsideAssets, 'Outside assets exceeds current limits, please enter a value less than $1,000,000,000' ] :
                    [ 'Outside assets exceeds current limits, please enter a value less than $1,000,000,000' ]
            }
        }
        if (userInfo?.pensions > 999999999) {
            errors = {
                ...errors,
                pensions: errors.pensions ?
                    [ ...errors.pensions, 'Pensions exceeds current limits, please enter a value less than $1,000,000,000' ] :
                    [ 'Pensions exceeds current limits, please enter a value less than $1,000,000,000' ]
            }
        }
        if (userInfo?.personalDebt > 999999999) {
            errors = {
                ...errors,
                personalDebt: errors.personalDebt ?
                    [ ...errors.personalDebt, 'Debt exceeds current limits, please enter a value less than $1,000,000,000' ] :
                    [ 'Debt exceeds current limits, please enter a value less than $1,000,000,000' ]
            }
        }
        
        setErrors(errors)

        if (!Object
            .values(errors)
            .filter(err => err)
            .length) {
                updateUser({
                    profileFields: [
                        ...getFormFields(
                            userInfo,
                            [ 'salary', 'contributionRate', 'expectedRetirementAge', 'current401kBalance', 'outsideAssets', 'pensions', 'gender', 'personalDebt', 'numDependents', 'dob', 'sharedData' ]
                        )
                ]
            })
        }
    }
    const hasErrors = Object.values(errors).some(Boolean)

    const responseError = updateUserResponse.isSuccess && parseInt(updateUserResponse.data?.status?.rc) !== 0

    if (updateUserResponse.data?.status?.rc === 0)
        return <Navigate to={ wizardRoutes.questions1 } />
        
    function fixNaN(val) {
        val = +Number.parseInt(val?.replace(/,/g, '')) || 0; // Remove commas in currency
        return val.toLocaleString();
    }
    

    return (
        <Frame palette="baseTwo" paddingY="4xl">
            <form noValidate onSubmit={ handleSubmit }>
                <Stack space="6xl" hasDivider={ true }>
                    <Frame width={ [ 'full', '1/2' ] }>
                        <Stack space="4xl">
                            { (hasErrors || responseError) && <Alert variant="negative" heading="Profile update failed" content={ updateUserResponse.data?.status?.msg || updateUserResponse.data?.message || 'Please check the errors below before proceeding' } /> }
                            <CurrencyInput
                                label="Salary (annual) *"
                                aria-label="Salary (annual)"
                                name="salary"
                                id="salary"
                                isRequired={ true }
                                currencyLabel="$"
                                characters={ 12 }
                                min={ 0 }
                                step={ 1000 }
                                value={
                                    fixNaN(
                                      (typeof userInfo.salary === 'number' ?
                                        userInfo.salary.toLocaleString() :
                                        userInfo.salary))
                                }
                                onChange={ salary => setUserInfo({
                                    ...userInfo,
                                    salary: Number.parseInt(salary || 0)
                                }) }
                                errors={ errors.salary || [] }
                            />
                            <PercentageInput
                                label="Contribution rate (%) *"
                                aria-label="Contribution rate"
                                name="contributionRate"
                                id="contributionRate"
                                isRequired={ true }
                                description="<p>Please enter only your current contribution rate. You cannot alter your contribution rate on this form</p>"
                                step={ 1 }
                                min={ 0 }
                                max={ 100 }
                                value={ userInfo.contributionRate }
                                onChange={ contributionRate => setUserInfo({ ...userInfo, contributionRate }) }
                                errors={ errors.contributionRate || [] }
                            />
                            <NumberInput
                                label="Retirement age *"
                                aria-label="Retirement age"
                                name="expectedRetirementAge"
                                id="expectedRetirementAge"
                                isRequired={ true }
                                min={ 55 }
                                max={ 75 }
                                step={ 1 }
                                description="<p>At what age do you intend to retire? Please select an age between 55 and 75</p>"
                                value={ userInfo.expectedRetirementAge }
                                onChange={ expectedRetirementAge => setUserInfo({ ...userInfo, expectedRetirementAge }) }
                                errors={ errors.expectedRetirementAge || [] }
                            />
                        </Stack>
                    </Frame>
                    <Stack space="4xl">
                        <Stack space="4xl">
                            <Heading as="h3">
                                Optional financial information
                            </Heading>
                            <Text as="p">
                                The following fields are not required, but may help us optimize your portfolio.
                            </Text>
                            <Frame width={ [ 'full', '1/2' ] }>
                                <CurrencyInput
                                    label="Current 401(k) balance"
                                    aria-label="Current 401k balance"
                                    name="current401kBalance"
                                    id="current401kBalance"
                                    currencyLabel="$"
                                    characters={ 12 }
                                    min={ 0 }
                                    step={ 1000 }
                                    description="<p>Balance of your 401(k) where you work now. If you have 401(k) accounts from previous employers, please include that information in the Outside Assets field below.</p>"
                                    // value={
                                    //     typeof userInfo.current401kBalance === 'number' ?
                                    //         Number.parseInt(
                                    //             userInfo.current401kBalance
                                    //                 .toString()
                                    //                 .match(/\d/g)
                                    //                 .join('')
                                    //             ).toLocaleString() :
                                    //         userInfo.current401kBalance
                                    // }
                                    value={
                                        fixNaN(typeof userInfo.current401kBalance === 'number' ?
                                            userInfo.current401kBalance.toLocaleString() :
                                            userInfo.current401kBalance)
                                    }
                                      onChange={ current401kBalance => setUserInfo({
                                        ...userInfo,
                                        current401kBalance: Number.parseInt(current401kBalance || 0)
                                    }) }
                                    errors={ errors.current401kBalance || [] }
                                />
                            </Frame>
                            <Frame width={ [ 'full', '1/2' ] }>
                                <CurrencyInput
                                    label="Outside assets"
                                    aria-label="Outside assets"
                                    name="outsideAssets"
                                    id="outsideAssets"
                                    step={ 1000 }
                                    currencyLabel="$"
                                    characters={ 12 }
                                    min={ 0 }
                                    description="<p>Other long term savings, investments, or retirement plans that you or your spouse have</p>"
                                    useOptionalSuffix={ false }
                                    value={
                                        fixNaN(typeof userInfo.outsideAssets === 'number' ?
                                            userInfo.outsideAssets.toLocaleString() :
                                            userInfo.outsideAssets) 
                                    }
                                    onChange={ outsideAssets => setUserInfo({
                                        ...userInfo,
                                        outsideAssets: Number.parseInt(outsideAssets || 0)
                                    }) }
                                    errors={ errors.outsideAssets || [] }
                                />
                            </Frame>
                            <Frame width={ [ 'full', '1/2' ] }>
                                <CurrencyInput
                                    label="Pension & deferred compensation (annual $)"
                                    aria-label="Pension and deferred compensation (annual)"
                                    name="pensions"
                                    id="pensions"
                                    step={ 1000 }
                                    currencyLabel="$"
                                    characters={ 12 }
                                    min={ 0 }
                                    description="<p>Annual income you will receive from pensions and/or deferred compensation plans</p>"
                                    value={
                                        fixNaN(typeof userInfo.pensions === 'number' ?
                                            userInfo.pensions.toLocaleString() :
                                            userInfo.pensions) 
                                    }
                                    onChange={ pensions => setUserInfo({
                                        ...userInfo,
                                        pensions: Number.parseInt(pensions || 0)
                                    }) }
                                    errors={ errors.pensions || [] }
                                />
                            </Frame>
                            <Frame width={ [ 'full', '1/2' ] }>
                                <Select
                                    label="Gender"
                                    ariaLabel="Gender"
                                    placeholder="Please select"
                                    name="gender"
                                    id="gender"
                                    items={
                                        [
                                            { id: '3', label: 'Prefer not to answer' },
                                            { id: '1', label: 'Male' },
                                            { id: '2', label: 'Female' },
                                        ]
                                    }
                                    value={ (userInfo.gender || '').toString() }
                                    onChange={ gender => setUserInfo({ ...userInfo, gender: parseInt(gender) }) }
                                    errors={ errors.gender || [] }
                                />
                            </Frame>
                            <Frame width={ [ 'full', '1/2' ] }>
                                <CurrencyInput
                                    label="Personal debt (non-mortgage)"
                                    aria-label="Personal debt (non-mortgage)"
                                    name="personalDebt"
                                    id="personalDebt"
                                    currencyLabel="$"
                                    characters={ 12 }
                                    step={ 1000 }
                                    min={ 0 }
                                    description="<p>Total amount of personal, non-mortgage related debt, including consumer debt and student loans</p>"
                                    value={
                                        fixNaN(typeof userInfo.personalDebt === 'number' ?
                                            userInfo.personalDebt.toLocaleString() :
                                            userInfo.personalDebt) 
                                    }
                                    onChange={ personalDebt => setUserInfo({
                                        ...userInfo,
                                        personalDebt: Number.parseInt(personalDebt || 0)
                                    }) }
                                    errors={ errors.personalDebt || [] }
                                />
                            </Frame>
                            <Frame width={ [ 'full', '1/2' ] }>
                                <RadioGroup
                                    label="Do you have any dependents?"
                                    value={ hasDependents }
                                    onChange={ hasDependents => setHasDependents(hasDependents) }
                                >
                                    <RadioConditional
                                        label="Yes"
                                        value="yes"
                                    >
                                        <NumberInput
                                            name="numDependents"
                                            id="numDependents"
                                            aria-label="Number of dependents"
                                            description="Enter in the number of dependents"
                                            isRequired={ true }
                                            defaultValue={ 1 }
                                            min={ 1 }
                                            max={ 99 }
                                            value={ userInfo.numDependents }
                                            onChange={ numDependents => setUserInfo({ ...userInfo, numDependents }) }
                                            errors={ errors.numDependents || [] }
                                        />
                                    </RadioConditional>
                                    <Radio
                                        label="No"
                                        description="I do not have any dependents"
                                        value="no"
                                    />
                                </RadioGroup>
                            </Frame>
                            <Frame width={ [ 'full', '1/3' ] }>
                                <Text as="p">
                                    * Required field
                                </Text>
                            </Frame>
                        </Stack>
                        <Frame width={ [ 'full' ]}>
                            <Checkbox
                                isChecked={ Boolean(userInfo.sharedData) }
                                onChange={ e => setUserInfo({ ...userInfo, sharedData: e.target.checked }) }
                                label="I authorize Stadion to share any information I provide to them with my plan’s financial advisor. Shared information will include personal and confidential information."
                            />
                        </Frame>
                    </Stack>
                </Stack>
                <Grid templateColumns={ [ '1fr', 'auto auto' ] } justifyContent="start" rowGap="4" columnGap="4" paddingTop="5xl">
                    <Link href={ wizardRoutes.profile } variant="secondaryButton">
                        Back
                    </Link>
                    <Button type="submit" isLoading={ updateUserResponse.isLoading }>
                        Continue
                    </Button>
                </Grid>
            </form>
        </Frame>
    )    
}

export default FinancialInformation