import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useRef } from 'react'

let baseUrl = '';
if(window.location.origin.match(/155\.stadionmm\.com/))
   baseUrl = 'https://api.155.stadionmm.com/'    // test stage
else if(window.location.origin.match(/122\.stadionmm\.com/))
   baseUrl = 'https://api.122.stadionmm.com/'    // QC stage
else if(window.location.origin.match(/156\.stadionmm\.com/))
   baseUrl = 'https://api.156.stadionmm.com/'    // inc stage
else if(window.location.origin.match(/localhost/)) 
   baseUrl = 'https://ym2pfmavl7.execute-api.us-east-1.amazonaws.com/dev/'   // dev stage
else   
   baseUrl = 'https://api.stadionstoryline.com'  // Production stage


export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        mode: 'cors',
        prepareHeaders: (headers, api) => {
            const state = api.getState()
            headers.set('x-auth', state.token.sessionToken) 
        }
    }),
    endpoints: builder => ({
        login: builder.mutation({
            query: body => ({
                url: '/loginUser',
                method: 'POST',
                body
            }),
            //invalidatesTags: [ 'User-Info', 'Dashboard-Info', 'Results-Info' ]
        }),
        getUserInfo: builder.query({
            query: () => ({
                url: '/userInfo'
            }),
            providesTags: [ 'User-Info' ]
        }),
        getRkInfo: builder.mutation({
            query: body => ({
                url: '/rkInfo',
                method: 'POST',
                body
            }),
        }),
        getDashboard: builder.mutation({
            query: body => ({
                url: '/getDashboard',
                method: 'POST',
                body
            }),
            providesTags: [ 'Dashboard-Info' ],
        }),     
        getWebsiteModels: builder.mutation({
            query: body => ({
                url: '/getWebsiteModels',
                method: 'POST',
                body
            }),
        }),                
        getIncomeDist: builder.mutation({
            query: body => ({
                url: '/getIncomeDist',
                method: 'POST',
                body
            }),
        }),
        getAumBalance: builder.query({
            query: () => ({
                url: '/getAumBalance'
            }),
        }),
        buildIncomePdf: builder.mutation({
            query: body => ({
                url: '/buildIncomePdf',
                method: 'POST',
                body
            }),
        }),
        updateUser: builder.mutation({
            query: body => ({
                url: '/UpdateUser',
                method: 'POST',
                body
            }),
            //invalidatesTags: [ 'User-Info', 'Dashboard-Info' ]
        }),
        enrollUser: builder.mutation({
            query: body => ({
                url: '/EnrollUser',
                method: 'POST',
                body
            }),
            //invalidatesTags: [ 'User-Info' ]
        }),
        forgotUsername: builder.mutation({
            query: body => ({
                url: '/ForgotUsername', 
                method: 'POST',
                body
            })
        }),
        fillOutQuestionnaire: builder.mutation({
            query: body => ({
                url: '/GetResults',
                method: 'POST',
                body
            }),
            providesTags: [ 'Results-Info' ],
            //invalidatesTags: [ 'User-Info' ]
        }),
        implementUser: builder.mutation({
            query: () => ({
                url: '/ImplementUser',
                method: 'POST',
                body: { implemented: 1 }
            }),
            //invalidatesTags: [ 'User-Info' ]
        }),
        forgotPassword: builder.mutation({
            query: body => ({
                url: '/ResetPw',
                method: 'POST',
                body
            }),
        }),
        updatePassword: builder.mutation({
            query: body => ({
                url: '/UpdatePw',
                method: 'POST',
                body
            }),
        }),
        sendMessage: builder.mutation({
            query: body => ({
                url: '/ContactUs',
                method: 'POST',
                body
            })
        }),
        logout: builder.mutation({
            query: (body = {}) => ({
                url: '/logout',
                method: 'POST',
                body
            })
        }),
        refreshSession: builder.mutation({
            query: (body = {}) => ({
                url: '/keepSessionAlive',
                method: 'POST',
                body
            })
        })
    })
})

export const {
    useLazyGetUserInfoQuery,
    useLoginMutation,
    useGetUserInfoQuery,
    useGetRkInfoMutation,
    useGetDashboardMutation,
    useGetWebsiteModelsMutation,
    useGetAumBalanceQuery,
    useGetIncomeDistMutation,
    useBuildIncomePdfMutation,
    useUpdateUserMutation,
    useEnrollUserMutation,
    useFillOutQuestionnaireMutation,
    useForgotPasswordMutation,
    useForgotUsernameMutation,
    useImplementUserMutation,
    useUpdatePasswordMutation,
    useSendMessageMutation,
    useLogoutMutation,
    useRefreshSessionMutation
} = api