import { Box, Grid } from '@chakra-ui/layout'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel,Adjacent, Frame, NumberInput, Stack, Text, useIsMobileViewport } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { setDisplayFlex } from '../../store/store'
import { ModalAssetClass } from '../../utils/modals'



function DonutChart({user, allocationDataList, allowAgeChange}) {
    const dispatch = useDispatch()
    const [ focusedAsset, setFocusedAsset ] = useState(null)
    const [ totalPcts, setTotalPcts ] = useState(null);
    const [ age, setAge ] = useState(67);
    const [ chart, setChart ] = useState(null);
    const [ donutData, setDonutData ] = useState(null);
    const [ hasFI, setHasFI ] = useState(false)
    const [ hasEQ, setHasEQ ] = useState(false)
    const [ hasFlex, setHasFlex ] = useState(false)
    const isMobile = useIsMobileViewport();


 
    const afterChartCreated = (chart) => {
        setChart(chart);
    }; 
    
    useEffect(() => {
        dispatch(setDisplayFlex(1))
        
        return () => {
            dispatch(setDisplayFlex(0))
        }
    }, [])
    
    useEffect(() => {
        if(hasFlex) dispatch(setDisplayFlex(2))
    }, [hasFlex])
        
    useEffect(() => {
        setAge(user?.pqdiaReq?.age);   
    }, [user?.pqdiaReq?.age]);

    useEffect(() => {
        buildDonutChartData (age, allocationDataList, setDonutData);
        calculateTotalPcts(donutData, setTotalPcts);
        setHasFI(donutData?.allocationData?.find((el) => el.assetType.includes("Fixed Income")))
        setHasEQ(donutData?.allocationData?.find((el) => el.assetType.includes("Equity")))
        setHasFlex(donutData?.allocationData?.find((el) => el.assetType.includes("Flex")))
    }, [age, allocationDataList, donutData]);
     
  
    // When hovering over the legend, find the point being hovered over and set it's state to 'hover' on the donut
    useEffect(() => {
        if (focusedAsset !== null) setTotalPcts(null);
        else {
           setFocusedAsset(null);
           calculateTotalPcts(donutData, setTotalPcts);
        }
        // Find which point is currently being focused
        if (chart != null) {
           let currPt = 0;
           chart?.series?.forEach(function (s) {
              //explicit opacity
              // s.opacity = 0.2;
              s.setState("inactive", true);
              let i = 0;
              s.points.map((point) => {
                 if (
                    point.name === focusedAsset?.name &&
                    point.y === focusedAsset?.y
                 )
                    currPt = i;
                 i++;
              });
           });
           chart.series[0].points[currPt].setState("hover");
        }
     }, [focusedAsset]);
     

    // Determine how many columns for legend
    // let numLegendCols = 0;
    // const hasFI = donutData?.allocationData?.find((el) => el.assetType.includes("Fixed Income"));
    // if (hasFI) numLegendCols++;
    // const hasEQ = donutData?.allocationData?.find((el) => el.assetType.includes("Equity"));
    // if (hasEQ) numLegendCols++;
    // const hasFlex = donutData?.allocationData?.find((el) => el.assetType.includes("Flex"));
    // if (hasFlex) {
    //     numLegendCols++;
    //     dispatch(setDisplayFlex(2));
    // }
    let numLegendCols = 0
    if (hasFI) numLegendCols++
    if (hasEQ) numLegendCols++
    if (hasFlex) numLegendCols++
    if(isMobile) numLegendCols = 1
    // let numLegendCols = 1
    const legendCols = "repeat(" + numLegendCols + ", 1fr)"
    
    
    // Set options for the donut chart
    const options = getOptions(donutData, age, setFocusedAsset, setTotalPcts)    

    // Rows for allocation fund table. For Flex, use citHoldings, for no-Flex use holdings
    let renderedRows = '';
    if(hasFlex) {
        renderedRows = donutData?.citHoldings?.map((rowData) =>
        {
            return (
                // <tr key={rowData.key} onMouseEnter={() => setFocusedAsset({name: rowData.fundName, y: rowData.fundPct})} onMouseLeave={() => setFocusedAsset(null)}>
                <tr key={rowData.key}>
                    <td>
                        <Adjacent alignY="center" space="sm">
                            <LegendIcon backgroundColor={rowData.color} />
                            <Text level="xs">{rowData.assetType}</Text>
                        </Adjacent>
                    </td>
                    <td>
                        <Text level="xs">{rowData.fundName}</Text>
                    </td>
                    <td>
                        <Text level="xs">{rowData.fundPct}%</Text>
                    </td>
                </tr>
            );
        });
    }
    else {
        renderedRows = donutData?.holdings?.map((rowData) =>
        {
            return (
                // <tr key={rowData.key} onMouseEnter={() => setFocusedAsset({name: rowData.assetClass, y: rowData.fundPct})} onMouseLeave={() => setFocusedAsset(null)}>
                <tr key={rowData.key}>
                    <td>
                        <Adjacent alignY="center" space="xs">
                            <LegendIcon backgroundColor={rowData.color} />
                            <Text level="xs">{rowData.assetClass}</Text>
                        </Adjacent>
                    </td>
                    <td>
                        <Text level="xs">{rowData.fundName}</Text>
                    </td>
                    <td>
                        <Text level="xs">{rowData.fundPct}%</Text>
                    </td>
                </tr>
            );
        });
    }
    
    
    return (
        <Stack space="md">
            <Text level="lg" variant="primary" as="b">
                Your retirement portfolio
            </Text>
            <ModalAssetClass hasFlex={hasFlex} />
            <Text as="p" level="xs">
                This chart shows the different asset classes your account will be invested in. Your portfolio will be adjusted over time as you get closer to retirement. To see the allocation for each asset class, move your mouse over each section in the graph.
            </Text>

            <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} columnGap="4" rowGap="4">
                <Box>
                    <Frame palette="baseOne" alignY="center" alignX="center">
                        {donutData?.allocationData ?
                            (<HighchartsReact
                                highcharts={Highcharts}
                                callback={afterChartCreated}
                                options={options}
                            />) : (
                                <Text as="p">
                                    There is no data to display in the chart.
                                </Text>)
                        }
                        {
                            focusedAsset &&
                            <div id="donutCenter" style={{ position: 'absolute', textAlign: 'center', width: '150px' }}>
                                <Text as="p" level="3xl">
                                    {focusedAsset.y}%
                                </Text>
                                <Text as="p" level="sm">
                                    {focusedAsset.name}
                                </Text>
                            </div>
                        }
                        {totalPcts && (
                            <div
                                id="donutCenter"
                                style={{
                                    position: "absolute",
                                    textAlign: "center",
                                }}
                            >
                                {hasEQ && (
                                    <div>
                                        <Text as="p" level="lg">
                                            {
                                                totalPcts?.filter(
                                                    (opt) => opt.key === "eq"
                                                )[0].value
                                            }
                                            %
                                        </Text>
                                        <Text as="p" level="sm">
                                            Equity
                                        </Text>
                                    </div>
                                )}
                                {hasFI && (
                                    <div>
                                        <Text as="p" level="lg">
                                            {
                                                totalPcts?.filter(
                                                    (opt) => opt.key === "fi"
                                                )[0].value
                                            }
                                            %
                                        </Text>
                                        <Text as="p" level="sm">
                                            Fixed Income
                                        </Text>
                                    </div>
                                )}
                                {hasFlex && (
                                    <div>
                                        <Text as="p" level="lg">
                                            {
                                                totalPcts?.filter(
                                                    (opt) => opt.key === "flex"
                                                )[0].value
                                            }
                                            %
                                        </Text>
                                        <Text as="p" level="sm">
                                            Flex
                                        </Text>
                                    </div>
                                )}
                            </div>
                        )}
                    </Frame>
                </Box>
                <Box>
                    {allowAgeChange && (
                        <Stack>
                            <Box>
                                <NumberInput
                                    type="number"
                                    label="To see how your allocations may change over time, adjust the age (years)"
                                    name="age"
                                    id="age"
                                    value={age}
                                    isRequired={true}
                                    onChange={setAge}
                                    min={user.pqdiaReq.age}
                                    max={95}
                                    step={1}
                                />
                            </Box>
                        </Stack>
                    )}

                    <Stack space="md">
                        <Box mt={5}>
                            <Grid templateColumns={legendCols} gap={1}>
                                {hasEQ && (
                                    <Stack space="2xs">
                                        <Text as="b" level="sm">
                                            Equity
                                        </Text>
                                        <Stack>
                                            {(donutData?.allocationData || [])
                                                .filter(
                                                    (e) =>
                                                        e.assetType === "Equity"
                                                )
                                                .map((entry) =>
                                                    LegendEntry(
                                                        entry,
                                                        setFocusedAsset,
                                                        chart
                                                    )
                                                )}
                                        </Stack>
                                    </Stack>
                                )}
                                {hasFI && (
                                    <Stack space="2xs">
                                        <Text as="b" level="sm">
                                            Fixed income
                                        </Text>
                                        <Stack>
                                            {(donutData?.allocationData || [])
                                                .filter(
                                                    (e) =>
                                                        e.assetType ===
                                                        "Fixed Income"
                                                )
                                                .map((entry) =>
                                                    LegendEntry(
                                                        entry,
                                                        setFocusedAsset,
                                                        chart
                                                    )
                                                )}
                                        </Stack>
                                    </Stack>
                                )}
                                {hasFlex && (
                                    <Stack space="2xs">
                                        <Text as="b" level="sm">
                                            Flex
                                        </Text>
                                        <Stack>
                                            {(donutData?.allocationData || [])
                                                .filter(
                                                    (e) => e.assetType === "Flex"
                                                )
                                                .map((entry) =>
                                                    LegendEntry(
                                                        entry,
                                                        setFocusedAsset,
                                                        chart
                                                    )
                                                )}
                                        </Stack>
                                    </Stack>
                                )}
                            </Grid>
                        </Box>
                    </Stack>
                </Box>
            </Grid>
            <Grid templateColumns={["1fr"]} columnGap="4" rowGap="4">
                <Box>
                    <Accordion allowToggle={true}>
                        <AccordionItem>
                            <AccordionButton>
                                Allocation breakdown
                            </AccordionButton>
                            <AccordionPanel px="0" py="2">
                                <Stack space="md">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                    {hasFlex ? (<Text as="b" level="xs">Asset type</Text>) : (<Text as="b" level="xs">Asset class</Text>)}
                                                </td>
                                                <td>
                                                    {hasFlex ? (<Text as="b" level="xs">CIT name</Text>) : (<Text as="b" level="xs">Fund name</Text>)}
                                                </td>
                                                <td>
                                                    <Text as="b" level="sm">Allocation</Text>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>{renderedRows}</tbody>
                                    </table>
                                </Stack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
            </Grid>
        </Stack>
    )
}

function LegendIcon({ backgroundColor }) {
    return (
        <div style={ { backgroundColor, width: 15, height: 15, borderRadius: 3 } }>
        </div>
    )
}

function LegendEntry(entry, setFocusedItem, chart) {
    return (
       <span
          style={{ cursor: "default" }}
          onMouseOver={() => {
             setFocusedItem({ name: entry.assetClass, y: entry.assetPct });
          }}
          onMouseOut={() => setFocusedItem(null)}
          key={entry.assetClass}
       >
          <Adjacent alignY="center" space="xs" key={entry.assetClass}>
             <LegendIcon backgroundColor={entry.color} />
             {<Text level="xs">{entry.assetClass}</Text>}
          </Adjacent>
       </span>
    );
 }
 
 function buildDonutChartData (ageIn, allocationDataList, setDonutData) {
    //console.log("In buildDonutChartData");
    //console.log(ageIn);
    let ddata = allocationDataList?.find(ddata => (ddata.age === parseInt(ageIn)));
    setDonutData(ddata);
    //console.log(ddata, allocationDataList);
 } 
 
 function calculateTotalPcts(donutData, setTotalPcts) {
    let sumFI = 0;
    let sumEQ = 0;
    let sumFlex = 0;
    donutData?.totalPercent?.forEach((el, index, array) => {
       if(el.assetType === "Equity") sumEQ = el.assetPct;
       if(el.assetType === "Fixed Income") sumFI = el.assetPct;
       if(el.assetType === "Flex") sumFlex = el.assetPct;
    });
    let t = [];
    t.push({ key: "fi", value: sumFI });
    t.push({ key: "eq", value: sumEQ });
    t.push({ key: "flex", value: sumFlex });

    setTotalPcts(t);
};
 

function getOptions(donutData, age, setFocusedAsset, setTotalPcts) {
    return {
        chart: {
            width: 300,
            backgroundColor: 'transparent'
        },
        title: {
            text: null
        },
        tooltip: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 2,
                borderRadius: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '80%',
                point: {
                    events: {
                        mouseOver: function (e) {
                            setTotalPcts(null);
                            setFocusedAsset({
                               ...e.target.options,
                            });
                         },
                         mouseOut: function () {
                            setFocusedAsset(null);
                         },                                                 
                    }
                },
                label: { enabled: false }
            }
        },
        series: [
            {
                name: 'Asset',
                type: 'pie',
                dataLabels: {
                    enabled: false,
                    borderRadius: 0
                },
                data: donutData?.allocationData?.map(series => ({
                    name: series.assetClass, y: series.assetPct, color: series.color, assetType: series.assetType
                }))
            }
        ],
        yAxis: {
            title: {
                text: null
            }
        },
        xAxis: { visible: false },
        credits: { enabled: false }
    }
}

export default DonutChart;
