import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
   Frame,
   Text,
   useIsMobileViewport,
} from "@stadion/bright";
import { Box, Grid, GridItem } from "@chakra-ui/layout";

function StackedBarChartIncome({
   barChartData,
   include401k,
   includeSocSec,
   socSecStartAge,
   monthlySocSecOverride,
   pensionStartAge,
   includePension,
   monthlyPension,
   narrowView,
   heightOverride,
   bgOverride
})
{
   const isMobile = useIsMobileViewport() || narrowView;

   let incomeData = [];
   let socsecData = [];
   let pensionData = [];
   let categories = [];
   if(includePension)
   {
      // Check for pension start age less than 401k,socsec add those to pension array
      let graphStartAge = barChartData?.reduce((min, p) => p.age < min.age ? p : min, barChartData[0])
      if (pensionStartAge < graphStartAge?.age)
      {
         for (let age = pensionStartAge; age < graphStartAge?.age; age++)
         {
            pensionData.push(monthlyPension)
            incomeData.push(0)
            socsecData.push(0)
            categories.push(parseInt(age))
         }
      }
   }
   if (barChartData)
   {
      barChartData?.forEach((el) =>
      {
         if (include401k) incomeData.push(el.income);
         else incomeData.push(0);
         if ((includeSocSec === true) &&
            ((socSecStartAge === undefined) || (socSecStartAge !== undefined && socSecStartAge <= el.age)))
         {
            socsecData.push(el.socsec);
         }
         else socsecData.push(0);
         if ((includePension === true) &&
            ((pensionStartAge === undefined) || (pensionStartAge !== undefined && pensionStartAge <= el.age)))
         {
            pensionData.push(monthlyPension);
         }
         else pensionData.push(0);
         categories.push(parseInt(el.age));
      });
   }

   //const total401k = incomeData?.reduce((acc, o) => acc + parseInt(o), 0);
   const totalSocsec = socsecData?.reduce((acc, o) => acc + parseInt(o), 0);
   const totalPension = pensionData?.reduce((acc, o) => acc + parseInt(o), 0);

   const options = getOptions(
      incomeData,
      socsecData,
      pensionData,
      categories,
      isMobile,
      heightOverride,
      bgOverride
   );
 
   let bgBase = 'baseTwo';  // white
   if(bgOverride !== undefined) bgBase = 'baseOne';   // light gray
   return (
      <div>
         <Frame>
            <div style={{ position: "relative", width: "100%" }}>
               {barChartData !== undefined && barChartData.length > 0 ? (
                  <HighchartsReact highcharts={Highcharts} options={options} />
               ) : (
                  <Box m={4}>
                     <Text as="p" level="xs">
                        There is no income data to display in the chart.
                     </Text>
                  </Box>
               )}
            </div>
         </Frame>
         {barChartData ? (
            <Frame alignX="center" alignY="center" palette={bgBase}>
               <Grid
                  templateColumns={["auto", "1fr"]}
                  columnGap="2"
                  rowGap="1"
                  paddingRight={1}
               >
                  <Frame palette={bgBase}>
                     <Box>
                        <div
                           style={{
                              backgroundColor: "#00819D",
                              width: 10,
                              height: 10,
                              marginRight: 6,
                              borderRadius: 2,
                              display: "inline-block",
                           }}
                        ></div>
                        <Text level="2xs">{(totalSocsec > 0) ? "Est 401(k)" : "Estimated 401(k) monthly income"}</Text>
                     </Box>

                     {totalSocsec > 0 ? (
                        <Box>
                           <div
                              style={{
                                 backgroundColor: "#ff9c36",
                                 width: 10,
                                 height: 10,
                                 marginRight: 6,
                                 marginLeft: 15,
                                 borderRadius: 2,
                                 display: "inline-block",
                              }}
                           ></div>
                           <Text level="2xs">Social Security</Text>
                        </Box>
                     ) : (
                        ""
                     )}

                     {totalPension > 0 ? (
                        <Box>
                           <div
                              style={{
                                 backgroundColor: "#003357",
                                 width: 10,
                                 height: 10,
                                 marginRight: 6,
                                 marginLeft: 15,
                                 borderRadius: 2,
                                 display: "inline-block",
                              }}
                           ></div>
                           <Text level="2xs"> Pension</Text>
                        </Box>
                     ) : (
                        ""
                     )}
                  </Frame>
               </Grid>
            </Frame>
         ) : (
            ""
         )}
      </div>
   );
}

function getOptions(incomeData, socsecData, pensionData, categories, isMobile, heightOverride, bgOverride) {
   return {
      chart: {
         type: "column",
         height: heightOverride,
         backgroundColor: bgOverride !== undefined ? bgOverride : 'transparent'
      },
      credits: {
         enabled: false,
      },
      accessibility: {
         enabled: false,
      },
      title: {
         text: "",
      },
      tooltip: {
         backgroundColor: "#003357",
         style: {
            color: "#ffffff",
            fontFamily:
               'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
         },
         useHTML: true,
         formatter: function () {
            return `<div style="font-family: lato; display: grid; grid-template-columns: auto; grid-gap: 5px">
                        <div style="display: grid; grid-template-columns: 75px 75px">
                            <div>Account</div>
                            <div style="justify-self: end">${
                               this.series.name
                            }</div>
                        </div>
                        <div style="background-color: rgba(255, 255, 255, 0.15); height: 2px">
                        </div>
                        <div style="display: grid; grid-template-columns: 75px 75px">
                           <div>Age</div>
                           <div style="justify-self: end">${this.x}</div>
                       </div>
                       <div style="background-color: rgba(255, 255, 255, 0.15); height: 2px">
                       </div>
                          <div style="display: grid; grid-template-columns: 75px 75px">
                             <div>Amount</div>
                             <div style="justify-self: end">$${this.y.toLocaleString(
                                "en",
                                {
                                   minimumFractionDigits: 0,
                                   maximumFractionDigits: 0,
                                }
                             )}</div>
                         </div>
                    </div>
                    `;
         },
      },
      xAxis: {
         categories: categories,
         tickInterval: isMobile ? 4 : 2,
         labels: {
            style: {
               fontSize: isMobile ? '9px' : '12px',
               fontFamily:
                  'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            },
         },
      },
      yAxis: {
         opposite: true,
         labels: {
            style: {
               fontSize: isMobile ? '9px' : '12px',
               fontFamily:
                  'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            },
            formatter: function () {
               return `$${this.value.toLocaleString()}`;
            },
         },
         title: {
            text: null,
         },
         tickPixelInterval: 100,
      },
      legend: {
         enabled: false,
      },
      plotOptions: {
         column: {
            stacking: "normal",
            dataLabels: {
               enabled: false,
            },
         },
         series: {
            pointWidth: isMobile ? 8 : 20,
            marker: {
               enabled: false,
            },
         },
      },
      series: [
         {
            name: "Pension",
            data: pensionData,
            color: "#003357",
         },
         {
            name: "Social Security",
            data: socsecData,
            color: "#ff9c36",
         },
         {
            name: "401(k)",
            data: incomeData,
            color: "#00819d",
         },
      ],
   };
}

export default StackedBarChartIncome;
