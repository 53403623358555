import { Alert, Button, Frame, Link, PhoneInputSimple, Radio, RadioGroup, Stack, Text, TextInput, useIsMobileViewport } from '@stadion/bright'
import React, { useState, useEffect, useRef } from 'react'
import { useUpdateUserMutation } from '../../../store/api'
import { errorTemplates, getErrors, getFormFields } from '../../../utils/forms'
import { Navigate } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { participantRoutes, wizardRoutes } from '../../../routes'
import { Grid } from '@chakra-ui/layout'
import moment from 'moment/moment'
import useUser from '../../../store/useUser'

function Profile() {
    const navigate = useNavigate()
    const [ errors, setErrors ] = useState({})
    const [ userInfo, setUserInfo ] = useState({})
    const [ phoneInput, setPhoneInput ] = useState(null)
    const [ maritalStatusInput, setMaritalStatusInput ] = useState(null)
    const [ updateUser, updateUserResponse ] = useUpdateUserMutation()
    const isMobile = useIsMobileViewport()
    
    const firstNameInput = useRef()
    const lastNameInput = useRef()
    const emailInput = useRef()
    const dobInput = useRef()
    
    const user = useUser()


    useEffect(() => { 
        if (user) {
            let dob = ""
            if(user?.pqdiaReq?.dob) dob=moment(user?.pqdiaReq?.dob, "YYYY-MM-DD").format('YYYY-MM-DD')
            setUserInfo({
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                phone: user?.phone,
                // dob: (user?.pqdiaReq?.dob || user?.pqdiaReq?.dob === 0) ?  new Date(user?.pqdiaReq?.dob).toISOString().split('T')[0] : 0,
                dob: dob,
                maritalStatus: user?.pqdiaReq?.maritalStatus
            })
        }
    }, [ user ])

    useEffect(() => {
        const phoneInputElement = document.querySelector('[name=phone]')
        const maritalStatusInputElement = document.querySelector('[name=maritalStatus]')

        phoneInputElement.setAttribute('pattern', `^\\(\\d{3}\\) \\d{3}-\\d{4}$`)

        setPhoneInput({ current: phoneInputElement })
        setMaritalStatusInput({ current: maritalStatusInputElement })
    }, [])

    const handleInput = name => e => setUserInfo({
        ...userInfo,
        [name]: e.target.value
    })
    
    const handleBackClick = () =>
    {
        navigate(-1)
    }
    const handleSubmit = e => {
        e.preventDefault()

        const inputs = [
            firstNameInput,
            lastNameInput,
            emailInput,
            phoneInput,
            dobInput,
            maritalStatusInput
        ]

        const minDob = moment().subtract(99, 'years')
        const maxDob = moment().subtract(16, 'years')

        const participantAge = moment(userInfo.dob)

        let errors = {}

        for (let input of inputs) {
            errors = { ...errors, [input.current.name]: getErrors(input.current, errorTemplates[input.current.name]) }
        }

        if (userInfo.dob && !(participantAge.isAfter(minDob) && participantAge.isBefore(maxDob)))
            errors = {
                ...errors,
                dob: errors.dob ?
                    [ ...errors.dob, 'Participant must be between the age of 16 and 99' ] :
                    [ 'Participant must be between the age of 16 and 99' ]
            }

        setErrors(errors)

        if (!Object
            .values(errors)
            .filter(err => err)
            .length) {
                updateUser({ profileFields: getFormFields(userInfo, [ 'firstName', 'lastName', 'email', 'phone', 'dob', 'maritalStatus' ]) })
        }
    }

    if (updateUserResponse.data?.status?.rc === 0)
        return <Navigate to={ wizardRoutes.financialInformation } />

    const hasErrors = Object.values(errors).some(Boolean)
    const responseError = updateUserResponse.isSuccess && parseInt(updateUserResponse.data?.status?.rc) !== 0

    return (
            <form noValidate onSubmit={handleSubmit}>
                <Stack space="4xl">
                    {/* util element to keep the width of the stack, inputs only take as much room as needed, this forces consistent sizing when error messages appear */}
                    <div style={{ width: isMobile ? 300 : 380 }}></div>
                    {
                        (hasErrors || responseError || updateUserResponse.isError) &&
                        <Alert
                            variant="negative"
                            heading="Profile update failed"
                            content={
                                updateUserResponse.data?.status?.msg ||
                                updateUserResponse.error?.data?.message ||
                                'Please check the errors below before proceeding'
                            }
                        />
                    }
                    <Frame width={['full', '1/2']}>
                        <TextInput
                            isFullWidth={isMobile}
                            label="First name *"
                            ariaLabel="First name"
                            name="firstName"
                            inputRef={firstNameInput}
                            isRequired
                            pattern="^[^0-9_!¡?÷?¿\/\\+=@#$%\^&*\(\)\{\}\|~<>;:\[\]]{1,}\S(.*\S)?$"
                            min={2}
                            value={userInfo.firstName || ""}
                            onChange={handleInput('firstName')}
                            errors={errors['firstName'] || []}
                        />
                    </Frame>
                    <Frame width={['full', '1/2']}>
                        <TextInput
                            label="Last name *"
                            ariaLabel="Last name"
                            name="lastName"
                            inputRef={lastNameInput}
                            isRequired
                            pattern="^[^0-9_!¡?÷?¿\/\\+=@#$%\^&*\(\)\{\}\|~<>;:\[\]]{1,}\S(.*\S)?$"
                            min={2}
                            value={userInfo.lastName || ""} 
                            onChange={handleInput('lastName')}
                            errors={errors['lastName'] || []}
                        />
                    </Frame>
                    <Frame width={['full', '1/2']}>
                        <TextInput
                            label="Email *"
                            ariaLabel="Email"
                            name="email"
                            inputRef={emailInput}
                            isRequired
                            pattern="^\S+@\S+\.\S+$"
                            value={userInfo.email || ""}
                            onChange={handleInput('email')}
                            errors={errors['email'] || []}
                        />
                    </Frame>
                    <Frame width={['full', '1/2']}>
                        <PhoneInputSimple
                            label="Phone number *"
                            ariaLabel="Phone number"
                            name="phone"
                            isRequired
                            defaultCountry="US"
                            value={userInfo.phone || ""}
                            onChange={handleInput('phone')}
                            errors={errors['phone'] || []}
                        />
                    </Frame>
                    <Frame width={['full', '1/2']}>
                        <TextInput
                            type="date"
                            label="Birthdate *"
                            ariaLabel="Date of birth"
                            name="dob"
                            isRequired
                            inputRef={dobInput}
                            description="<p>mm/dd/yyyy</p>"
                            value={userInfo.dob || ""}
                            onChange={handleInput('dob')}
                            errors={errors['dob'] || []}
                        />
                    </Frame>
                    <Frame width={['full', '1/2']}>
                        <RadioGroup
                            label="Marital status *"
                            name="maritalStatus"
                            isRequired
                            value={userInfo.maritalStatus || ""}
                            onChange={maritalStatus => setUserInfo({ ...userInfo, maritalStatus })}
                            errors={errors['maritalStatus'] || []}
                        >
                            <Radio
                                label="Single"
                                value="Single"
                            />
                            <Radio
                                label="Married"
                                value="Married"
                            />
                        </RadioGroup>
                    </Frame>
                    <Text as="p">
                        * Required field
                    </Text>
                </Stack>
                <Grid templateColumns={['1fr', 'auto auto']} justifyContent="start" rowGap="4" columnGap="4" paddingY="5xl">
                    {/* <Link href={participantRoutes.getStarted} variant="secondaryButton">
                        Back
                    </Link> */}
                    <Button  onClick={handleBackClick} variant="secondaryButton">
                        Back
                    </Button>
                    <Button type="submit" isLoading={updateUserResponse.isLoading}>
                        Continue
                    </Button>
                </Grid>
            </form>
    )    
}

export default Profile