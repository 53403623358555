import { Adjacent, Alert, Block, Button, Frame, Link, Stack, Text, useNavigation } from '@stadion/bright'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import RadioInput from '../../RadioInput'
import { answerQuestion } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router'
import { wizardRoutes } from '../../../routes'

function Questions4() {
    const navigation = useNavigation()
    const questionnaire = useSelector(state => state.questionnaire)
    const dispatch = useDispatch()
    const [ errors, setErrors ] = useState({})
    
    const checkForm = () => {
        if (questionnaire.q7 && questionnaire.q8)
            navigation.redirectTo('/participant/questionnaire/questions-5')
        else
            setErrors({
                q7: questionnaire.q7 ? null : [ 'Rating is required' ],
                q8: questionnaire.q8 ? null : [ 'Rating is required' ]
            })
    }

    if (!questionnaire.q5 && !questionnaire.q6)
        return <Navigate to={ wizardRoutes.questions3 } />

    const hasErrors = Object.values(errors).some(e => e)

    return (
        <Block palette="baseTwo" paddingY="4xl">
            <Frame width={ [ 'full' ] }>
                <Stack space="6xl">
                    { hasErrors && <Alert variant="negative" heading="Questionnaire answers missing" /> }
                    <RadioInput
                        label="7. I am more cautious with investing my money than other people I know."
                        isRequired={ true }
                        name="q7"
                        value={ questionnaire.q7 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q7', answer })) }
                        errors={ errors.q7 || [] }
                    />
                    <RadioInput
                        label="8. I like the idea of insurance - it is OK to pay a little to protect against big losses."
                        isRequired={ true }
                        name="q8"
                        value={ questionnaire.q8 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q8', answer })) }
                        errors={ errors.q8 || [] }
                    />
                    <Adjacent space="md">
                        <Link href="/participant/questionnaire/questions-3" variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={ checkForm }>
                            Next
                        </Button>
                    </Adjacent>
                </Stack>
            </Frame>
        </Block>
    )    
}

export default Questions4