import React, { useState, useEffect } from 'react'
import { Alert, ElevatedCard, PageHeadingBlock, Stack, Block, Button, Text, useNavigation, MainWrapper, PageLoading } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import PageWrapper from '../../PageWrapper'
import { useGetRkInfoMutation } from '../../../store/api'
import useUser from '../../../store/useUser'



function PaymentSetup() {
    const navigation = useNavigation()
    const [ errors, setErrors ] = useState({})
    const user = useUser()
    const [ getRkInfo, rkInfoResponse ] = useGetRkInfoMutation()


    useEffect(() => {
        getRkInfo({
            vendorId: user.vendorId
        })
    }, [ user.vendorId ])
    
    
    useEffect(() => {
        if (rkInfoResponse.data?.status?.rc < 0)
        {
            setErrors({ generic: rkInfoResponse.data?.status?.msg })
        }
    }, [ rkInfoResponse ])
    
    const handleContinueClick = () =>
    {
        navigation.redirectTo('/participant/dashboard')
    }
    
    const handleUrlClick = async () =>
    {
        window.open(rkInfoResponse?.data?.rkURL, "_blank")
    }
    
    
    if (rkInfoResponse.isLoading || rkInfoResponse.isFetching)
    {
        return (
            <MainWrapper palette="baseOne">
                <PageLoading />
            </MainWrapper>
        )
    }
    
    const errorEntries = Object.values(errors).filter(err => err)
        
    return (
        <PageWrapper pageTitle="Payment Setup">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Stack>
                        <Box>
                            <PageHeadingBlock
                                pageHeading="Payment setup"
                                subheading={"You can access the link below to be directed to your Recordkeeper payment portal."}
                                showDivider={true}
                            />
                        </Box>

                        <Box mt={8}>
                            <Button
                                onClick={handleUrlClick}
                                rightIconName="FunctionalExternal"
                                size="lg"
                                variant="primaryLink"
                            >
                                Recordkeeper payment portal
                            </Button>
                        </Box>

                        <Box mt={6}>
                            <Text as="p">
                                Have questions about your Recordkeeper's payment setup?
                            </Text>
                        </Box>

                        <Box mt={6}>
                            <Text level="sm" as="b" variant="primary">
                                Recordkeeper support center:
                            </Text>
                        </Box>
                        <Box mt={4}>
                            <Text level="md" as="b" variant="primary">
                               {rkInfoResponse?.data?.rkPhone}
                            </Text>
                        </Box>

                        <Box mt={8}>
                            <Button onClick={handleContinueClick}>
                                Return to dashboard
                            </Button>
                        </Box>
                    </Stack>
                </Flex>
            </Block>
        </PageWrapper>
    )
}


export default PaymentSetup