import { Box, Flex, Grid } from '@chakra-ui/layout'
import { PageHeadingBlock, Stack, Block, OutlineCard, Text, PageLoading } from '@stadion/bright'
import { ModalDashboardTabOverview1, ModalDashboardTabOverview2, ModalDashboardTabIncome2 } from '../../utils/modals'
import { useSelector } from 'react-redux'
import StackedBarChartIncome from '../charts/StackedBarChartIncome'


function DashboardTabIncome({ dashboardResponse, Notifications })
{
    const planAccepted = useSelector(state => state.incomePlan.planAccepted)
    const include401k = true
    const includeSocsec = false
    const includePension = false

    //const incomeList = dashboardResponse?.data?.incomeDistributionList.filter(el => el.income > 0)
    let incomeList = dashboardResponse?.data?.incomeDistributionList
    if(incomeList !== undefined)  incomeList = incomeList.filter(el => el.income > 0)
    const retirementBalance = dashboardResponse?.data?.incomeAcctValueAtRetire;
    let monthlyIncome = dashboardResponse?.data?.incomeMonthlyAtRetire;
    const retirementAmts = incomeList?.find(cd => (cd.age === parseInt(dashboardResponse?.data?.incomeStartAge))) 
    if(retirementAmts !== undefined) {
        monthlyIncome = retirementAmts.income;
    }

    return (
        <Flex flexGrow={1} flexDir="column">
            <Block>
                <PageHeadingBlock
                    pageHeading={"Income"}
                    showDivider={false}
                />
                
                {(planAccepted === true) ? (
                    <Stack space="lg">
                        
                        <Notifications />

                        <OutlineCard>
                            <Stack space="md">
                                {!(dashboardResponse.isLoading || dashboardResponse.isFetching) ? (
                                    <Box mt={5}>
                                        <Grid
                                            templateColumns={
                                                [
                                                    'auto',
                                                    '1fr 1px 1fr 1px 1fr'
                                                ]
                                            }
                                            columnGap="4"
                                            rowGap="4"
                                        >
                                            <Stack space="sm">
                                                <Box>
                                                    <Text level="sm" as="b" variant="primary">
                                                        Estimated 401(k) balance at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${Math.round(retirementBalance).toLocaleString()}
                                                    </Text>
                                                    <ModalDashboardTabOverview1 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box>
                                                    <Text level="sm" as="b" variant="primary">
                                                        Estimated monthly income at retirement:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        ${Math.round(monthlyIncome).toLocaleString()}
                                                    </Text>
                                                    <ModalDashboardTabOverview2 />
                                                </Box>
                                            </Stack>
                                            <Border />
                                            <Stack space="sm">
                                                <Box
                                                    h="50px"
                                                    sx={{
                                                        "@media screen and (max-width: 600px)": {
                                                            height: 15,
                                                        },
                                                    }}
                                                >
                                                    <Text level="sm" as="b" variant="primary">
                                                        Duration of income:
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text level="xl" as="b" variant="primary">
                                                        {dashboardResponse ? dashboardResponse.data?.incomeStartAge + "-" + dashboardResponse.data?.incomeEndAge : "0"}
                                                    </Text>
                                                    <ModalDashboardTabIncome2 />
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                ) : (
                                    <PageLoading />
                                )
                                }
                                
                                {!(dashboardResponse.isLoading || dashboardResponse.isFetching) ? (
                                    <div>
                                        <Box mt={5}>
                                            <StackedBarChartIncome
                                                barChartData={incomeList}
                                                include401k={include401k}
                                                includeSocSec={includeSocsec}
                                                includePension={includePension}
                                            />
                                        </Box>
                                    </div>
                                ) : (
                                    <div />
                                )
                                }
                            </Stack>
                        </OutlineCard>
                        <Box mt={2}>
                            <Text as="p" level="xs">
                               The timeframe for any disbursement estimates shown may not be available based on your Plan's service arrangement with its recordkeeper. Please confirm check with your Plan's administrator to learn what is available to you.
                            </Text>
                        </Box>
                    </Stack>
                ) : (
                        <div>
                            <Box mt={5}>
                                <Notifications />
                            </Box>
                        </div>
                )}
            </Block>
        </Flex>
    )
}

function Border()
{
    return <div style={{ backgroundColor: '#E0E0E0', minHeight: '1px' }}></div>
}

export default DashboardTabIncome