import { Flex, Grid } from '@chakra-ui/layout'
import { Button, ElevatedCard, PageHeadingBlock, Stack, Block, Frame, MainWrapper, Link, Tabs, Text, OutlineCard, Alert } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { useGetDashboardMutation, useGetAumBalanceQuery, useGetWebsiteModelsMutation } from '../../store/api'
import { Navigate } from 'react-router'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setUpdatePlanNotification, setHideIncomeReminder, setPlanAcceptedNotification, setAllocationDataList, setPtileOverride } from '../../store/store'
import InfoTooltip from '../InfoTooltip'
import PageWrapper from '../PageWrapper'
import { ModalPaymentSchedule } from '../../utils/modals'
import DashboardTabOverview from "./DashboardTabOverview"
import DashboardTab401k from "./DashboardTab401k"
import DashboardTabIncome from './DashboardTabIncome'
import DashboardTabProfile from './DashboardTabProfile'
import useUser from '../../store/useUser'

function Dashboard() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [ aumDataList, setAumDataList ] = useState(null);
    const [ getDashboard, dashboardResponse ] = useGetDashboardMutation()
    const [ getWebsiteModels, websiteModelsResponse ] = useGetWebsiteModelsMutation()
    const hideIncomeReminder = useSelector(state => state.incomeReminder.hideIncomeReminder)
    const planAcceptedNotification = useSelector(state => state.incomePlan.planAcceptedNotification)
    const updatePlanNotification = useSelector(state => state.incomePlan.updatePlanNotification)
    const ptileOverride = useSelector(state => state.incomePlan.ptileOverride)


    const [ errors, setErrors ] = useState({})
    const [tabIndex, setTabIndex] = useState(0);

    const aumBalanceResponse = useGetAumBalanceQuery()

    const user = useUser()
    const implemented = user?.implemented


    useEffect(() => {
        if (!implemented)
        {
            console.log("Not implemented, forwarding to get-started page")
            navigate('/participant/get-started')
        }
     }, [ implemented ])
    
    useEffect(() => {
        const urlFrom = location.state?.from;
        if((urlFrom === '/income/updateAccount')  ||
           (urlFrom === '/income/updatePersonal') ||
           (urlFrom === '/income/updateFinancial')) 
        {
            setTabIndex(3);
            navigate(location.pathname, {});
        }
        if(urlFrom === '/income/confirm') {
            setTabIndex(2);
            navigate(location.pathname, {});
        }
    }, [ location.state?.from ])

    
    useEffect(() => {
        if(implemented) {
            console.log("ptileOverride: ", ptileOverride)
            getDashboard({
                palette: 3,
                sendModelsToS3: true
            })
            getWebsiteModels({
                palette: 3,
                sendModelsToS3: true,
                ptileOverride: ptileOverride
            })
        }
    }, [ptileOverride, implemented])
   
    
    
    useEffect(() => {
        if (dashboardResponse.data?.status?.rc < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: dashboardResponse.data?.status?.msg })
        }
    }, [ dashboardResponse ])
    
        
    useEffect(() => {
        if (websiteModelsResponse.data?.status?.rc < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: websiteModelsResponse.data?.status?.msg })
        }
        else {
            // Get donut data, store in state since used in 'income' branch set of screens in addition to the dashboard tabs
            let aList = websiteModelsResponse.data?.allocationDataList;
            dispatch(setAllocationDataList(aList));
            //setTabIndex(0);
        }
    }, [ websiteModelsResponse ])
    
    
    useEffect(() => {
        if (aumBalanceResponse.data?.status?.rc < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: aumBalanceResponse.data?.status?.msg })
        }
        else {
            let aumList = aumBalanceResponse.data?.aumBalanceList;  
            setAumDataList(aumList);
        }
    }, [ aumBalanceResponse ])
    
    // const implemented = user?.implemented
    // if (!implemented)
    //    return <Navigate to="/participant/get-started" />
        
    const errorEntries = Object.values(errors).filter(err => err)
        
    const handleRemindMeIncomeClick = () =>
    {
        dispatch(setHideIncomeReminder(true));
    }
    const handleRemindMePlanAcceptedClick = () =>
    {
        dispatch(setPlanAcceptedNotification(false));
    }
    const handlePaymentSetupClick = () =>
    {
        dispatch(setPlanAcceptedNotification(false));
        navigate('/payment/setup')
    }
    const handleGetStartedClick = () =>
    {
        dispatch(setPlanAcceptedNotification(false));
        navigate('/income/welcome')
    }
    const handleRemindMeUpdatePlanClick = () =>
    {
        dispatch(setUpdatePlanNotification(false));
    }

    
    // Notifications for overview tab 
    const hasNotifications = (!hideIncomeReminder || planAcceptedNotification) && user?.enableIncome === 1;

    const NotificationsOverview = () =>
    {
        return (
            <div>
                {(hasNotifications) && (
                    <OutlineCard>
                        <Stack space="md">
                            <Text level="lg" variant="primary">Notifications</Text>
                            {!(hideIncomeReminder) && !(planAcceptedNotification) && (
                                <Notification
                                    title="Retirement income calculator"
                                    description="Stadion takes into account your 401(k) balance and how you feel about risk when building your portfolio. To research if there are additional retirement strategies that are in line with your goals, please click here to learn more."
                                    description2="Your account balances, your personal circumstances, investment returns and other material factors will likely change over time. As a result, your outcomes and estimated retirement income can, and likely will, change. As a result, it is important that you review your Dashboard on a regular basis, for example, annually."
                                    buttonText="Get started"
                                    //buttonLink={() => handleTabsChange(2)}
                                    buttonLink={handleGetStartedClick}
                                    handleRemindMeClick={handleRemindMeIncomeClick}
                                />
                            )}
                            {(planAcceptedNotification) && (
                                <Notification
                                    title="Contact your recordkeeper to inquire about possible payments."
                                    //description="As you approach your retirement age, personalizing your payment schedule becomes essential."
                                    // tooltipText=" Learn about setting up a payment schedule"
                                    // tooltip="Learn about setting up a payment schedule"
                                    modalName={<ModalPaymentSchedule/ >}
                                    buttonText="Get started"
                                    buttonLink={handlePaymentSetupClick}
                                    handleRemindMeClick={handleRemindMePlanAcceptedClick}
                                />
                            )}
                        </Stack>
                    </OutlineCard>
                )}
            </div>
        )
    }



    // Notifications for income tab
    const hasNotificationsIncome = (updatePlanNotification) || !(planAcceptedNotification);

    const NotificationsIncome = () =>
    {
        return (
            <div>
                {(hasNotificationsIncome) && (
                    <OutlineCard>
                        <Stack space="md">
                            <Text level="lg" variant="primary">Notifications</Text>
                            {(updatePlanNotification === true) && (
                                <Notification
                                    title="Update your retirement income strategy"
                                    description="As you enter retirement, make sure your plan still works for you now and then take action to put it into motion."
                                    // tooltipText=" Learn about setting up a retirement strategy"
                                    // tooltip="Learn about setting up a retirement strategy"
                                    buttonText="Get started"
                                    buttonLink={() => navigate('/income/IncomePlanSummary')}
                                    handleRemindMeClick={handleRemindMeUpdatePlanClick}
                                />
                            )}
                            {(!(planAcceptedNotification) && !(updatePlanNotification)) && (
                                <Notification
                                    title="Retirement income calculator"
                                    description="Stadion takes into account your 401(k) balance and how you feel about risk when building your portfolio. To research if there are additional retirement strategies that are in line with your goals, please click here to learn more."
                                    buttonText="Get started"
                                    buttonLink={handleGetStartedClick}
                                />
                            )}
                        </Stack>
                    </OutlineCard>
                )}
            </div>
        )
    }
   
    const handleTabsChange = (indexIn) => {
        setTabIndex(indexIn);
     };
    
    function tabOverview() {
        return <DashboardTabOverview dashboardResponse={dashboardResponse} aumBalanceResponse={aumBalanceResponse} lineChartData={aumDataList} Notifications={NotificationsOverview}/>
    }    
    function tab401k() {
        return <DashboardTab401k dashboardResponse={dashboardResponse} websiteModelsResponse={websiteModelsResponse} />
    }
    function tabIncome() {
        return <DashboardTabIncome dashboardResponse={dashboardResponse} Notifications={NotificationsIncome}/>
    }
    function tabProfile() {
        return <DashboardTabProfile />
    }
    
    let navTab = (
        <Tabs
           value={tabIndex}
           onChange={handleTabsChange}
           tabs={[
              {
                 content: () => tabOverview(),
                 title: "Overview",
              },
              {
                 content: () => tab401k(),
                 title: "401(k) Savings",
              },
              {
                content: () => tabProfile(),
                title: "Profile",
             },              
           ]}
        />
     );
     if(user?.enableIncome === 1) {
        navTab = (
            <Tabs
               value={tabIndex}
               onChange={handleTabsChange}
               tabs={[
                  {
                     content: () => tabOverview(),
                     title: "Overview",
                  },
                  {
                     content: () => tab401k(),
                     title: "401(k) Savings",
                  },
                  {
                     content: () => tabIncome(),
                     title: "Income",
                  },
                  {
                    content: () => tabProfile(),
                    title: "Profile",
                 },              
               ]}
            />
         );
     }
     
    return (
        <PageWrapper pageTitle="Dashboard">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="Retirement dashboard"
                        palette='baseThree'
                        subheading={`Welcome, ${user.firstName} ${user.lastName}`}
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="lg">
                                <ElevatedCard>
                                    <Stack space="xl">
                                        {
                                            errorEntries.length > 0 &&
                                            <Alert
                                                variant="negative"
                                                heading="A system error has occurred"
                                                content={
                                                    errors.generic ||
                                                    "Errors occurred during processing"}
                                            />
                                        }
                                        {navTab}
                                    </Stack>
                                </ElevatedCard>

                                <Stack space="md">
                                    <Frame paddingY="xs" palette="baseOne"></Frame>
                                    <Link href="https://cdn.stadionmoney.com/Footer/StoryLine_Interface_Terms.pdf" level="2xs">
                                        <b>StoryLine Terms and Conditions</b>
                                    </Link>
                                    <Stack space="md">
                                        <Text as="p" level="2xs">
                                            Past performance is no guarantee of future results. Investments are subject to risk, and any of Stadion’s investment strategies may lose money. Your account balances, your personal circumstances, investment returns and other material factors will likely change over time. As a result, your outcomes and estimated retirement income can, and likely will, change. As a result, it is important that you review your Dashboard on a regular basis, for example, annually. Allocation percentages shown are the target model for each portfolio. Actual percentages will vary based on market conditions. Stadion reserves the right to modify its current investment strategies based on changing market dynamics or client needs.
                                        </Text>
                                        <Text as="p" level="2xs">
                                            Stadion Money Management, LLC ("Stadion") is a registered investment adviser under the Investment Advisers Act of 1940. Registration does not imply a certain level of skill or training. More information about Stadion, including fees, can be found in Stadion's ADV Part 2, which is available free of charge.
                                        </Text>
                                        <Text as="p" level="2xs">
                                            This report is not intended to predict actual future data, but is only an illustration based on the profile user’s data inputs. The projections are provided solely as information which should be carefully weighed and evaluated by the investor with the assistance of the investor’s personal financial adviser. No guarantee of future performance is implied or intended, and no specific investments or financial products are represented in the reports. Portfolio characteristics, client restrictions, market conditions, or other factors could impact estimated accumulation. Although this report should prove helpful in visualizing the possibilities for your financial future, you should keep in mind that your actual results will undoubtedly differ from those shown. These projections are for illustrative purposes only and do not constitute investment advice.
                                        </Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )       
}

function Notification  ({ title, description, description2, tooltipText, tooltip, buttonText, buttonLink, handleRemindMeClick, modalName }) 
{
    const hasTooltip = (tooltip !== undefined)  || (tooltipText !== undefined)
    const hasModal = (modalName !== undefined) || (modalName !== undefined)
    const hasRemindme = handleRemindMeClick !== undefined;
    return (
        <Grid templateColumns={['1fr', '1fr auto']} columnGap="2" rowGap="2" alignItems={['center']}>
            <Stack space="sm">
                <Text as="b">
                    {title}
                </Text>
                <Text level="xs" variant="tertiary">
                    {description}
                </Text>
                <Text level="xs" variant="tertiary">
                    {description2}
                </Text>
                {hasTooltip && (
                    <Text as="p" level="xs" variant="accentPrimary">
                        <InfoTooltip label={tooltip} variant="FunctionalQuestion" textIn={tooltipText} />
                    </Text>
                )}
                {hasModal && (
                    modalName
                )}
            </Stack>
            <Stack space="sm">
                <Button onClick={buttonLink} variant='secondaryButton' size="sm">{buttonText}</Button>
                {hasRemindme && (<Link level="xs" onClick={handleRemindMeClick}>Remind me later</Link>)}
            </Stack>
        </Grid>
    )
}

export default Dashboard