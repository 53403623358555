import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Box, SimpleGrid } from '@chakra-ui/layout'
import { Frame, Text, useIsMobileViewport } from '@stadion/bright'

function ColumnChartIncome({calcData, contributionRate, retirementAge, currentAge}) {
    const isMobile = useIsMobileViewport()
    const options = getOptions(calcData, isMobile, retirementAge, currentAge)
    
    return (
        <>
            <Frame>
                <div style={{ position: 'relative', width: '100%' }}>
                    {calcData ?
                        (<HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />) : (
                            <Text as="p">
                                There is no data to display in the chart.
                            </Text>)
                    }
                </div>
            </Frame>

            {calcData ? (
                <SimpleGrid
                    minChildWidth="250px"
                    maxW="xl"
                    alignItems="center"
                    justifyContent="center"
                    margin="auto"
                >
                    <Box>
                        <div
                            style={{
                                backgroundColor: "#6db33f",
                                width: 10,
                                height: 10,
                                marginRight: 6,
                                borderRadius: 2,
                                display: "inline-block",
                            }}
                        ></div>
                        <Text level="2xs">Estimated 401(k) balance before retirement</Text>
                    </Box>
                    <Box>
                        <div
                            style={{
                                backgroundColor: "#00819D",
                                width: 10,
                                height: 10,
                                marginRight: 6,
                                borderRadius: 2,
                                display: "inline-block",
                            }}
                        ></div>
                        <Text level="2xs">Estimated 401(k) balance during retirement</Text>                
                    </Box>                    
                </SimpleGrid>
            ) : ""
            }
        </>    
    )
}

function getOptions(calcData, isMobile, retirementAge, currentAge) {
    let dataReduced = calcData?.chart.reduce((data, point, index, points) => {
        // calcData.chart is array of [x, y] (where x is age, y is dollar amount)
        if (index % 2)
           return data
        
        // Display only green (before retirement) if 10 years or more from retirement, otherwise display all retirement points
        const age = points[index]
        const value = points[index + 1]
        if(((retirementAge - currentAge) > 10) &&
           (age > retirementAge)) 
        {
            return data
        }
        return [...data, { x: age, y: value, color: age < retirementAge ? '#6db33f' : "#00819D" }]
    }, []);
    
    return {
        chart: {
            type: 'column',
        },
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },        
        title: {
            text: ''
        },
        tooltip: {
            backgroundColor: '#003357',
            style: {
                color: '#ffffff',
                fontFamily: 'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
            },
            useHTML: true,
            formatter: function() {
                return (
                    `<div style="font-family: lato; display: grid; grid-template-columns: auto; grid-gap: 5px">
                        <div style="display: grid; grid-template-columns: 65px 65px">
                            <div>Age</div>
                            <div style="justify-self: end">${this.x}</div>
                        </div>
                        <div style="background-color: rgba(255, 255, 255, 0.15); height: 2px">
                        </div>
                        <div style="display: grid; grid-template-columns: 65px 65px">
                            <div>Total</div>
                            <div style="justify-self: end">$${this.y.toLocaleString()}</div>
                        </div>
                    </div>
                    `
                )
            }
        },
        xAxis: {
            // categories: calcData?.chart.filter((value, index) => !index % 2),
            categories: dataReduced?.filter((value, index) => !index % 2),
            //categories: dataReduced.map(series => series.x),
            tickInterval: isMobile ? 4 : 2,
            labels: {
                style: {
                    fontSize: isMobile ? '9px' : '12px',
                    fontFamily: 'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
                },
            },
            title: {
                text: 'Age',
                align: 'high',
                x: 15,
                y: -15,
                style: {
                    fontSize: isMobile ? '9px' : '12px',
                    fontFamily: "lato,Helvetica,Arial,sans-serif",
                 },
            },
        },
        series: [
            {
                data: dataReduced?.map(series => ({
                    x: series.x, y: series.y, color: series.color
                })),
                name: 'Estimated total accumulation',
               }
        ],
        yAxis: {
            opposite: true,
            labels: {
                style: {
                    fontSize: isMobile ? '9px' : '12px',
                    fontFamily: 'lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
                },
                formatter: function() { return `$${this.value.toLocaleString()}` }
            },
            title: {
                text: null
            },
            tickPixelInterval: 100
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointWidth: isMobile ? 8 : 20,
            }
        }
    }
}

export default ColumnChartIncome