import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Alert, ElevatedCard, PageHeadingBlock, Stack, Block, Button, Link, Adjacent, Text, useNavigation } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import PageWrapper from '../../PageWrapper'

function IncomeWelcome()
{
    const navigate = useNavigate()
    const location = useLocation();

    const [errors, setErrors] = useState({})
    
    const editAll = location.state?.editAll;


    const handleGetStartedClick = () =>
    {
        // navigation.redirectTo('/income/step1')
        navigate('/income/step1', {state: { editAll: editAll }})

    }
    const errorEntries = Object.values(errors).filter(err => err)


    return (
        <PageWrapper pageTitle="Income Get Started">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Stack space="lg">
                        <PageHeadingBlock
                            pageHeading={"Let's talk about withdrawing income from your 401(k) account"}
                            showDivider={true}
                        />
                        <Stack space="xl">
                            <Box mt={5}>
                                <Text as="p" variant="primary">
                                   We've used details from your 401(k) account to provide estimates of periodic cash disbursements during retirement.
                                </Text>
                            </Box>
                            <Box>
                                <Text as="p" variant="primary">
                                   The timeframe for any disbursement estimates shown may not be available based on your Plan's service arrangement with its recordkeeper. Please confirm check with your Plan's administrator to learn what is available to you.
                                </Text>
                            </Box>
                            <Box>
                                <Text as="p" variant="primary">
                                    With this planner, you have two options: you can either go with the Stadion's estimated cash disbursement, or you can choose your own amount. After that, you can compare the two plans and decide which one fits you the best. If Stadion determines that, based on its assumptions, your chosen level of retirement income cannot be sustained for your estimated lifetime with a prudent investment strategy, Stadion will let you know and continue to invest you in the style consistent with your current profile and allocations.
                                </Text>
                            </Box>
                            <Box>
                                <Text as="p" variant="primary">
                                    Once you've gone through the steps and are happy with your choice, simply click 'accept changes'. Your monthly income amount will be automatically updated.
                                </Text>
                            </Box>
                            <Box>
                                <Text as="p" variant="primary">
                                    Remember, you're free to make adjustments whenever you'd like.
                                </Text>
                            </Box>
                            <Box mt={8}>
                                <Adjacent space="md">
                                    <Link href="/participant/dashboard" variant="secondaryButton">
                                        Back
                                    </Link>
                                    <Button onClick={handleGetStartedClick}>
                                        Get started
                                    </Button>
                                </Adjacent>
                            </Box>
                        </Stack>
                    </Stack>
                </Flex>
            </Block>
        </PageWrapper>
    )
}


export default IncomeWelcome