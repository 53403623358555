import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    acceptedTerms: false,
    toaResponse: null
  }

const toaSlice = createSlice({
  name: 'ToA',
  initialState,
  reducers: {
    setAcceptTerms(state, action) {
        state.acceptedTerms = action.payload
    },
    setToAResponse(state, action) {
        state.toaResponse = action.payload
    },
    reset: () => initialState
  }
})

export const { setAcceptTerms, setToAResponse, reset } = toaSlice.actions

export default toaSlice