export const login = '/login'
export const signup = '/enroll'
export const updatePassword = '/update-password'
export const forgotPassword = '/forgot-password'
export const forgotUsername = '/forgot-username'

export const participant = '/participant'

export const dashboard = participant + '/dashboard'
export const getStarted = participant + '/get-started'
export const questionnaire = participant + '/questionnaire'

export const wizard = participant + '/questionnaire'

export const profile = wizard + '/profile'
export const financialInformation = questionnaire + '/financial-information'
export const questions1 = questionnaire + '/questions-1'
export const questions2 = questionnaire + '/questions-2'
export const questions3 = questionnaire + '/questions-3'
export const questions4 = questionnaire + '/questions-4'
export const questions5 = questionnaire + '/questions-5'
export const review = questionnaire + '/review'
export const toa = questionnaire + '/toa'

export const contact = '/contact-us'

export const income = '/income'
export const incomeWelcome = income + '/welcome'
export const incomeStep1 = income + '/step1'
export const incomeCustomize1 = income + '/customize1'
export const incomeCustomize2 = income +'/customize2'
export const incomeCustomizeSocsec = income + '/customizeSocsec'
export const incomeCustomizePension = income + '/customizePension'
export const incomePlanSummary = income + '/incomePlanSummary'
export const incomeCustomizeConfirm = income + '/customizeConfirm'
export const incomeReview = income + '/review'
export const incomeAccept = income + '/accept'
export const incomeConfirm = income + '/confirm'
export const incomeComparePlans = income + '/comparePlans'

export const payment = '/payment'
export const paymentSetup = payment + '/setup'

export const participantRoutes = {
    dashboard,
    getStarted,
    questionnaire
}

export const wizardRoutes = {
    profile,
    financialInformation,
    questions1,
    questions2,
    questions3,
    questions4,
    questions5,
    review,
    toa,
}

export default {
    income,
    incomeWelcome,
    incomeStep1,
    incomeCustomize1,
    incomeCustomize2,
    incomeCustomizeSocsec,
    incomeCustomizePension,
    incomeCustomizeConfirm,
    incomePlanSummary,
    incomeReview,
    incomeAccept,
    incomeConfirm,
    incomeComparePlans,
    payment,
    paymentSetup,
    contact,
    login,
    signup,
    updatePassword,
    forgotPassword,
    forgotUsername,
    participant,
    dashboard,
    wizard,
    wizardRoutes
}