import { Box, Grid, Input, VStack, useRadio, useRadioGroup } from '@chakra-ui/react'
import { Adjacent, Stack, Text, useColor, useIsMobileViewport, useShadow } from '@stadion/bright'
import React, { Children, isValidElement, cloneElement } from 'react'
import { FormControl } from './Form-Extended'

const Radio = ({ label, description, ariaControls, isVertical = false, ...useRadioProps }) => {
  const borderColor = useColor('borderPrimary')
  const checkedFillColor = useColor('contentPrimary', 'highlight')
  const checkedBorderColor = useColor('core', 'highlight')
  const boxShadow = useShadow('focus')
  const activeColor = useColor('active', 'highlight')
  const isMobile = useIsMobileViewport()

  const { getInputProps, getCheckboxProps } = useRadio(useRadioProps)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <Input {...input} aria-controls={ariaControls} />

      {
        (isVertical && !isMobile) ?
        (
          <Grid templateColumns={ [ 'auto' ] } rowGap="xs">
            <Stack>
              <Text> {label}</Text>
              {description && (
                <Text variant="tertiary" level="sm">
                  <Box as="span" aria-hidden>
                    {description}
                  </Box>
                </Text>
              )}
            </Stack>
            <Box
              {...checkbox}
              width="20px"
              height="20px"
              border="2px solid"
              borderColor={borderColor}
              borderRadius="50%"
              marginTop="2px"
              _checked={{
                bg: checkedFillColor,
                color: checkedBorderColor,
                border: '5px',
              }}
              _focus={{ color: activeColor, boxShadow }}
            />
          </Grid>
        )
        : (
          <Adjacent space="sm">
            <Box marginStart="6">
              <Stack>
                <Text whiteSpace="normal">{label}</Text>
                {description && (
                  <Text variant="tertiary" level="sm">
                    <Box as="span" aria-hidden>
                      {description}
                    </Box>
                  </Text>
                )}
              </Stack>
            </Box>
            <Box
              {...checkbox}
              width="20px"
              height="20px"
              border="2px solid"
              borderColor={borderColor}
              borderRadius="50%"
              marginTop="2px"
              _checked={{
                bg: checkedFillColor,
                color: checkedBorderColor,
                border: '5px',
              }}
              _focus={{ color: activeColor, boxShadow }}
              marginStart="1"
            />
          </Adjacent>
        )
      }
    </Box>
  )
}

const RadioGroup = ({
  children,
  defaultValue,
  description,
  errors,
  isRequired,
  label,
  onChange,
  value = '',
  name,
  minDescription = 'Does not describe me at all',
  maxDescription = 'Describes me very well'
}) => {
  const { getRadioProps } = useRadioGroup({
    defaultValue,
    value,
    onChange,
    name,
  })

  return (
    <FormControl
      role="radiogroup"
      label={label}
      description={description}
      isRequired={isRequired}
      errors={errors}
      as="fieldset"
    >
      <Grid
        templateColumns={ [ 'auto', '124px 1fr 1fr 1fr 1fr 1fr 124px' ] }
        justifyItems={ [ 'justify', 'center' ] }
        alignItems={ [ 'center' ] }
        rowGap="xl"
      >
        <Text>
          { minDescription }
        </Text>
        <Radio
            label="1"
            value="1"
            isVertical={ true }
            { ...getRadioProps({ value: '1' }) }
        />
        <Radio
            label="2"
            value="2"
            isVertical={ true }
            { ...getRadioProps({ value: '2' }) }
        />
        <Radio
            label="3"
            value="3"
            isVertical={ true }
            { ...getRadioProps({ value: '3' }) }
        />
        <Radio
            label="4"
            value="4"
            isVertical={ true }
            { ...getRadioProps({ value: '4' }) }
        />
        <Radio
            label="5"
            value="5"
            isVertical={ true }
            { ...getRadioProps({ value: '5' }) }
        />
        <Text>
          { maxDescription }
        </Text>
      </Grid>
    </FormControl>
  )
}

export default RadioGroup
