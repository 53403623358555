import { Box, Flex, Grid } from '@chakra-ui/layout'
import { Button, ComplexList, ComplexListItem, ComplexListText, Heading, PageHeadingBlock, Stack, Block } from '@stadion/bright'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import useUser from '../../store/useUser'


function DashboardTabProfile() {
    const navigate = useNavigate()
    const startAge = useSelector(state => state.incomePlan.startAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customSocsecStartAge = useSelector(state => state.incomePlan.customSocsecStartAge)
    const customPensionStartAge = useSelector(state => state.incomePlan.customPensionStartAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customMonthlySocsec = useSelector(state => state.incomePlan.customMonthlySocsec)
    const customMonthlyPension = useSelector(state => state.incomePlan.customMonthlyPension)
    const includeSocsec = useSelector(state => state.incomeAccount.includeSocsec)
    const includePension = useSelector(state => state.incomeAccount.includePension)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)

    const user = useUser()
    
    const handleUpdateProfileClick = () => 
    {
        navigate('/participant/questionnaire/profile');
    }
        
 
    let gender = 'Prefer not to answer'
    if(user?.pqdiaReq?.gender === 1) gender = 'Male'
    else if(user?.pqdiaReq?.gender === 2) gender = 'Female'
    
    // let dob = changeDateFormat(new Date(user?.pqdiaReq?.dob).toISOString().split('T')[0]);
    //let dob = changeDateFormat(new Date(user?.pqdiaReq?.dob));
    let dob = moment(user?.pqdiaReq?.dob, "YYYY-MM-DD").format('MM/DD/YYYY')
    // console.log(dob, user?.pqdiaReq?.dob)

    const incomeStartAge = acceptedPlan > 0 ? customStartAge : startAge;
    const monthlyRetireIncome = acceptedPlan > 0 ? customMonthlyIncome : monthlyIncome;
    
 
    return (
        <Flex flexGrow={1} flexDir="column">
            <Block>
                <Stack space="lg">
                    <PageHeadingBlock
                        pageHeading={"Profile"}
                        palette='baseTwo'
                        showDivider={false}
                    />

                    <Grid templateColumns={"repeat(2, 1fr)"} gap={1}>
                        <Heading as="h3">Account information</Heading>
                        <Box display="flex" justifyContent="end" w="100%">
                            <Button onClick={handleUpdateProfileClick}
                                variant="primaryLink">
                                Update your profile
                            </Button>
                        </Box>
                    </Grid>
                    <ComplexList>
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Full name</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{(<>{user?.firstName} {user?.lastName}</>)}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Email</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.email}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Phone number</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.phone}</ComplexListText>}
                        />
                    </ComplexList>

                    <ComplexList>
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Birthdate</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{dob}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Residing state</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.pqdiaReq?.state}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Gender</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{gender}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Marital status</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.pqdiaReq?.maritalStatus}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Dependents</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.pqdiaReq?.numDependents}</ComplexListText>}
                        />
                    </ComplexList>

                    <ComplexList>
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Annual salary</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${Math.round(user?.pqdiaReq?.salary).toLocaleString()}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Current 401(k) balance</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${Math.round(user?.pqdiaReq?.vendorAum).toLocaleString()}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Contribution rate (%)</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.pqdiaReq?.partDeferralPercentage}%</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Outside assets</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${Math.round(user?.pqdiaReq?.outsideAssets).toLocaleString()}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Personal debt (non-mortgage)</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${Math.round(user?.pqdiaReq?.personalDebt).toLocaleString()}</ComplexListText>}
                        />
                    </ComplexList>


                    <Heading as="h3">401(k) information</Heading>
                    <ComplexList>
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">401(k) monthly income</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${Math.round(monthlyRetireIncome).toLocaleString()}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Retirement age</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{user?.pqdiaReq?.expectedRetirementAge}</ComplexListText>}
                        />
                    </ComplexList>


                    <Heading as="h3">Social Security information</Heading>
                    <ComplexList>
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Social Security monthly income</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${(includeSocsec ===true) ? (<>{Math.round(customMonthlySocsec).toLocaleString()}</>) : 0}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Collection age</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{(includeSocsec === true) ? (<>{customSocsecStartAge}</>) : 0}</ComplexListText>}
                        />
                    </ComplexList>


                    <Heading as="h3">Pension information</Heading>
                    <ComplexList>
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Pension monthly income</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">${(includePension === true) ? (<>{Math.round(customMonthlyPension).toLocaleString()}</>) : 0}</ComplexListText>}
                        />
                        <ComplexListItem
                            firstElement={<ComplexListText as="span">Collection age</ComplexListText>}
                            secondElement={<ComplexListText as="span" level="xs" variant="tertiary">{(includePension === true) ? (<>{customPensionStartAge}</>) : 0}</ComplexListText>}
                        />
                    </ComplexList>
                </Stack>
            </Block>
        </Flex>
    )
}

// function changeDateFormat(inputDate){  // expects Y-m-d
//     let splitDate = inputDate.split('-');
//     if(splitDate.count === 0){
//         return '';
//     }

//     let year = splitDate[0];
//     let month = splitDate[1];
//     let day = splitDate[2]; 

//     return month + '/' + day + '/' + year;
// }

export default DashboardTabProfile