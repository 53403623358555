import { configureStore, combineReducers, createSlice, createListenerMiddleware } from '@reduxjs/toolkit'
import { api } from './api'
import quitModalSlice from './slices/quitModal'
import toaSlice from './slices/toa'

const tokenSlice = createSlice({
  name: 'token',
  initialState: { sessionToken: sessionStorage.sessionToken },
  reducers: {
    setToken(state, action)
    {
      state.sessionToken = action.payload
    }
  }
})

const { setToken } = tokenSlice.actions

const forgotUsernameMessage = createSlice({
  name: 'forgotUsernameMessage',
  initialState: { displayForgotUsernameMessage: false },
  reducers: {
    setForgotUsernameMessageDisplay(state, action)
    {
      state.displayForgotUsernameMessage = action.payload.value
    }
  }
})

export const { setForgotUsernameMessageDisplay } = forgotUsernameMessage.actions

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {},
  reducers: {
    answerQuestion(state, action)
    {
      state[action.payload.questionNumber] = action.payload.answer
    },
    reset: () => ({})
  }
})

export const { answerQuestion, reset } = questionnaireSlice.actions


const resetPwSlice = createSlice({
  name: 'resetPw',
  initialState: {
    username: '',
    resetTitleMsg: ''
  },
  reducers: {
    setUsername(state, action)
    {
      state.username = action.payload
    },
    setResetTitleMsg(state, action)
    {
      state.resetTitleMsg = action.payload
    },
  }
})

export const { setUsername, setResetTitleMsg } = resetPwSlice.actions


const footerContentSlice = createSlice({
  name: 'footerContent',
  initialState: {
    displayFlex: 0
  },
  reducers: {
    setDisplayFlex(state, action)
    {
      state.displayFlex = action.payload
    }
  }
})

export const { setDisplayFlex } = footerContentSlice.actions


const incomeReminderSlice = createSlice({
  name: 'incomeReminder',
  initialState: {
    hideIncomeReminder: sessionStorage.hideIncomeReminder
  },
  reducers: {
    setHideIncomeReminder(state, action)
    {
      state.hideIncomeReminder = action.payload
    }
  }
})

export const { setHideIncomeReminder } = incomeReminderSlice.actions


const incomePlanSlice = createSlice({
  name: 'incomePlan',
  initialState: sessionStorage.getItem("SlIncomeInfo") ? JSON.parse(sessionStorage.getItem("SlIncomeInfo")) : {},
  reducers: {
    setPlanAccepted(state, action)
    {
      state.planAccepted = action.payload
    },
    setPlanAcceptedNotification(state, action)
    {
      state.planAcceptedNotification = action.payload
    },
    setUpdatePlanNotification(state, action)
    {
      state.updatePlanNotification = action.payload
    },
    setAcceptedPlan(state, action)
    {
      state.acceptedPlan = action.payload
    },
    setStartAge(state, action)
    {
      state.startAge = action.payload
    },
    setCustomStartAge(state, action)
    {
      state.customStartAge = action.payload
    },
    setEndAge(state, action)
    {
      state.endAge = action.payload
    },
    setCustomEndAge(state, action)
    {
      state.customEndAge = action.payload
    },
    setMonthlyIncome(state, action)
    {
      state.monthlyIncome = action.payload
    },
    setCustomMonthlyIncome(state, action)
    {
      state.customMonthlyIncome = action.payload
    },
    setMonthlySocsec(state, action)
    {
      state.monthlySocsec = action.payload
    },
    setCustomMonthlySocsec(state, action)
    {
      state.customMonthlySocsec = action.payload
    },
    setCustomSocsecStartAge(state, action)
    {
      state.customSocsecStartAge = action.payload
    },
    setMonthlyPension(state, action)
    {
      state.monthlyPension = action.payload
    },
    setCustomMonthlyPension(state, action)
    {
      state.customMonthlyPension = action.payload
    },
    setCustomPensionStartAge(state, action)
    {
      state.customPensionStartAge = action.payload
    },
    setCustomGraphSmileMode(state, action)
    {
      state.customGraphSmileMode = action.payload
    },
    setPtileOverride(state, action)
    {
      state.ptileOverride = action.payload
    },
    setRiskProfileOverride(state, action)
    {
      state.riskProfileOverride = action.payload
    }
  }
})

export const { setPlanAccepted, setPlanAcceptedNotification, setUpdatePlanNotification, setAcceptedPlan, setStartAge, setCustomStartAge, setEndAge, setCustomEndAge, setMonthlyIncome, setCustomMonthlyIncome, setMonthlySocsec, setCustomMonthlySocsec, setCustomSocsecStartAge, setMonthlyPension, setCustomMonthlyPension, setCustomPensionStartAge, setCustomGraphSmileMode, setPtileOverride, setRiskProfileOverride } = incomePlanSlice.actions


const incomeAccountSlice = createSlice({
  name: 'incomeAccount',
  initialState: {
    include401k: true,
    includeSocsec: (sessionStorage.includeSocsec === "true"),
    includePension: (sessionStorage.includePension === "true"),
  },
  reducers: {
    setInclude401k(state, action)
    {
      state.include401k = action.payload
    },
    setIncludeSocsec(state, action)
    {
      state.includeSocsec = action.payload
    },
    setIncludePension(state, action)
    {
      state.includePension = action.payload
    },
  }
})

export const { setInclude401k, setIncludeSocsec, setIncludePension } = incomeAccountSlice.actions



const donutDataSlice = createSlice({
  name: 'donutData',
  initialState: {
    allocationDataList: null,
  },
  reducers: {
    setAllocationDataList(state, action)
    {
      state.allocationDataList = action.payload
    },

  }
})

export const { setAllocationDataList } = donutDataSlice.actions

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { user: null },
  reducers: {
    setUserInfo(state, action) {
      if (action.payload)
        state.user = { ...action.payload }
      else
        state.user = null
    }
  }
})

export const { setUserInfo } = userInfoSlice.actions

const sessionActivitySlice = createSlice({
  name: 'userActivity',
  initialState: {
    latestActivityTimestamp: Date.now(),
    latestAPIRequestTimestamp: Date.now()
  },
  reducers: {
    setLatestActivityTimestamp(state, action) {
      state.latestActivityTimestamp = action.payload
    },
    setLatestAPIRequestTimestamp(state, action) {
      if (
        !state.latestAPIRequestTimestamp ||
        (action.payload > state.latestAPIRequestTimestamp)
      ) {
        state.latestAPIRequestTimestamp = action.payload
      }
    }
  }
})

export const {
  setLatestActivityTimestamp,
  setLatestAPIRequestTimestamp
} = sessionActivitySlice.actions

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: 'test',
  effect: async (action, listenerApi) =>
  {
    if (action.type === 'api/executeMutation/fulfilled' && (action.meta?.arg?.endpointName === 'login' || action.meta?.arg?.endpointName === 'enrollUser'))
    {
      sessionStorage.sessionToken = action.payload.sessionToken
      listenerApi.dispatch(setToken(action.payload.sessionToken))
      listenerApi.dispatch(setUserInfo(action.payload.user))
    }
    
    if (
      (action.type === 'api/executeQuery/fulfilled' && action.payload?.status?.rc === -90) ||
      (action.type === 'api/executeMutation/fulfilled' && action.payload?.status?.rc === -90)
    )
    {
      delete sessionStorage.sessionToken
      listenerApi.dispatch(setToken(undefined))
      
      delete sessionStorage.SlIncomeInfo
    }    
    
    if (
      (action.type === 'api/executeQuery/fulfilled' && action.payload?.status?.rc !== -90) ||
      (action.type === 'api/executeMutation/fulfilled' && action.payload?.status?.rc !== -90)
    )
    {
      // console.log(action)
      listenerApi.dispatch(setLatestAPIRequestTimestamp(action.meta.fulfilledTimeStamp))
    }  
    
    // Used for SL Income only when dashboard is loaded
    if (action.type === 'api/executeMutation/fulfilled' && action.meta?.arg?.endpointName === 'getDashboard') {
       const user = action?.payload?.user;
       
       let SlIncomeInfo = { planAccepted: false,
                            planAcceptedNotification: false,
                            updatePlanNotification: false,
                            acceptedPlan: 0,
                            startAge: 0,
                            customStartAge: 0,
                            endAge: 0,
                            customEndAge: 0,
                            monthlyIncome: 0,
                            customMonthlyIncome: 0,
                            monthlySocsec: 0,
                            customMonthlySocsec: 0,
                            customSocsecStartAge: 0,
                            monthlyPension: 0,
                            customMonthlyPension: 0,
                            customPensionStartAge: 0,
                            customGraphSmileMode: 0,
                            ptileOverride: 0,
                            riskProfileOverride: 0,
       }
      
       let pension = user?.pqdiaReq?.pensions;
       if(pension > 0) pension = pension / 12;
       SlIncomeInfo.monthlyPension = pension;      
       SlIncomeInfo.startAge = user?.pqdiaReq?.expectedRetirementAge;
       // SlIncomeInfo.endAge = action?.payload?.incomeEndAge;
       SlIncomeInfo.endAge = action?.payload?.lifeAge;
       SlIncomeInfo.monthlyIncome = action?.payload?.incomeMonthlyAtRetire;
       SlIncomeInfo.monthlySocsec = action?.payload?.socsecMonthlyAtRetire;
       SlIncomeInfo.customGraphSmileMode = user?.incSmileChartMode;
       SlIncomeInfo.ptileOverride = user?.incPtileOverride;
       SlIncomeInfo.riskProfileOverride = user?.incRiskProfileOverride;
       
              
       if (user?.incomeAcceptedPlanDate !== undefined) {
         SlIncomeInfo.planAccepted = true;
         SlIncomeInfo.acceptedPlan = user?.incomeAcceptedPlan;
         SlIncomeInfo.planAcceptedNotification = true;
         SlIncomeInfo.hideIncomeReminder = true;
         SlIncomeInfo.updatePlanNotification = true;
         SlIncomeInfo.customStartAge = user?.incomeStartAgeOverride;
         SlIncomeInfo.customSocsecStartAge = user?.socsecStartAgeOverride;
         SlIncomeInfo.customPensionStartAge = user?.pensionStartAgeOverride;
         SlIncomeInfo.customEndAge = user?.incomeEndAgeOverride;
         SlIncomeInfo.customMonthlyIncome = user?.monthlyIncomeAtRetireOverride;
         SlIncomeInfo.customMonthlySocsec = user?.monthlySocsecAtRetireOverride;
         SlIncomeInfo.customMonthlyPension = user?.monthlyPensionAtRetireOverride;
         
         
         // Set include pension and socsec
         const incSoc = user?.incIncludeSocsec === 1;
         sessionStorage.includeSocsec = incSoc;
         listenerApi.dispatch(setIncludeSocsec(incSoc))

         const incPension = user?.incIncludePension === 1;
         sessionStorage.includePension = incPension; 
         listenerApi.dispatch(setIncludePension(incPension))
       }
       else {
         SlIncomeInfo.planAccepted = false;
         SlIncomeInfo.acceptedPlan = user?.incomeAcceptedPlan;
         SlIncomeInfo.planAcceptedNotification = false;
         SlIncomeInfo.hideIncomeReminder = false;
         SlIncomeInfo.updatePlanNotification = false;
         SlIncomeInfo.customStartAge = 0;
         SlIncomeInfo.customSocsecStartAge = 0;
         SlIncomeInfo.customPensionStartAge = 0;
         SlIncomeInfo.customEndAge = 0;
         SlIncomeInfo.customMonthlyPension = 0;
         SlIncomeInfo.customMonthlyIncome = 0;
         SlIncomeInfo.customMonthlySocsec = 0;
         
         
         sessionStorage.includeSocsec = false;
         listenerApi.dispatch(setIncludeSocsec(false))
         sessionStorage.includePension = false;
         listenerApi.dispatch(setIncludePension(false)) 
       } 
       sessionStorage.setItem("SlIncomeInfo", JSON.stringify(SlIncomeInfo))
       listenerApi.dispatch(setMonthlyPension(SlIncomeInfo.pension))
       listenerApi.dispatch(setStartAge(SlIncomeInfo.startAge))
       listenerApi.dispatch(setEndAge(SlIncomeInfo.endAge))
       listenerApi.dispatch(setMonthlyIncome(SlIncomeInfo.monthlyIncome))
       listenerApi.dispatch(setMonthlySocsec(SlIncomeInfo.monthlySocsec))
       listenerApi.dispatch(setCustomGraphSmileMode(SlIncomeInfo.customGraphSmileMode))
       listenerApi.dispatch(setPtileOverride(SlIncomeInfo.ptileOverride))
       listenerApi.dispatch(setRiskProfileOverride(SlIncomeInfo.riskProfileOverride))
       listenerApi.dispatch(setPlanAccepted(SlIncomeInfo.planAccepted))
       listenerApi.dispatch(setAcceptedPlan(SlIncomeInfo.acceptedPlan))
       listenerApi.dispatch(setPlanAcceptedNotification(SlIncomeInfo.planAcceptedNotification))
       listenerApi.dispatch(setHideIncomeReminder(SlIncomeInfo.hideIncomeReminder))
       listenerApi.dispatch(setUpdatePlanNotification(SlIncomeInfo.updatePlanNotification))
       listenerApi.dispatch(setCustomStartAge(SlIncomeInfo.customStartAge));
       listenerApi.dispatch(setCustomSocsecStartAge(SlIncomeInfo.customSocsecStartAge));
       listenerApi.dispatch(setCustomPensionStartAge(SlIncomeInfo.customPensionStartAge));
       listenerApi.dispatch(setCustomEndAge(SlIncomeInfo.customEndAge));
       listenerApi.dispatch(setCustomMonthlyPension(SlIncomeInfo.customMonthlyPension));
       listenerApi.dispatch(setCustomMonthlyIncome(SlIncomeInfo.customMonthlyIncome));
       listenerApi.dispatch(setCustomMonthlySocsec(SlIncomeInfo.customMonthlySocsec));

      //  console.log("SlIncome: ", sessionStorage.getItem("SlIncomeInfo"))
      //  console.log("SlIncome JSON parse: ", JSON.parse(sessionStorage.getItem("SlIncomeInfo")))
     }
  }
})

const userInfoMiddleware = createListenerMiddleware()

userInfoMiddleware.startListening({
  actionCreator: 'userInfoActionCreator',
  effect: async (action, listenerApi) => {
    if (
      action.type === 'api/executeQuery/fulfilled' &&
      action.meta?.arg?.endpointName === 'getUserInfo' &&
      action.payload?.status?.rc === 0
    ) {
      listenerApi.dispatch(setUserInfo(action.payload.user))
    }

    if (
      action.type === 'api/executeMutation/fulfilled' &&
      action.payload?.user
    ) {
      listenerApi.dispatch(setUserInfo(action.payload.user))
    }

    if (
      action.type === 'api/executeMutation/fulfilled' &&
      action.meta?.arg?.endpointName === 'logout'
    ) {
      listenerApi.dispatch(setUserInfo(null))
    }
  }
})


export const store = configureStore({
  reducer: combineReducers({
    [tokenSlice.name]: tokenSlice.reducer,
    [resetPwSlice.name]: resetPwSlice.reducer,
    [questionnaireSlice.name]: questionnaireSlice.reducer,
    [toaSlice.name]: toaSlice.reducer,
    [forgotUsernameMessage.name]: forgotUsernameMessage.reducer,
    [quitModalSlice.name]: quitModalSlice.reducer,
    [footerContentSlice.name]: footerContentSlice.reducer,
    [incomeReminderSlice.name]: incomeReminderSlice.reducer,
    [incomePlanSlice.name]: incomePlanSlice.reducer,
    [incomeAccountSlice.name]: incomeAccountSlice.reducer,
    [donutDataSlice.name]: donutDataSlice.reducer,
    [userInfoSlice.name]: userInfoSlice.reducer,
    [sessionActivitySlice.name]: sessionActivitySlice.reducer,
    [api.reducerPath]: api.reducer
  }),
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(api.middleware)
    .concat(listenerMiddleware.middleware)
    .concat(userInfoMiddleware.middleware)
})