import React from "react"
import { FormErrorMessage, FormHelperText, FormLabel, FormControl as ChakraFormControl, Box } from "@chakra-ui/react"
import { Badge, Copy, List, ListIcon, ListTextItem, Stack, Text, isEmpty, isNil, useBrightTheme, useColor, useTranslation } from "@stadion/bright"

const descriptionTextProps = {
    variant: 'tertiary',
    level: 'xs',
}

const labelTextPropsMap = {
    small: descriptionTextProps,
    regular: { as: 'strong' },
}

export const FormControlLabel = ({
    formLabelAs = 'label',
    label,
    labelBadgeText,
    labelBadgeAriaLabel,
    labelVariant = 'regular',
    useOptionalSuffix,
    isRequired,
}) => {
    const theme = useBrightTheme()
    const optionanLabelSuffix = useTranslation('optionalLabelSuffix')
    const showLabelSuffix = useOptionalSuffix && !isRequired

    return (
        <FormLabel
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            requiredIndicator={ () => null }
            m={0}
            as={formLabelAs}
            lineHeight="none"
        >
        <Text {...labelTextPropsMap[labelVariant]}>
            {label}
            {showLabelSuffix ? ` ${optionanLabelSuffix}` : null}
        </Text>
        {labelBadgeText && (
            <Badge marginStart={theme.space.md} ariaLabel={labelBadgeAriaLabel} variant="info">
            {labelBadgeText}
            </Badge>
        )}
        </FormLabel>
    )
}

export const FormControlDescription = ({ description }) => (
    <FormHelperText>
        <Copy {...descriptionTextProps} html={description} />
    </FormHelperText>
)

export const FormControlErrors = ({ errors }) => {
    const errorColor = useColor('core', 'negativePrimary')

    return (
        <FormErrorMessage m="0" fontSize="md" color={errorColor} width="100%">
            <List level="xs">
                {Object.entries(errors).map(([code, message]) => (
                <ListTextItem
                    key={code}
                    variant="error"
                    marker={<ListIcon color={errorColor} variant="FunctionalExclamationOutline" />}
                >
                    {message}
                </ListTextItem>
                ))}
            </List>
        </FormErrorMessage>
    )
}

export const FormControl = ({
    children,
    errors,
    isRequired,
    label,
    labelBadgeText,
    labelBadgeAriaLabel,
    description,
    id,
    as = 'div',
    role = 'group',
    useOptionalSuffix = true,
}) => {
    const textColor = useColor('contentSecondary')
    const showLabelAndDescriptionSection = label || description

    return (
        <ChakraFormControl
            isInvalid={errors && !isEmpty(errors)}
            isRequired={isRequired}
            id={id}
            color={textColor}
            as={as}
            role={role}
        >
        {label && (
            <FormControlLabel
                label={label}
                formLabelAs={as === 'fieldset' ? 'legend' : 'label'}
                isRequired={isRequired}
                labelBadgeText={labelBadgeText}
                labelBadgeAriaLabel={labelBadgeAriaLabel}
                useOptionalSuffix={useOptionalSuffix}
                aria-hidden="true"
            />
        )}
        {description && (
            <Box marginTop={label ? '2xs' : '0'}>
                <FormControlDescription description={description} />
            </Box>
        )}
        <Box marginTop={showLabelAndDescriptionSection ? 'sm' : 0}>
            <Stack space="sm">
            {children}
            {errors && !isEmpty(errors) && <FormControlErrors errors={errors} />}
            </Stack>
        </Box>
        </ChakraFormControl>
    )
}