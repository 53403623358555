import { Adjacent, Alert, Block, Button, Frame, Link, Stack, Text, useNavigation } from '@stadion/bright'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import RadioInput from '../../RadioInput'
import { answerQuestion } from '../../../store/store'
import { useDispatch } from 'react-redux'

function Questions1() {
    const navigation = useNavigation()
    const questionnaire = useSelector(state => state.questionnaire)
    const dispatch = useDispatch()
    const [ errors, setErrors ] = useState({})
    
    const checkForm = () => {
        if (questionnaire.q1 && questionnaire.q2)
            navigation.redirectTo('/participant/questionnaire/questions-2')
        else
            setErrors({
                q1: questionnaire.q1 ? null : [ 'Rating is required' ],
                q2: questionnaire.q2 ? null : [ 'Rating is required' ]
            })
    }

    const hasErrors = Object.values(errors).some(e => e)

    return (
        <Block palette="baseTwo" paddingY="4xl">
            <Frame width={ [ 'full' ] }>
                <Stack space="6xl">
                    { hasErrors && <Alert variant="negative" heading="Questionnaire answers missing" /> }
                    <RadioInput
                        label="1. I don’t mind taking some risks with my money to increase the amount I have."
                        isRequired={ true }
                        name="q1"
                        value={ questionnaire.q1 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q1', answer })) }
                        errors={ errors.q1 || [] }
                    />
                    <RadioInput
                        label="2. A bumpy ride is ok if it ends in the right place."
                        isRequired={ true }
                        name="q2"
                        value={ questionnaire.q2 }
                        onChange={ answer => dispatch(answerQuestion({ questionNumber: 'q2', answer })) }
                        errors={ errors.q2 || [] }
                    />
                    <Adjacent space="md">
                        <Link href="/participant/questionnaire/financial-information" variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={ checkForm }>
                            Next
                        </Button>
                    </Adjacent>
                </Stack>
            </Frame>
        </Block>
    )    
}

export default Questions1