import { useSelector } from 'react-redux'

export default function useLatestActivity() {
    const {
        latestActivityTimestamp,
        latestAPIRequestTimestamp
    } = useSelector(state => state.userActivity)

    const now = Date.now()

    return {
        latestActivityTimestamp,
        latestAPIRequestTimestamp
    }
}