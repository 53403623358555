import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false
  }

const quitModalSlice = createSlice({
  name: 'quitModal',
  initialState,
  reducers: {
    toggle(state) {
        state.isOpen = !state.isOpen
    }
  }
})

export const { toggle } = quitModalSlice.actions

export default quitModalSlice