import { Box, Container, Flex } from '@chakra-ui/layout'
import { Block, ElevatedCard, PageHeadingBlock, Stack, TextInput, Button, MainWrapper, Text, Alert, Select, PhoneInputSimple, Textarea } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { useSendMessageMutation } from '../../store/api'
import { stateOptions } from '../../utils/forms'
import PageWrapper from '../PageWrapper'

const emptyMessage = {
    idSelection: '',
    firstName: '',
    lastName: '',
    contactEmail: '',
    state: '',
    phone: '',
    messageToStadion: ''
}

function Contact() {
    const [ sendMessage, sendMessageResponse ] = useSendMessageMutation()
    const [ message, setMessage ] = useState(emptyMessage)

    const handleInput = e => {
        setMessage({
            ...message,
            [e.target.name || e.target.id]: e.target.value
        })
    }

    const handleSelect = name => value => setMessage({
        ...message,
        [name]: value
    })

    const handleSubmit = e => {
        e.preventDefault()
        sendMessage({
            contactUsFields: Object.entries(message)
                .reduce((contactUsFields, entry) => entry[1] ? [ ...contactUsFields, ...entry ] : [ ...contactUsFields ], [])
        })
    }

    const success = sendMessageResponse.data?.status?.rc === 0
    const fail = sendMessageResponse.isError || (sendMessageResponse.data?.status?.rc && sendMessageResponse.data?.status?.rc < 0)

    useEffect(() => {
        if (success)
            setMessage(emptyMessage)
    }, [ success ])
    
    const content = (
        <Container>
            <ElevatedCard>
                <Stack space="xl">
                    <Text level="xl" variant="primary" as="b">
                        Contact us
                    </Text>
                    <Text level="sm" as="p">
                        Get in touch with us. We'd love to hear from you.
                    </Text>
                    { success && <Alert variant="positive" heading="Message successfully sent" /> }
                    { fail && <Alert variant="negative" heading={ sendMessageResponse.data?.status?.msg || sendMessageResponse.error?.data?.message || 'Sending message failed' } /> }
                    <form onSubmit={ handleSubmit }>
                        <Stack space="xl">
                            <Select
                                label="I am a: *"
                                name="idSelection"
                                isRequired={ true }
                                items={
                                    [
                                        { label: 'Financial Professional', id: 'Financial Professional' },
                                        { label: 'Employer/Benefits Professional', id: 'Employer/Benefits Professional' },
                                        { label: 'Individual Investor', id: 'Individual Investor' },
                                        { label: 'Other', id: 'Other' }
                                    ]
                                }
                                value={ message.idSelection }
                                onChange={ handleSelect('idSelection') }
                            />
                            <TextInput
                                label="First name"
                                name="firstName"
                                value={ message.firstName }
                                onChange={ handleInput }
                                pattern="^[A-z ,\.'\-]+$"
                            />
                            <TextInput
                                label="Last name"
                                name="lastName"
                                value={ message.lastName }
                                onChange={ handleInput }
                                pattern="^[A-z ,\.'\-]+$"
                            />
                            <TextInput
                                label="Email Address *"
                                name="contactEmail"
                                isRequired={ true }
                                value={ message.contactEmail }
                                onChange={ handleInput }
                                pattern="^\S+@\S+\.\S+$"
                            />
                            <Select
                                label="State"
                                name="state"
                                items={ stateOptions }
                                value={ message.state }
                                onChange={ handleSelect('state') }
                            />
                            <PhoneInputSimple
                                label="Phone"
                                name="phone"
                                value={ message.phone }
                                onChange={ handleInput }
                            />
                            <Textarea
                                label="Message to Stadion"
                                name="messageToStadion"
                                id="messageToStadion"
                                isRequired={ true }
                                value={ message.messageToStadion }
                                onChange={ handleInput }
                            />
                            <Text as="span">* Required field</Text>
                            <Button
                                type="submit"
                                variant="primaryButton"
                                isLoading={ sendMessageResponse.isLoading }
                            >
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </Stack>
            </ElevatedCard>
        </Container>
    )
    return (
        <PageWrapper pageTitle="Contact Us">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="6xl">
                                <Box>{content}</Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default Contact