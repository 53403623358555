import { FunctionalIcon, Tooltip } from '@stadion/bright'
import React from 'react'

function InfoTooltip({ label, variant = 'FunctionalInfoOutline', size = 'xs', color = '#007793', textIn }) {
    return (
        <Tooltip label={ label }>
            <FunctionalIcon
                variant={ variant }
                size={ size }
                color={ color }
            />
            {textIn}
        </Tooltip>
    )
}

export default InfoTooltip