import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4';


function initializeAnalytics()
{
    ReactGA.initialize(window.location.hostname === 'stadionstoryline.com' ? 'G-FSS5R7S44S' : 'G-2K1WVNTWQ5')
}

function RouteChangeTrackerGA()
{
    const location = useLocation();
    // console.log(location)
    const [initialized, setInitialized] = useState(false);

    useEffect(() =>
    {
        initializeAnalytics();
        setInitialized(true);
    }, []);

    useEffect(() =>
    {
        if (initialized)
        {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search })
        }
    }, [initialized, location]);
}


export default RouteChangeTrackerGA;