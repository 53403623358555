import { Box, Container, Flex, Grid } from '@chakra-ui/layout'
import { ElevatedCard, Checkbox, PageHeadingBlock, Stack, TextInput, Block, PasswordInput, Button, MainWrapper, Link, Adjacent, Text, Select, Alert, Copy } from '@stadion/bright'
import React, { useEffect, useRef, useState } from 'react'
import { useEnrollUserMutation } from '../../store/api'
import { Navigate } from 'react-router-dom'
import { getFormFields, getErrors, errorTemplates, stateOptions } from '../../utils/forms'
import routes, { participantRoutes } from '../../routes'
import PageWrapper from '../PageWrapper'
import useUser from '../../store/useUser'

function SignUp() {
    const [ acceptedTerms, setAcceptedTerms ] = useState(false)
    // const { sessionToken } = useSelector(state => state.token)
    const [ enrollUser, enrollUserResponse ] = useEnrollUserMutation()
    const [ user, setUser ] = useState({
        firstName: '',
        lastName: '',
        last4ssn: '',
        state: '',
        email: '',
        confirmEmail: '',
        enrollUsername: '',
        enrollPassword: '',
        confirmPassword: '',
        enrollmentCode: ''
    })
    const [ errors, setErrors ] = useState({})
    const [ stateInput, setStateInput ] = useState({ current: null }) 
    const [ enrollPasswordInput, setEnrollPasswordInput ] = useState({ current: null })
    const [ confirmPasswordInput, setConfirmPasswordInput ] = useState({ current: null })
    const [ acceptedTermsInput, setAcceptedTermsInput ] = useState({ current: null })
    const stateUser = useUser()
    
    useEffect(() => {
        //if (sessionToken)
        //    return
            
        const stateInputElement = document.querySelector('[name=state]')
        const enrollPasswordInputElement = document.getElementById('enrollPassword')
        const confirmPasswordInputElement = document.getElementById('confirmPassword')
        const acceptedTermsInput = document.getElementById('accepted-terms')

        enrollPasswordInputElement.setAttribute('pattern', '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!$%@^&*_\\-])[A-Za-z\\d#?!$%@^&*_\\-]{8,}$')
        confirmPasswordInputElement.setAttribute('pattern', '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?!$%@^&*_\\-])[A-Za-z\\d#?!$%@^&*_\\-]{8,}$')

        setStateInput({ current: stateInputElement })
        setEnrollPasswordInput({ current: enrollPasswordInputElement })
        setConfirmPasswordInput({ current: confirmPasswordInputElement })
        setAcceptedTermsInput({ current: acceptedTermsInput })
    }, [])

    const form = useRef()
    const firstNameInput = useRef()
    const lastNameInput = useRef()
    const last4ssnInput = useRef()
    const usernameInput = useRef()
    const emailInput = useRef()
    const confirmEmailInput = useRef()
    const enrollmentCodeInput = useRef()

    useEffect(() => {
        if (!confirmEmailInput.current)
            return

        if (user.email !== user.confirmEmail)
            confirmEmailInput.current.setCustomValidity(`Email address doesn't match`)
        else
            confirmEmailInput.current.setCustomValidity('')
    }, [ user.email, user.confirmEmail ])

    useEffect(() => {
        if (!confirmPasswordInput.current)
            return

        if (user.enrollPassword !== user.confirmPassword)
            confirmPasswordInput.current.setCustomValidity(`Passwords must match`)
        else
            confirmPasswordInput.current.setCustomValidity('')
    }, [ user.enrollPassword, user.confirmPassword ])

    const updateUserField = e => {
        setUser({
            ...user,
            [e.target.name || e.target.id]: e.target.value
        })
    }

    const checkValidity = errorTemplate => e => {
        const { target } = e

        if (target.validity.valid)
            setErrors({ ...errors, [target.name || target.id]: null })
        else {
            setErrors({
                ...errors,
                [target.name || target.id]: getErrors(target, errorTemplate[target.name || target.id])
            })
        }
    }

    const handleSubmit = e => {
        e.preventDefault()

        const inputs = [
            firstNameInput,
            lastNameInput,
            last4ssnInput,
            stateInput,
            confirmEmailInput,
            usernameInput,
            emailInput,
            enrollPasswordInput,
            confirmPasswordInput,
            enrollmentCodeInput,
            acceptedTermsInput
        ]

        let errors = {}

        for (let input of inputs) {
            try {
                errors[input.current.name || input.current.id] = getErrors(input.current, errorTemplates[input.current.name || input.current.id])
            }
            catch (e) {
                console.log(e)
            }
        }

        setErrors(errors)

        if (!Object
            .values(errors)
            .filter(err => err)
            .length) {
            enrollUser({ enrollmentFields: getFormFields(user) })
        }
    }

    useEffect(() => {
        if (!enrollUserResponse.data)
            return

        const { rc, msg } = enrollUserResponse.data.status

        if (rc === -2 && msg.includes('username')) {
           setErrors({ enrollUsername: [ msg ] })
           console.log([ msg] );
        }
        else if ((rc === -1) && (enrollUserResponse.data.errFieldNames !== undefined)) {
            setErrors(enrollUserResponse.data.errFieldNames.reduce((errors, fn) => ({ 
                        ...errors,
                        [fn]: [ 'Invalid value'] 
                    }), {}
                )
            )
        }
        else if (rc < 0) {
           // When general error occurs not displayed above form appears to hang without displaying error.
           setErrors({ generic: enrollUserResponse.data.status?.msg })
        }
    }, [ enrollUserResponse.data ])

    useEffect(() => {
        if (enrollUserResponse.isError)
            setErrors({
                generic: enrollUserResponse.error.data?.status?.message || enrollUserResponse.error.data?.msg || 'Unknown error happened'
            })
    }, [ enrollUserResponse ])

    // if (sessionToken)
    //    return <Navigate to={ routes.dashboard } />

    if (enrollUserResponse.isSuccess && enrollUserResponse.data.status.rc === 0 && stateUser)
        return <Navigate to={ participantRoutes.getStarted } />

    const errorEntries = Object.values(errors)
        .filter(err => err)

    const content = (
        <Container maxW="container.sm">
            <ElevatedCard>
                <form ref={ form } onSubmit={ handleSubmit } noValidate>
                    <Stack space="3xl">
                        {
                            errorEntries.length > 0 &&
                            <Alert
                                variant="negative"
                                heading="Signup failed"
                                content={
                                    errors.generic ||
                                    "Please check the errors below before proceeding"}
                            />
                        }
                        <Text level="xl" as="b" variant="primary">
                            Write your own story
                        </Text>
                        <Text as="p">
                            StoryLine gives you a way to tailor your path based on your current situation and retirement goals. Register below to get started.
                        </Text>
                        <TextInput
                            inputRef={ firstNameInput }
                            label="First name *"
                            ariaLabel="First name"
                            isRequired={ true }
                            // pattern="^[A-z ,\.'\-]+$"
                            pattern="^[^0-9_!¡?÷?¿\/\\+=@#$%\^&*\(\)\{\}\|~<>;:\[\]]{1,}\S(.*\S)?$"  
                            onBlur={ checkValidity(errorTemplates) }
                            name="firstName"
                            errors={ errors['firstName'] || [] }
                            value={ user.firstName }
                            onChange={ updateUserField }
                        />
                        <TextInput
                            inputRef={ lastNameInput }
                            label="Last name *"
                            ariaLabel="Last name"
                            isRequired={ true }
                            pattern="^[^0-9_!¡?÷?¿\/\\+=@#$%\^&*\(\)\{\}\|~<>;:\[\]]{1,}\S(.*\S)?$"  
                            onBlur={ checkValidity(errorTemplates) }
                            name="lastName"
                            errors={ errors['lastName'] || [] }
                            value={ user.lastName }
                            onChange={ updateUserField }
                        />
                        <TextInput
                            inputRef={ last4ssnInput }
                            label="Last four numbers of your social security number *"
                            ariaLabel="Last four numbers of your social security number"
                            isRequired={ true }
                            inputMode="numeric"
                            minLength={ 4 }
                            maxLength={ 4 }
                            pattern="^[0-9]{4}$"
                            // onBlur={ checkValidity(errorTemplates) }
                            name="last4ssn"
                            errors={ errors['last4ssn'] || [] }
                            value={ user.last4ssn }
                            onChange={ updateUserField }
                        />
                        <Select
                            label="Residing state *"
                            description="Select state from the drop down"                            
                            ariaLabel="State"
                            name="state"
                            isRequired={ true }
                            items={ stateOptions }
                            errors={ errors['state'] || [] }
                            value={ user.state }
                            onChange={ state => setUser({ ...user, state }) }
                        />
                        <TextInput
                            inputRef={ emailInput }
                            label="Email address *"
                            ariaLabel="Email address"
                            isRequired={ true }
                            pattern="^\S+@\S+\.\S+$"
                            // onBlur={ checkValidity(errorTemplates) }
                            name="email"
                            errors={ errors['email'] || [] }
                            value={ user.email }
                            onChange={ updateUserField }
                        />
                        <TextInput
                            inputRef={ confirmEmailInput }
                            label="Confirm email *"
                            ariaLabel="Confirmation email address"
                            isRequired={ true }
                            pattern="^[a-zA-Z0-9_@\.\-]{5,}$"
                            // onBlur={ checkValidity(errorTemplates) }
                            name="confirmEmail"
                            errors={ errors['confirmEmail'] || [] }
                            value={ user.confirmEmail }
                            onChange={ updateUserField }
                        />
                        <TextInput
                            inputRef={ usernameInput }
                            label="Username *"
                            ariaLabel="Username"
                            description="Minimum five characters"
                            isRequired={ true }
                            pattern="^[a-zA-Z0-9_@\.\-]{5,}$"
                            onBlur={ checkValidity(errorTemplates) }
                            name="enrollUsername"
                            errors={ errors['enrollUsername'] || [] }
                            value={ user.enrollUsername }
                            onChange={ updateUserField }
                        />
                        <PasswordInput
                            inputRef={ enrollPasswordInput }
                            label="Password *"
                            ariaLabel="Password"
                            description="Minimum of 8 characters; one number, one uppercase and one lowercase letter, special character #?!@$%^&*-"
                            isRequired={ true }
                            onBlur={ checkValidity(errorTemplates) }
                            name="enrollPassword"
                            id="enrollPassword"
                            errors={ errors['enrollPassword'] || [] }
                            value={ user.enrollPassword }
                            onChange={ updateUserField }
                        />
                        <PasswordInput
                            inputRef={ confirmPasswordInput }
                            label="Confirm password *"
                            ariaLabel="Confirm password"
                            description="Minimum of 8 characters; one number, one uppercase and one lowercase letter, special character #?!@$%^&*-"
                            isRequired={ true }
                            onBlur={ checkValidity(errorTemplates) }
                            name="confirmPassword"
                            id="confirmPassword"
                            errors={ errors['confirmPassword'] || [] }
                            value={ user.confirmPassword }
                            onChange={ updateUserField }
                        />
                        <TextInput
                            inputRef={ enrollmentCodeInput }
                            label="Enrollment code *"
                            ariaLabel="Enrollment code"
                            description="Six digit number"
                            isRequired={ true }
                            name="enrollmentCode"
                            pattern="[0-9A-Za-z]{6}"
                            // onBlur={ checkValidity(errorTemplates) }
                            errors={ errors['enrollmentCode'] || [] }
                            value={ user.enrollmentCode }
                            onChange={ updateUserField }
                        />
                        <Text as="span">* Required field</Text>
                        <Checkbox
                            id="accepted-terms"
                            isChecked={ acceptedTerms }
                            isRequired={ true }
                            onChange={ e => setAcceptedTerms(e.target.checked) }
                            label={ <Copy html={ `<p>I agree to the <a href="https://cdn.stadionmoney.com/Footer/StoryLine_Interface_Terms.pdf">Terms & Conditions</a></p>` } /> }
                            errors={ errors['accepted-terms'] }
                        />
                        <Grid templateColumns={ [ '1fr', 'auto auto' ] } justifyContent="start" rowGap="4" columnGap="4" paddingTop="3">
                            <Link variant="secondaryButton" href="/login">
                                Cancel
                            </Link>
                            <Button type="submit" isLoading={ enrollUserResponse.isLoading }>
                                Submit
                            </Button>
                        </Grid>
                        <Flex paddingTop="3">
                            <Adjacent space="lg">
                                <Text as="b">Already registered?</Text>
                                <Link href="/login"><b>Log in</b></Link>
                            </Adjacent>
                        </Flex>
                    </Stack>
                </form>
            </ElevatedCard>
        </Container>
    )
    return (
        <PageWrapper pageTitle="Enroll">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="6xl">
                                <Box>{content}</Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default SignUp