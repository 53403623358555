import { Box, Container, Flex } from '@chakra-ui/layout'
import { ElevatedCard, Heading, PageHeadingBlock, Stack, TextInput, Block, Button, MainWrapper, Text, Alert, Link, Adjacent } from '@stadion/bright'
import { Navigate } from 'react-router'
import { useForgotPasswordMutation } from '../../store/api'
import { setUsername } from '../../store/store'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import routes from '../../routes'
import PageWrapper from '../PageWrapper'


function ForgotPassword() {
    const { sessionToken } = useSelector(state => state.token)
    const username = useSelector(state => state.resetPw.username)
    const resetTitleMsg = useSelector(state => state.resetPw.resetTitleMsg)
    const dispatch = useDispatch()
    const [ forgotPassword, forgotPasswordResponse ] = useForgotPasswordMutation()

    const forgotPwRc = forgotPasswordResponse.data?.status?.rc === 0
    const forgotPasswordError = (
        forgotPasswordResponse.data?.status?.rc !== undefined &&
        forgotPasswordResponse.data?.status?.rc < 0
    )
    

    if (sessionToken)
        return <Navigate to={ routes.dashboard } />

    if (forgotPwRc)
        return <Navigate to={ routes.updatePassword } />

    const handleForgotPassword = e => {
        e.preventDefault()
        forgotPassword({ username })
    }

    const usernameErrors = forgotPasswordError ? [ 'Please check your input' ] : []

    
    const content = (
        <Container>
            <ElevatedCard>
                <Stack space="xl">
                    <Text level="xl" variant="primary" as="b">
                       Forgot or reset your password?
                    </Text>
                    if(resetTitleMsg)
                    {
                        <Text as="b" variant="error">
                          { resetTitleMsg }
                        </Text>
                    }
                    {
                        forgotPasswordError &&
                        <Alert variant="negative">
                            <Heading as="h5" color="contentSecondary">
                                { forgotPasswordResponse.data?.status?.msg }
                            </Heading>
                        </Alert>
                    }
                    <form onSubmit={ handleForgotPassword }>
                        <Stack space="xl">
                            <TextInput
                                label="Username"
                                isRequired={ true }
                                value={ username }
                                onChange={ e => dispatch(setUsername(e.target.value)) }
                                errors={ usernameErrors }
                            />
                            <Adjacent space="md">
                                <Link href={ routes.login } variant="secondaryButton">
                                    Cancel
                                </Link>
                                <Button
                                    type="submit"
                                    variant="primaryButton"
                                    isLoading={ forgotPasswordResponse.isLoading }
                                >
                                    Submit
                                </Button>
                            </Adjacent>
                        </Stack>
                    </form>
                    <Adjacent space="md">
                        <Text as="b">Forgot your username?</Text>
                        <Link href={ routes.forgotUsername }><b>Click here</b></Link>
                    </Adjacent>
                </Stack>
            </ElevatedCard>
        </Container>
    )
    return (
        <PageWrapper pageTitle="Forgot Password">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="6xl">
                                <Box>{content}</Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default ForgotPassword