import { Flex } from '@chakra-ui/layout'
import { Block, Frame, Link, MainWrapper, PageHeadingBlock, ProgressIndicator, QuitAndRestartModal, Stack, Text, useNavigation } from '@stadion/bright'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Navigate, useLocation } from 'react-router'
import { reset } from '../../../store/store'
import { reset as resetToA } from '../../../store/slices/toa'
import { toggle } from '../../../store/slices/quitModal'
import routes, { participantRoutes, wizardRoutes } from '../../../routes'
import PageWrapper from '../../PageWrapper'

function Wizard() {
    const footerContent = useSelector(state => state.footerContent)
    const { isOpen } = useSelector(state => state.quitModal)
    const dispatch = useDispatch()
    const navigate = useNavigation()
    const location = useLocation()
    const toggleModal = () => dispatch(toggle())
    const steps = [
        'profile',
        'financial-information',
        'questions-1',
        'questions-2',
        'questions-3',
        'questions-4',
        'questions-5',
        'review',
        'toa'
    ]
    const step = location.pathname.split('/')[3]
    const stepIndex = steps.indexOf(step) + 1
    const restart = () => {
        toggleModal()
        dispatch(reset())

        if (stepIndex < 3)
            navigate.redirectTo(participantRoutes.getStarted)
        else
            navigate.redirectTo(wizardRoutes.profile)
    }

    let pageHeading, subheading
    switch (stepIndex) {
        case 1:
        case 2:
            pageHeading = 'Your profile'
            break
        case 3:
        case 4:
        case 5:
        case 6:
            pageHeading = 'Questionnaire'
            subheading = 'On the next few pages are a series of statements - please rate them on a 1 to 5 scale where 1 means "does not describe me at all" and 5 means "describes me very well"'
            break
        case 7:
            pageHeading = 'Questionnaire'
            subheading = 'Choose the answer to the question that best represents your financial situation'
            break
        case 8:
            pageHeading = 'Review and confirm your results'
            break
        default:
            pageHeading = 'Terms of Agreement'
            break
    }

    // reset wizard if dismounted
    useEffect(() => {
        return () => {
            dispatch(reset())
            dispatch(resetToA())
        }
    }, [])

    if (!step)
        return <Navigate to={ '/participant/questionnaire/profile' } />

    let flexFooterText1 = "Allocation percentages shown are the target model for each portfolio. Actual percentages will vary based on market conditions. Stadion reserves the right to modify its current investment strategies based on changing market dynamics or client needs.";
    let flexFooterText2 = "Large cap is typically defined as companies with market capitalization greater than $10 billion. Large cap stocks tend to " +
                          "be less volatile than mid cap and small cap stocks and are therefore considered less risky. Mid caps are typically defined " +
                          "as companies with market caps that are between $2 billion and $10 billion. Mid cap stocks tend to be riskier than large cap " +
                          "stocks but less risky than small cap stocks. Mid cap stocks, however, tend to offer more growth potential than large cap stocks. " +
                          "Small caps are typically defined as companies with market caps that are less than $2 billion. Many small caps are young companies " +
                          "with significant growth potential. However, the risk of failure is greater with small cap stocks than with large cap and mid cap " +
                          "stocks. As a result, small cap stocks tend to be the more volatile (and therefore riskier) than large cap and mid cap stocks. " +
                          "Developed markets: A developed economy is typically characteristic of a developed country with a relatively high level of economic " +
                          "growth and security. Standard criteria for evaluating a country's level of development are income per capita or per capita gross " +
                          "domestic product, the level of industrialization, the general standard of living, and the amount of technological infrastructure. " +
                          "Non-economic factors, such as the human development index (HDI), which quantifies a country's levels of education, literacy, and " +
                          "health into a single figure, can also be used to evaluate an economy or the degree of development. Emerging markets: A stock or " +
                          "stock fund that invests primarily in countries with developing economies (that is, those that are becoming industrialized). " +
                          "Emerging markets funds tend to be more volatile than domestic stock funds due to currency fluctuation and political instability. " +
                          "Consequently, fund prices can fluctuate dramatically. World ex-U.S.: Captures large, mid and small cap representation across 22 of " +
                          "23 Developed Markets (DM) countries (excluding the United States) and 24 Emerging Markets (EM) countries. With 6,576 constituents, " +
                          "the index covers approximately 99% of the global equity opportunity set outside the US. REITs: A Real Estate Investment Trust (REIT) " +
                          "is a security that trades like a stock on the major exchanges and owns—and in most cases operates—income-producing real estate or " +
                          "related assets. Aggregate Bond: Aggregate bonds are investment grade, US dollar-denominated, fixed-rate taxable bonds. This includes " +
                          "Treasuries, government-related and corporate securities, MBS (agency fixed-rate pass-throughs), ABS and CMBS (agency and non-agency). " +
                          "Cash: The value of assets that can be converted into cash immediately. TIPS: Treasury Inflation Protected Securities (TIPS) are bonds " +
                          "offered by the U.S. Department of Treasury that help protect investors against inflation. Issued with maturities of 5, 10, and 30, " +
                          "years; the principal amount is adjusted semi-annually for inflation based on changes in the CPI (Consumer Price Index). TIPS are backed " +
                          "by the “full faith and credit” of the U.S> Government and vary little default risk. High Yield: Bonds that are believed to have a higher " +
                          "risk of default and receive low ratings by credit rating agencies, namely bonds rated Ba or below (by Moody's) or BB or below (by S&P and " +
                          "Fitch). These bonds typically are issued at a higher yield (for example, a higher interest rate) than more creditworthy bonds, reflecting " +
                          "the perceived higher risk to investors.";
    if(footerContent.displayFlex === 2) {
        flexFooterText2 = "Trustee information: Benefit Trust Company (BTC) is the Trustee of the Stadion ETF Master CITs. BTC created and administers the Stadion ETF " +
                          "Master CITs which are collective investment funds. BTC is responsible for evaluating and selecting the Fund’s advisor. As the trustee BTC is a " +
                          "fiduciary for the trust. The value of the units of these Funds will fluctuate and is not guaranteed by Benefit Trust Company or the FDIC. When " +
                          "redeemed, units may be worth more or less than the original cost. Past performance is no guarantee of future results.";

   }
    
    let flexFooterContent = null;
    if(footerContent.displayFlex === 2) { // CIT
        flexFooterContent = 
        <><Text as="p" level="2xs">
                {flexFooterText1}
            </Text>
            <Text as="p" level="2xs">
                {flexFooterText2}
            </Text>
        </>   
    }
    if(footerContent.displayFlex === 1) {  // non-CIT/FIP only
        flexFooterContent =
          <><Text as="p" level="2xs">
               {flexFooterText1}
            </Text>
            <Text as="p" level="2xs">
               {flexFooterText2}
            </Text>
            <Text as="p" level="2xs"> 
                Sources:
               <Link href="https://www.bloomberg.com" level="2xs"> www.bloomberg.com;</Link>
               <Link href="https://www.finra.org/investors/insights/market-cap" level="2xs"> www.finra.org/investors/insights/market-cap;</Link>
               <Link href="https://www.nasdaq.com/glossary/e/emerging-markets-fund" level="2xs"> www.nasdaq.com/glossary/e/emerging-markets-fund;</Link>
               <Link href="https://https://www.nasdaq.com/glossary/c/cash-and-equivalents" level="2xs"> https://www.nasdaq.com/glossary/c/cash-and-equivalents;</Link>
               <Link href="https://www.finra.org/investors/learn-to-invest/types-investments/bonds/types-of-bonds/tips-and-strips" level="2xs"> www.finra.org/investors/learn-to-invest/types-investments/bonds/types-of-bonds/tips-and-strips;</Link>
               <Link href="https://www.investopedia.com/terms/d/developed-economy.asp" level="2xs"> www.investopedia.com/terms/d/developed-economy.asp;</Link>
               <Link href="https://www.investor.gov/introduction-investing/investing-basics/glossary/high-yield-bond-or-junk-bond" level="2xs"> www.investor.gov/introduction-investing/investing-basics/glossary/high-yield-bond-or-junk-bond;</Link>
               <Link href="https://www.reit.com" level="2xs"> www.reit.com;</Link>
               <Link href="https://www.msci.com" level="2xs"> www.msci.com;</Link>
               <Link href="https://stadionmoney.com" level="2xs"> Stadion</Link>
            </Text>
        </>   
    }

    return (
        <PageWrapper pageTitle={pageHeading}>
            <MainWrapper palette="baseTwo">
                <Block palette="baseTwo">
                    <Stack hasDivider={true}>
                        <Frame paddingY="md">
                            <ProgressIndicator
                                message={`Step: ${stepIndex} of ${steps.length}`}
                                totalSteps={steps.length}
                                currentStep={stepIndex}
                            />
                        </Frame>
                        <PageHeadingBlock
                            pageHeading={pageHeading}
                            subheading={subheading}
                            palette='baseTwo'
                            showDivider={false}
                        />
                        <Flex height="100%" flexDir="column" rowGap="10">
                            <Outlet />
                        </Flex>
                        <Frame paddingY="3xl">
                            <Stack space="5xl">
                                <QuitAndRestartModal
                                    quitAndRestartLinkText="Quit and return to the start"
                                    quitAndRestartModalTitle="Are you sure you want to quit?"
                                    quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                                    quitAndRestartModalCancelButtonText="Cancel"
                                    quitAndRestartModalConfirmButtonText="Yes, quit"
                                    onQuitAndRestartConfirm={restart}
                                    isOpen={isOpen}
                                    onClose={toggleModal}
                                    onOpen={toggleModal}
                                />
                                <Stack space="sm">
                                    {flexFooterContent}
                                    <Link href="https://cdn.stadionmoney.com/Footer/StoryLine_Interface_Terms.pdf" level="2xs">
                                        <b>StoryLine Terms and Conditions</b>
                                    </Link>
                                    <Stack space="md">
                                        <Text as="p" level="2xs">
                                            Past performance is no guarantee of future results. Investments are subject to risk, and any of Stadion’s investment strategies may lose money. Your account balances, your personal circumstances, investment returns and other material factors will likely change over time. As a result, your outcomes and estimated retirement income can, and likely will, change. As a result, it is important that you review your Dashboard on a regular basis, for example, annually. Allocation percentages shown are the target model for each portfolio. Actual percentages will vary based on market conditions. Stadion reserves the right to modify its current investment strategies based on changing market dynamics or client needs.
                                        </Text>
                                        <Text as="p" level="2xs">
                                            Stadion Money Management, LLC ("Stadion") is a registered investment adviser under the Investment Advisers Act of 1940. Registration does not imply a certain level of skill or training. More information about Stadion, including fees, can be found in Stadion's ADV Part 2, which is available free of charge.
                                        </Text>
                                        <Text as="p" level="2xs">
                                            This report is not intended to predict actual future data, but is only an illustration based on the profile user’s data inputs. The projections are provided solely as information which should be carefully weighed and evaluated by the investor with the assistance of the investor’s personal financial adviser. No guarantee of future performance is implied or intended, and no specific investments or financial products are represented in the reports. Portfolio characteristics, client restrictions, market conditions, or other factors could impact estimated accumulation. Although this report should prove helpful in visualizing the possibilities for your financial future, you should keep in mind that your actual results will undoubtedly differ from those shown. These projections are for illustrative purposes only and do not constitute investment advice.
                                        </Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Frame>
                    </Stack>
                </Block>
            </MainWrapper>
        </PageWrapper>
    )
}

export default Wizard