export function getFormFields(user, fields = [ 'firstName', 'lastName', 'last4ssn', 'state', 'email', 'enrollUsername', 'enrollPassword', 'enrollmentCode' ]) {
    return Object
        .entries(user)
        .filter(e => fields.includes(e[0]))
        .reduce((enrollmentFields, entry) => [ ...enrollmentFields, ...entry ], [])
}

export function getErrors(element, errorTemplate = {}) {
    const { validity } = element

    if (validity.valid)
        return null

    let errors = []

    if (validity.customError)
        errors = [ ...errors, element.validationMessage || 'Custom error' ]

    if (validity.patternMismatch)
        errors = [ ...errors, errorTemplate.patternMismatch || 'Pattern mismatch' ]

    if (validity.tooLong || validity.tooShort)
        errors = [ ...errors, errorTemplate.lengthMismatch || `Input length issue` ]

    if (validity.valueMissing)
        errors = [ ...errors, errorTemplate.valueMissing || `${element.ariaLabel || 'This field'} is required` ]

    return errors
}

export const errorTemplates = {
    firstName: {
        patternMismatch: `First name can't contain special characters and has to be at least two characters long`
    },
    lastName: {
        patternMismatch: `Last name can't contain special characters and has to be at least two characters long`
    },
    last4ssn: {
        patternMismatch: `Only digits are allowed`,
        valueMissing: 'Last four numbers of your social security number are required'
    },
    state: {},
    enrollUsername: {
        patternMismatch: `Username must be at least five characters long and cannot contain special characters`
    },
    email: {
        patternMismatch: 'Please enter a valid email address'
    },
    confirmEmail: {},
    enrollPassword: {
        valueMissing: 'Password is required',
        patternMismatch: 'Please enter a password matching the requirements'
    },
    confirmPassword: {
        valueMissing: 'Password is required',
        patternMismatch: 'Please enter a password matching the requirements'
    },
    enrollmentCode: {
        valueMissing: 'Enrollment code is required',
        patternMismatch: 'Please enter a valid enrollment code'
    },
    phone: {
        patternMismatch: 'Please enter a valid phone number in (xxx) xxx-xxxx format'
    },
    maritalStatus: {},
    dob: {},
    salary: {},
    contributionRate: {},
    expectedRetirementAge: {},
    current401kBalance: {},
    outsideAssets: {},
    pensions: {},
    gender: {},
    personalDebt: {},
    numDependents: {},
    'accepted-terms': {
        valueMissing: 'You have to agree to the Terms & Conditions'
    }
}

export const stateOptions = [
    { label: 'Alabama', id: 'AL' },
    { label: 'Alaska', id: 'AK' },
    { label: 'Arizona', id: 'AZ' },
    { label: 'Arkansas', id: 'AR' },
    { label: 'California', id: 'CA' },
    { label: 'Colorado', id: 'CO' },
    { label: 'Connecticut', id: 'CT' },
    { label: 'Delaware', id: 'DE' },
    { label: 'Florida', id: 'FL' },
    { label: 'Georgia', id: 'GA' },
    { label: 'Hawaii', id: 'HI' },
    { label: 'Idaho', id: 'ID' },
    { label: 'Illinois', id: 'IL' },
    { label: 'Indiana', id: 'IN' },
    { label: 'Iowa', id: 'IA' },
    { label: 'Kansas', id: 'KS' },
    { label: 'Kentucky', id: 'KY' },
    { label: 'Louisiana', id: 'LA' },
    { label: 'Maine', id: 'ME' },
    { label: 'Maryland', id: 'MD' },
    { label: 'Massachusetts', id: 'MA' },
    { label: 'Michigan', id: 'MI' },
    { label: 'Minnesota', id: 'MN' },
    { label: 'Mississippi', id: 'MS' },
    { label: 'Missouri', id: 'MO' },
    { label: 'Montana', id: 'MT' },
    { label: 'Nebraska', id: 'NE' },
    { label: 'Nevada', id: 'NV' },
    { label: 'New Hampshire', id: 'NH' },
    { label: 'New Jersey', id: 'NJ' },
    { label: 'New Mexico', id: 'NM' },
    { label: 'New York', id: 'NY' },
    { label: 'North Carolina', id: 'NC' },
    { label: 'North Dakota', id: 'ND' },
    { label: 'Ohio', id: 'OH' },
    { label: 'Oklahoma', id: 'OK' },
    { label: 'Oregon', id: 'OR' },
    { label: 'Pennsylvania', id: 'PA' },
    { label: 'Puerto Rico', id: 'PR' },
    { label: 'Rhode Island', id: 'RI' },
    { label: 'South Carolina', id: 'SC' },
    { label: 'South Dakota', id: 'SD' },
    { label: 'Tennessee', id: 'TN' },
    { label: 'Texas', id: 'TX' },
    { label: 'Utah', id: 'UT' },
    { label: 'Vermont', id: 'VT' },
    { label: 'Virginia', id: 'VA' },
    { label: 'Washington', id: 'WA' },
    { label: 'Washington, D.C.', id: 'DC' },
    { label: 'West Virginia', id: 'WV' },
    { label: 'Wisconsin', id: 'WI' },
    { label: 'Wyoming', id: 'WY' }
]