import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Alert, ElevatedCard, OutlineCard, QuitAndRestartModal, PageHeadingBlock, PageLoading, Stack, Block, Button, MainWrapper, Adjacent, Table, Thead, Tbody, Tr, Th, Td, Text } from '@stadion/bright'
import { Box, Flex } from '@chakra-ui/layout'
import PageWrapper from '../../PageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggle } from '../../../store/slices/quitModal'
import { ModalIncomeReview } from '../../../utils/modals'
import { useGetIncomeDistMutation, useBuildIncomePdfMutation, useGetRkInfoMutation } from '../../../store/api'
import StackedBarChartIncome from '../../charts/StackedBarChartIncome'
import useUser from '../../../store/useUser'
import ReactGA from 'react-ga4';


function IncomeReview() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toggleModal = () => dispatch(toggle())
    const { isOpen } = useSelector(state => state.quitModal)
    const startAge = useSelector(state => state.incomePlan.startAge)
    const endAge = useSelector(state => state.incomePlan.endAge)
    const monthlyIncome = useSelector(state => state.incomePlan.monthlyIncome)
    const customStartAge = useSelector(state => state.incomePlan.customStartAge)
    const customEndAge = useSelector(state => state.incomePlan.customEndAge)
    const customSocsecStartAge = useSelector(state => state.incomePlan.customSocsecStartAge)
    const customPensionStartAge = useSelector(state => state.incomePlan.customPensionStartAge)
    const customMonthlyIncome = useSelector(state => state.incomePlan.customMonthlyIncome)
    const customMonthlySocsec = useSelector(state => state.incomePlan.customMonthlySocsec)
    const customMonthlyPension = useSelector(state => state.incomePlan.customMonthlyPension)
    const customGraphSmileMode = useSelector(state => state.incomePlan.customGraphSmileMode)
    const includeSocsec = useSelector(state => state.incomeAccount.includeSocsec)
    const includePension = useSelector(state => state.incomeAccount.includePension)
    const acceptedPlan = useSelector(state => state.incomePlan.acceptedPlan)
    const [ getIncomeDist, incomeDistResponse ] = useGetIncomeDistMutation()
    const [ buildIncomePdf, incomePdfResponse ] = useBuildIncomePdfMutation()
    const [ buildIncomePdfDl, incomePdfResponseDl ] = useBuildIncomePdfMutation()

    const [ errors, setErrors ] = useState({})

    const rc2 = incomeDistResponse.data?.status?.rc
    const user = useUser()
    const [ getRkInfo, rkInfoResponse ] = useGetRkInfoMutation()

    
    
    useEffect(() => {
        if (rc2 < 0)
        {
            // When general error occurs not displayed above form appears to hang without displaying error.
            setErrors({ generic: incomeDistResponse.data?.status?.msg })
        }
    }, [ incomeDistResponse ])
    
    useEffect(() => {
        getRkInfo({
            vendorId: user?.vendorId
        })
    }, [ user?.vendorId ])
   
    useEffect(() => {
        getIncomeDist({
            smileChartMode: acceptedPlan > 0 ? customGraphSmileMode : 1,
            incomeStartAge: acceptedPlan > 0 ? customStartAge : startAge,
            incomeEndAge: acceptedPlan > 0 ? customEndAge : endAge,
            incomeOverride: customMonthlyIncome > 0 ? customMonthlyIncome : 0,
            socSecOverride: customMonthlySocsec,
            socSecStartAge: customSocsecStartAge    
        })
    }, [user, acceptedPlan])
    
       
    
    // if (incomeDistResponse.isLoading || incomeDistResponse.isFetching ||
    //     rkInfoResponse.isLoading || rkInfoResponse.isFetching)
    //     return (
    //         <MainWrapper palette="baseOne">
    //             <PageLoading />
    //         </MainWrapper>
    //     )
   
    
    const restart = () =>
    {
        toggleModal()
        navigate('/participant/dashboard')
    }
    const handleDownloadClick = async () =>
    {
        setErrors({})
        ReactGA.event({'category': 'Income PDF', 'action': 'Download Income PDF'})
        let incomePdfResponseDl = await buildIncomePdfDl({downloadPdf: true,
                                                          firstName: user?.firstName,
                                                          lastName: user?.lastName,
                                                          rkName: rkInfoResponse?.data?.rkName,
                                                          rkPhone: rkInfoResponse?.data?.rkPhone,
                                                          rkUrl: rkInfoResponse?.data?.rkURL,
                                                          barChartData:incomeDistResponse?.data?.incomeDistributionList,
                                                          includeSocSec:includeSocsec,
                                                          socSecStartAge:customSocsecStartAge,
                                                          monthlySocsec:customMonthlySocsec,
                                                          includePension:includePension,
                                                          pensionStartAge:customPensionStartAge,
                                                          monthlyPension:customMonthlyPension,
                                                          totalIncome:totalIncome,
                                                          retirementBalance:retirementBalance,
                                                          incomeStartAge:incomeDistResponse?.data?.incomeStartAge,
                                                          incomeEndAge:incomeDistResponse?.data?.incomeEndAge,
                                                          monthlyIncome:customMonthlyIncome > 0 ? customMonthlyIncome : monthlyIncome})

        let validateError = incomePdfResponseDl.data?.status?.rc;
        if (validateError < 0)
        {
            setErrors({ generic: incomePdfResponseDl.data?.status?.msg })
        }
        else {
            window.open(incomePdfResponseDl.data.incomePdfUrl)
        }
    }
     const handlePrintClick = async () =>
    {
        setErrors({})
        ReactGA.event({'category': 'Income PDF', 'action': 'Print Income PDF'})
        let incomePdfResponse = await buildIncomePdf({downloadPdf: false,
                                                      firstName: user?.firstName,
                                                      lastName: user?.lastName,
                                                      rkName: rkInfoResponse?.data?.rkName,
                                                      rkPhone: rkInfoResponse?.data?.rkPhone,
                                                      rkUrl: rkInfoResponse?.data?.rkURL,
                                                      barChartData:incomeDistResponse?.data?.incomeDistributionList,
                                                      includeSocSec:includeSocsec,
                                                      socSecStartAge:customSocsecStartAge,
                                                      monthlySocsec:customMonthlySocsec,
                                                      includePension:includePension,
                                                      pensionStartAge:customPensionStartAge,
                                                      monthlyPension:customMonthlyPension,
                                                      totalIncome:totalIncome,
                                                      retirementBalance:retirementBalance,
                                                      incomeStartAge:incomeDistResponse?.data?.incomeStartAge,
                                                      incomeEndAge:incomeDistResponse?.data?.incomeEndAge,
                                                      monthlyIncome:customMonthlyIncome > 0 ? customMonthlyIncome : monthlyIncome})
        let validateError = incomePdfResponse.data?.status?.rc;
        if (validateError < 0)
        {
            setErrors({ generic: incomePdfResponse.data?.status?.msg })
        }
        else {
            window.open(incomePdfResponse.data.incomePdfUrl, "PDF")
        }
    }
    const handleBackClick = () =>
    {
        navigate(-1)
    }
    const handleNextClick = () =>
    {  
        navigate('/income/accept')
    }
    
    
    
    const errorEntries = Object.values(errors).filter(err => err)   
    
    const incomeList = incomeDistResponse?.data?.incomeDistributionList; 
    const retirementBalance = incomeDistResponse?.data?.incomeAcctValueAtRetire;
    
    // Find the income distrib for user retirement age, get monthly values from there.
    const retirementAmts = incomeList?.find(cd => (cd.age === user?.pqdiaReq?.expectedRetirementAge))

    const include401k = true;
    let totalIncome = 0;
    let income401k = 0;
    if(retirementAmts !== undefined) {
        totalIncome = retirementAmts.income;
        income401k = retirementAmts.income;
        if(includeSocsec === true) {
            totalIncome = totalIncome + retirementAmts.socsec;
        }
        if(includePension === true) {
            totalIncome = totalIncome + customMonthlyPension;
        }
    }
    

    // Rows for monthly income table
    let renderedRows = incomeList?.map((rowData) =>
    {
        return (
            <Tr key={rowData.age}>
                <Td>
                    <Text level="xs">{rowData.age}</Text>
                </Td>
                <Td>
                    <Text level="xs"> ${Math.round(rowData.income).toLocaleString()}</Text>
                </Td>
            </Tr>
        );
    });
    
    // Rows for monthly socsec table
    let renderedRowsSoc = incomeList?.filter(rowData => rowData.age >= customSocsecStartAge).map((rowData) =>
    {
        return (
            <Tr key={rowData.age}>
                <Td>
                    <Text level="xs">{rowData.age}</Text>
                </Td>
                <Td>
                    <Text level="xs"> ${Math.round(rowData.socsec).toLocaleString()}</Text>
                </Td>
            </Tr>
        );
    });
    
    // Rows for monthly pension table
    let pensionList = [];
    for(let age = customPensionStartAge; age <= customEndAge; age++ ) {
        let obj = {};
        obj['age'] = age;
        obj['customMonthlyPension'] = customMonthlyPension;
        pensionList.push(obj);
    }

    let renderedRowsPension = pensionList?.map((rowData) =>
    {
        return (
            <Tr key={rowData.age}>
                <Td>
                    <Text level="sm">{rowData.age}</Text>
                </Td>
                <Td>
                    <Text level="sm"> ${Math.round(rowData.customMonthlyPension).toLocaleString()}</Text>
                </Td>
            </Tr>
        );
    });
    

    return (
        <PageWrapper pageTitle="Income Plan Review">
            <Block palette="baseTwo" paddingY="4xl">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading={"Your retirement plan illustration"}
                        subheading={"Save your illustration for your records or share with a financial professional"}
                        showDivider={true}
                    />

                    {
                        errorEntries.length > 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="negative"
                                    heading="A system error has occurred"
                                    content={
                                        errors.generic ||
                                        "Errors occurred during processing"}
                                />
                            </Stack>
                        </ElevatedCard>
                    }
                    {
                        incomePdfResponseDl.data?.status?.rc === 0 &&
                        <ElevatedCard>
                            <Stack space="xl">
                                <Alert
                                    variant="positive"
                                    heading="Download completed successfully"
                                    content="Please check your Downloads folder for the downloaded pdf document."
                                />
                            </Stack>
                        </ElevatedCard>
                    }

                    <Box mt={5} mb={5}>
                        {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching ||
                           rkInfoResponse.isLoading || rkInfoResponse.isFetching)
                        ? (
                        <Adjacent space="md">
                            <Button onClick={handleDownloadClick} variant="secondaryButton" isLoading={incomePdfResponseDl.isLoading}>
                                Download
                            </Button>
                            <Button onClick={handlePrintClick} variant="secondaryButton" isLoading={incomePdfResponse.isLoading}>
                                Print
                            </Button>
                        </Adjacent>
                        ) : (
                        <PageLoading />
                        )
                        }
                    </Box>

                    {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) && (
                        <>
                            <Text level="sm" variant="primary">
                                Est. total monthly income: ${Math.round(totalIncome).toLocaleString()}
                            </Text>

                            <OutlineCard mt={2}>
                                <Stack space="md">
                                    <Box mt={5}>
                                        <StackedBarChartIncome
                                            barChartData={incomeList}
                                            include401k={include401k}
                                            includeSocSec={includeSocsec}
                                            socSecStartAge={customSocsecStartAge}
                                            monthlySocSecOverride={customMonthlySocsec}
                                            includePension={includePension}
                                            pensionStartAge={customPensionStartAge}
                                            monthlyPension={customMonthlyPension}
                                        />

                                    </Box>
                                </Stack>
                            </OutlineCard>


                            <Box mt={2}>
                                <Text level="xs">
                                    This report illustrates point-in-time estimates and projections from the StoryLine website, and should not be relied upon for multi-year income planning purposes. Estimated income projections are based on assumptions and hypothetical performance data and do not represent actual or guaranteed results. Please read the Important Disclosures section for details. Your point-in-time projections will vary over time, and are subject to review and change at least annually by Stadion. Actual results will vary based on factors such as market performance, inflation, taxes, and personal circumstances including: retirement age, lifestyle, mortality, and other relevant personal information.&nbsp;
                                </Text>
                                <ModalIncomeReview />
                            </Box>
                            <Box mt={2}>
                                <Text as="p" level="xs">
                                    As a result, it is important that you look at your Dashboard on a regular basis, for example, at least once a year.
                                </Text>
                            </Box>
                            <Box mt={2}>
                                <Text as="p" level="xs">
                                    The timeframe for any disbursement estimates shown may not be available based on your Plan's service arrangement with its recordkeeper. Please confirm check with your Plan's administrator to learn what is available to you.
                                </Text>
                            </Box>
                        </>
                    ) 
                    }


                    {!(incomeDistResponse.isLoading || incomeDistResponse.isFetching) && (
                        <Box mt={5}>
                            <Accordion allowToggle={true}>
                                <AccordionItem>
                                    <AccordionButton>
                                        <div
                                            style={{
                                                backgroundColor: "#00819D",
                                                width: 10,
                                                height: 10,
                                                marginRight: 6,
                                                borderRadius: 2,
                                                display: "inline-block",
                                            }}
                                        ></div>
                                        Estimated 401(k) income
                                        <Text level="xs" as="b" variant="primary">
                                            <br />${Math.round(retirementBalance).toLocaleString()} total
                                        </Text>
                                        <Text level="xs" as="b" variant="primary">
                                            <br />From age {incomeDistResponse?.data?.incomeStartAge} to {incomeDistResponse?.data?.incomeEndAge}
                                        </Text>
                                        <Text level="xs" variant="primary">
                                            &nbsp;Estimated to be ${Math.round(income401k).toLocaleString()} a month at retirement.
                                        </Text>
                                    </AccordionButton>
                                    <AccordionPanel px="0" py="2">
                                        <Stack space="md">
                                            <Table vairant="simple">
                                                <Thead>
                                                    <Tr>
                                                        <Th>
                                                            <Text as="b" level="sm">Age</Text>
                                                        </Th>
                                                        <Th>
                                                            <Text as="b" level="sm">Estimated 401(k) monthly</Text>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>{renderedRows}</Tbody>
                                            </Table>
                                        </Stack>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    )
                    }

                    {((includeSocsec === true) && !(incomeDistResponse.isLoading || incomeDistResponse.isFetching)) && (
                        <Box mt={5}>
                            <Accordion allowToggle={true}>
                                <AccordionItem>
                                    <AccordionButton>
                                        <div
                                            style={{
                                                backgroundColor: "#ff9c36",
                                                width: 10,
                                                height: 10,
                                                marginRight: 6,
                                                borderRadius: 2,
                                                display: "inline-block",
                                            }}
                                        ></div>
                                        Social Security income
                                        <Text level="xs" as="b" variant="primary">
                                            <br />From age {customSocsecStartAge}
                                        </Text>
                                        <Text level="xs" variant="primary">
                                            &nbsp;Estimated to be ${Math.round(customMonthlySocsec).toLocaleString()} a month at retirement.
                                        </Text>
                                    </AccordionButton>
                                    <AccordionPanel px="0" py="2">
                                        <Stack space="md">
                                            <Table>
                                                <Thead>
                                                    <Tr>
                                                        <Th>
                                                            <Text as="b" level="sm">Age</Text>
                                                        </Th>
                                                        <Th>
                                                            <Text as="b" level="sm">Social Security monthly</Text>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>{renderedRowsSoc}</Tbody>
                                            </Table>
                                        </Stack>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    )}
                    
                    {((includePension === true) && !(incomeDistResponse.isLoading || incomeDistResponse.isFetching)) && (
                        <Box mt={5}>
                            <Accordion allowToggle={true}>
                                <AccordionItem>
                                    <AccordionButton>
                                        <div
                                            style={{
                                                backgroundColor: "#003357",
                                                width: 10,
                                                height: 10,
                                                marginRight: 6,
                                                borderRadius: 2,
                                                display: "inline-block",
                                            }}
                                        ></div>
                                        Pension income
                                        <Text level="xs" as="b" variant="primary">
                                            <br />From age {customPensionStartAge}
                                        </Text>
                                        <Text level="xs" variant="primary">
                                            &nbsp;Estimated to be ${Math.round(customMonthlyPension).toLocaleString()} a month at retirement.
                                        </Text>
                                    </AccordionButton>
                                    <AccordionPanel px="0" py="2">
                                        <Stack space="md">
                                            <Table>
                                                <Thead>
                                                    <Tr>
                                                        <Th>
                                                            <Text as="b" level="sm">Age</Text>
                                                        </Th>
                                                        <Th>
                                                            <Text as="b" level="sm">Pension monthly</Text>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>{renderedRowsPension}</Tbody>
                                            </Table>
                                        </Stack>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    )}
        



                    <Box mt={8}>
                        <Adjacent space="md">
                            <Button onClick={handleBackClick} variant="secondaryButton">
                                Back
                            </Button>
                            <Button onClick={handleNextClick}>
                                Next
                            </Button>
                        </Adjacent>
                    </Box>

                    <Box mt={8} mb={4}>
                        <QuitAndRestartModal
                            quitAndRestartLinkText="Quit and return to the dashboard"
                            quitAndRestartModalTitle="Are you sure you want to quit?"
                            quitAndRestartModalDescription="If you quit, all your unsaved information will be lost."
                            quitAndRestartModalCancelButtonText="Cancel"
                            quitAndRestartModalConfirmButtonText="Yes, quit"
                            onQuitAndRestartConfirm={restart}
                            isOpen={isOpen}
                            onClose={toggleModal}
                            onOpen={toggleModal}
                        />
                    </Box>
                </Flex>
            </Block>
        </PageWrapper>
    )
}

export default IncomeReview