import { DynamicErrors, MainWrapper } from '@stadion/bright'
import React from 'react'
import PageWrapper from '../PageWrapper'

function NotFound()
{
    return (
        <PageWrapper>
            <MainWrapper palette="baseTwo">
                <DynamicErrors
                    autoPlay
                    errorItems={[]}
                    fallbackErrorItem={
                        {
                            errorHeading: 'Page not found',
                            errorMessage: `Sorry, the page you're looking for can't be found`,
                            buttonLabel: 'Go home',
                            buttonLink: '/'
                        }
                    }
                />
            </MainWrapper>
        </PageWrapper>
    )
}

export default NotFound