import { Adjacent, Alert, Block, Button, Checkbox, Copy, Frame, Link, Stack, Text } from '@stadion/bright'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setAcceptTerms } from '../../../store/slices/toa'
import { useImplementUserMutation } from '../../../store/api'
import { Navigate } from 'react-router-dom'
import useUser from '../../../store/useUser'
import ReactGA from 'react-ga4';


function ToA() {
    const dispatch = useDispatch()
    const [ implementUser, implementUserResponse ] = useImplementUserMutation()
    const { acceptedTerms, toaResponse } = useSelector(state => state.ToA)
    const [ fileDownloaded, setFileDownloaded ] = useState(false)
    const [ errors, setErrors ] = useState([])
    const user = useUser()

    useEffect(() => {
        if (implementUserResponse.data?.status?.rc < 0) {
           // When general error occurs not displayed above form appears to hang without displaying error.
           setErrors({ generic: implementUserResponse.data?.status?.msg })       
       }
    }, [ implementUserResponse ])
       
    
    
    const submitUserImplementation = () => {
        if (!acceptedTerms || !fileDownloaded)
            setErrors([ 'You have to download and agree to Terms of Agreement' ])
        else {
            console.log("Implementating...")
            ReactGA.event({'category': 'ToA', 'action': 'Accept ToA / Implement'})
            implementUser()
        }
    }

    const downloadTerms = () => {
        console.log("Downloaded ToA...")
        ReactGA.event({'category': 'ToA', 'action': 'Download ToA'})
        setFileDownloaded(true)
        window.open(toaResponse.toaUrl, '_blank')
    }

    if (!toaResponse)
        return <Navigate to="/participant/questionnaire/review" />

       
    if (implementUserResponse.isSuccess && implementUserResponse.data?.status?.rc === 0 && implementUserResponse.data?.user?.implemented)
        // return (
        //     <MainWrapper>
        //         <Confirmation
        //             title="Profile successfully updated"
        //             buttonLabel="Go to dashboard"
        //             buttonLink="/participant/dashboard"
        //             buttonVariant="primaryButton"
        //             iconVariant="Success"
        //         />
        //     </MainWrapper>
        // )
        return <Navigate to="/participant/dashboard" />

    return (
        <Block palette="baseTwo" paddingY="4xl">
            <Frame width={ [ 'full' ] }>
                <Stack space="4xl">
                    { errors.length > 0 && <Alert variant="negative" heading="Download Terms of Agreement"></Alert> }
                    <Text as="p" level="sm">
                        Please download and review the Terms of Agreement before checking the acknowledgement box and clicking "Accept and confirm" button.
                    </Text>
                    <Button size="sm" leftIconName="FunctionalPdf" onClick={ downloadTerms }>
                        Click to download
                    </Button>
                    <Checkbox
                        isChecked={ acceptedTerms }
                        onChange={ e => dispatch(setAcceptTerms(e.target.checked)) }
                        label={ <Copy html={ `<p>I acknowledge that I have received, read, and understand <a href="https://cdn.stadionmoney.com/Retirement/Misc/sws/v2/adv/ADVPN.pdf">Stadion's Form ADV Part 2A</a> and <a href="https://cdn.stadionmoney.com/Retirement/Misc/PRIVACY_NOTICE_01_2016.pdf">Privacy Notice</a> and that I have read, understand and agree to the Terms of Agreement. I understand that once I Accept & confirm that my account will be invested in the portfolio shown above.</p>` } /> }
                        errors={ errors }
                    />
                    <Adjacent space="md">
                        <Link href="/participant/questionnaire/review" variant="secondaryButton">
                            Back
                        </Link>
                        <Button onClick={ submitUserImplementation } isLoading={ implementUserResponse.isLoading }>
                            Accept & confirm
                        </Button>
                    </Adjacent>
                </Stack>
            </Frame>
        </Block>
    )    
}

export default ToA