import { Box, Container, Flex } from '@chakra-ui/layout';
import { ElevatedCard, PageHeadingBlock, Stack, TextInput, Block, Button, MainWrapper, Link, Adjacent, Text, Alert, Heading } from '@stadion/bright';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useForgotUsernameMutation } from '../../store/api';
import { setForgotUsernameMessageDisplay } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../../routes';
import PageWrapper from '../PageWrapper';

function ForgotUsername() {
    const { sessionToken } = useSelector(state => state.token)
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const [forgotUsername, forgotUsernameResponse] = useForgotUsernameMutation();

    const isSuccess = forgotUsernameResponse.data?.status?.rc === 0;
    const isError = (
        (forgotUsernameResponse.data?.status?.rc !== undefined && !isSuccess) || forgotUsernameResponse.isError
    );

    useEffect(() => {
        if (forgotUsernameResponse.data)
            dispatch(setForgotUsernameMessageDisplay({ value: true }));
        else
            dispatch(setForgotUsernameMessageDisplay({ value: false }));
    }, [forgotUsernameResponse.data]);

    if (sessionToken)
        return <Navigate to={ routes.dashboard } />

    if (forgotUsernameResponse.isSuccess && isSuccess)
        return <Navigate to={ routes.login } />;

    const handleForgotUsername = e => {
        e.preventDefault();
        forgotUsername({ email });
    };

    const content = (
        <Container>
            <ElevatedCard>
                <Stack space="xl">
                    <Text level="xl" variant="primary" as="b">
                        Forgot your Username?
                    </Text>
                    <Text level="sm" variant="primary" as="b">
                        Simply provide us with your e-mail and we’ll send you an e-mail with your username.
                    </Text>
                    {
                        isError && <Alert
                            variant="negative"
                            heading={ forgotUsernameResponse.error?.data?.message || forgotUsernameResponse.data?.status?.msg || 'Request failed' }
                        />
                    }
                    <form onSubmit={handleForgotUsername}>
                        <Stack space="xl">
                            <TextInput
                                label="E-Mail Address"
                                isRequired={true}
                                pattern="^\S+@\S+\.\S+$"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Adjacent space="md">
                                <Link href={ routes.login } variant="secondaryButton">
                                    Cancel
                                </Link>
                                <Button
                                    type="submit"
                                    variant="primaryButton"
                                    isLoading={forgotUsernameResponse.isLoading}
                                >
                                    Submit
                                </Button>
                            </Adjacent>
                        </Stack>
                    </form>
                    <Adjacent space="md">
                        <Text as="b">Forgot your password?</Text>
                        <Link href={ routes.forgotPassword }><b>Click here</b></Link>
                    </Adjacent>
                </Stack>
            </ElevatedCard>
        </Container>
    )
    
    return (
        <PageWrapper pageTitle="Forgot Username">
            <MainWrapper palette="baseOne">
                <Flex height="100%" flexDir="column">
                    <PageHeadingBlock
                        pageHeading="StoryLine"
                        palette='baseThree'
                        subheading='The next chapter in retirement solutions'
                        showDivider={false}
                    />
                    <Flex flexGrow={1} flexDir="column">
                        <Block palette="baseOne" paddingY="4xl">
                            <Stack space="6xl">
                                <Box>{content}</Box>
                            </Stack>
                        </Block>
                    </Flex>
                </Flex>
            </MainWrapper>
        </PageWrapper>
    )
}

export default ForgotUsername;